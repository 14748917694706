import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withContext } from '../Context/context';
import ModifierBox from '../Controls/modifierBox';
import { getAvailableMagicPoints } from '../../services/magicPointsService';
import Incrementor from '../Controls/incrementor';
import { FormControlLabel, Switch } from '@mui/material';
import * as SAVE from '../../constants/save';
import { getAvailableRunePoints } from '../../services/runePointsService';
import { processRuneSpell } from '../../services/spellsService';
import CloseIcon from '../../icons/close-icon.svg';
import RuneSpell from './runeSpell';
import RuneSpellButton from '../Buttons/runeSpellButton';
import RuneMatrixButton from '../Buttons/runeMatrixButton';
import { withFirebase } from '../Firebase';
import DamageButton from '../Buttons/damageButton';
import { getAllRuneSpells, getRuneSpell } from '../../services/dataService';

const RuneSpellTileContextMenu = (props) => {

    const [modifier, setModifier] = useState(0);
    const [boost, setBoost] = useState(0);
    const [points, setPoints] = useState(0);
    const [active, setActive] = useState(true);
    const [availableMagicPoints, setAvailableMagicPoints] = useState(0);
    const [availableRunePoints, setAvailableRunePoints] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [spell, setSpell] = useState(null)
    const[matrix, setMatrix] = useState(props.panelItem.matrix)
    const locationElement = useRef();
    const notesElement = useRef();
    const castingElement = useRef();
    const [damageDice, setDamageDice] = useState(1);

    useEffect(() => {
        setPoints(props.panelItem.item.points);
        let itemSpell = getRuneSpell(props.panelItem.item.parentId, props.providerData.campaign);
        console.log('props.panelItem.item.runes', props.panelItem.item.runes)
        setSpell(itemSpell);
   },[props.panelItem.item]);

    useEffect(() => {
        var height = 145;
        height += props.panelItem.matrix ? 53 : 0;
        height += props.panelItem.item.stackable ? 45 : 0;
        height += locationElement.current ? locationElement.current.clientHeight + 15 : 0;
        height += notesElement.current ? notesElement.current.clientHeight + 15 : 0;
        height += castingElement.current.clientHeight + 15;
        setDescriptionHeight(800 - height);
    },[props.panelItem.item])

    useEffect(() => {
        if (props.panelItem.matrix) {
            setActive(props.panelItem.matrix.active);
        }
    },[props.panelItem.matrix])

    useEffect(() => {
        if (props.entity.matrices?.length > 0 && props.panelItem.matrix) {
            var index = props.entity.matrices.findIndex(obj => obj.id === props.panelItem.matrix.id);
            if (index > -1) {
                setActive(props.entity.matrices[index].active);
            }
        }
    },[props.entity.matrices])

    useEffect(() => {
        var points = getAvailableMagicPoints(props.spellcaster)
        setAvailableMagicPoints(points);
    },[props.spellcaster.magicPoints])

    useEffect(() => {
        if (props.panelItem.cult) {
            var points = getAvailableRunePoints(props.providerData.character, props.panelItem.cult);
            setAvailableRunePoints(points);
            setDisabled(points < props.panelItem.item.points);
        }
    },[props.entity, props.panelItem.cult?.runePoints])

    const boostSpell = (value) => {
        var newBoost = boost + value;
        if (newBoost < 0) newBoost = 0;
        if (newBoost > availableMagicPoints.current) {
            newBoost -= 1;
        } 
        setBoost(newBoost);
    }
    
    function incrementStackablePoints(value){
        if (points + value > availableRunePoints) return; 
        if (points + value < 1) return;
        setPoints(points + value);
    }
    
    function incrementDamageDice(value){
        if (damageDice + value < 1) return;
        setDamageDice(damageDice + value);
    }

    function updateActive(active) {
        setActive(active)
        var updatedMatrix = {...matrix};
        updatedMatrix.active = active;
        setMatrix(updatedMatrix)
        var updatedMatrix = {...props.panelItem.matrix};
        updatedMatrix.active = active;
        var updatedMatrices = [...props.spellcaster.matrices]
        const index = updatedMatrices.findIndex(matrix => matrix.id === updatedMatrix.id);
        updatedMatrices[index] = updatedMatrix;
        props.update([{fieldName: "matrices", value: updatedMatrices}], SAVE.YES);
    }
   
    function powGain(gained) {
        if (gained) {
            props.update([{fieldName: "powGain", value: true}], SAVE.DELAYED);
        }
    }
 
    function processResult(dieRoll, details, title){
        var action = {...details, points: points, boost: boost, spellcaster: props.spellcaster, name: details.castingRune?.name, id: details.castingRune?.id, cult: props.panelItem.cult}
        var updates = processRuneSpell(dieRoll, action, props.entity, props.firebase, props.providerData.type, props.providerData.campaign)

        if (props.panelItem.matrix && dieRoll.result <= dieRoll.target) {
            var updatedMatrices = [...props.entity.matrices]
            var index = updatedMatrices.findIndex(item => item.id === props.panelItem.matrix.id);
            updatedMatrices[index].active = false;
            updates.push({fieldName: "matrices", value: updatedMatrices});
            setActive(false);
        }
        props.update(updates, SAVE.DELAYED)
        props.processResult(dieRoll, action, title);
    }

    return (
                <div style={{}}>
                    {props.panelItem.matrix ?
                    <>
                        <div style={{width:"100%", display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                            {active ? 
                            <div>Spell matrix is active:</div>
                            : 
                            <div>Spell matrix is not active:</div>
                            }
                            <FormControlLabel style={{marginRight: "-10px"}}
                                control={<Switch checked={active} color="primary" onChange={(event) => updateActive(event.target.checked)} />}
                            />
                        </div>
                        <hr style={{width:"350px"}} />
                    </>
                            :
                        props.panelItem.item.stackable ?
                        <>
                            <Incrementor style={{padding: "5px 0px"}} label={"Cast spell using " + points + " rune point" + (points == 1 ? ":": "s:")} increment={incrementStackablePoints} /> 
                            <hr style={{width:"350px"}} />
                        </>
                        : null
                    }
                    <Incrementor style={{padding: "5px 0px"}} label={"Boost spell by " + boost + " magic point" + (boost == 1 ? ":": "s:")} increment={boostSpell} /> 
                    <hr style={{width:"350px"}} />
                    <ModifierBox number handleChange={(value) => setModifier(value)} />
                    <hr style={{width:"350px"}} />
                    <div ref={castingElement} style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", width: "329px"}}>
                        <div style={{paddingRight: "5px"}}>Cast spell at strike rank {1 + boost}{props.panelItem.cult ? " using the following rune" : ":"}</div>
                        <div style={{width: "132px"}}>
                            {props.panelItem.cult ?
                            props.panelItem.item.runes.map((rune, index) => {
                                return(
                                    <div key={index} style={{margin: "10px 0px"}} >
                                        <RuneSpell diceRoller={props.diceRoller} totalPoints={availableRunePoints} castingDetails={props.castingDetails} character={props.character} element={<RuneSpellButton rune={rune} item={props.panelItem.item} modifier={modifier + (props.providerData?.augmenting.modifier || 0)} />} spellcaster={props.spellcaster} availableMagicPoints={availableMagicPoints} spell={{...props.panelItem.item, points: points}} processResult={processResult} powGain={powGain} />
                                    </div>
                                )
                            })
                        : 
                        <div>
                            <RuneSpell diceRoller={props.diceRoller} update={props.update} matrix={matrix} castingDetails={props.castingDetails} character={props.character} element={<RuneMatrixButton item={props.panelItem.item} target={props.panelItem.item.value} modifier={modifier + (props.providerData?.augmenting.modifier || 0)} />} spellcaster={props.spellcaster} availableMagicPoints={availableMagicPoints} spell={{...props.panelItem.item, points: points}} processResult={processResult} powGain={powGain} />
                        </div>
                        }
                        </div>
                    </div>
                    {spell && spell.damage ?
                    <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>Damage: </div>
                            <Incrementor style={{padding: "5px 0px"}} increment={incrementDamageDice} />
                        </div> 
                        <div className={globalStyles.largeDamageButton} style={{width: "120px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <DamageButton points={damageDice} processDamage={props.processDamage} weapon={spell} entity={props.providerData.character} diceRoller={props.diceRoller} />
                        </div>
                    </div>
                : 
                null
                }
                    <hr style={{width:"350px"}} />
                    {props.panelItem.parent && props.panelItem.parent.type === "matrix" ?
                    <>
                        <div ref={locationElement}>
                            <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>Matrix location: </div>
                            <div>{props.panelItem.matrix.location}</div>
                        </div>
                        <hr style={{width:"350px"}} />
                        <div ref={notesElement}>
                            <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>Notes: </div>
                            <div>{props.panelItem.matrix.notes}</div>
                        </div>
                        <hr style={{width:"350px"}} />
                    </>
                    :
                    null}
                    <div className={globalStyles.panel} style={{height: descriptionHeight + "px", overflow: "auto"}}>
                        <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{props.panelItem.item.name}</div>
                        <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px"}}>
                            <div dangerouslySetInnerHTML={{__html: spell && spell.description}}></div>
                        </div>
                    </div>
                </div>
    )
}
export default withFirebase(withContext(RuneSpellTileContextMenu));