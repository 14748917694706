import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import SpiritSpellPanel from '../Magic/spiritSpellPanel';
import RuneSpellPanel from '../Magic/runeSpellPanel';
import * as SAVE from '../../constants/save';
import * as CultsData from '../../data/cults.json';
import { v4 as uuidv4 } from 'uuid';
import Selector from '../Controls/selector';
import { Tabs, Tab, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import EditBox from '../Controls/editBoxSingle';
import { withContext } from '../Context/context';
import DeleteButton from '../Controls/deleteButton';
import { calculateSpiritCombatDamage } from '../../services/attributeService';
import { newWeapon, newWeaponSkill } from '../../services/weaponService';
import { getAllCults, getAllSpiritSpells, getCult, getRuneSpell } from '../../services/dataService';

const spiritTypes = [{name: "Bound spirit"}, {name: "Allied spirit"}, {name: "Fetch"}];

const SpiritDialog = (props) => {

    const [complete, setComplete] = useState(false);
    const [familiar, setFamiliar] = useState(false);
    const [spirit, setSpirit] = useState({name: "", POW: 0, INT: 0, CHA: 0, STR: 0, SIZ: 0, type: "", location: ""});
    const [disabled, setDisabled] = useState(true);
	const [value, setValue] = React.useState(0);
    const [spiritSpells, setSpiritSpells] = useState([]);
    const [runeSpells, setRuneSpells] = useState([]);
    const [cultDetails, setCultDetails] = useState(); // Full details of this spirit's cult
    const [cultSpells, setCultSpells] = useState([]);
    const [cult, setCult] = useState();
    const [cults, setCults] = useState([]);
    const [animals, setAnimals] = useState([{name: "Shadowcat", characteristics: {STR: 7, CON: 13, SIZ: 4, POW: 19, DEX: 23}, move: 10, armour: 0, skills: [
        {
            "description": "A skillful dodger is rarely where the blow falls. Dodging is described more fully in the Combat chapter, as are special conditions modifying this skill. A successful Dodge roll means that the adventurer is not hit by the attack being Dodged. An adventurer’s Dodge ability is decreased by –1% for each point of ENC carried.<p>Dodge takes no time when used against a hand-to-hand melee attack. It takes one entire melee round when used against missile attacks, and cannot be used in strike ranks while the adventurer is occupied with shooting a missile weapon. See Strike Ranks on page 192 for more information.</p>",
            "id": "50b31052-9d31-451c-9cc8-2abf9a3ab21b",
            "name": "Dodge",
            "parentId": "5b9dde30-bea7-11ea-afe0-c72b6ad5d25a",
            "skillCategory": "Agility",
            "value": 18
          },
          {
            "description": "With Hide, any available cover, including shadows, misty areas, etc., can be used to hide oneself from others. The surrounding terrain influences the chance to Hide, with the gamemaster suggesting modifiers ranging anywhere from –50% (out in the open with nothing to hide behind) to +50% (dense forest). The Move Quietly skill may be used to augment the Hide skill, and vice versa.<p>An opposed roll is required to detect someone hiding, using either Scan or Search, depending on the method being used to detect the hider.</p><p>For use of Hide and Move Quietly simultaneously, the adventurer should use the primary skill being attempted (Hide or Move Quietly), using the other as an augment. Failing the augmenting skill applies the modifier to the primary skill, as described in Augmenting Abilities on page 144 of the rulebook.</p><p>If this skill is used near adventurers who are not actively using Search, a simple success roll keeps the user from being discovered. If the hiding adventurer’s player made a successful roll but someone is actively Searching in the area, it becomes an opposed roll using the enemy’s Search skill.</p><p>The Hide skill can be rolled for only once for each Hide attempt.</p>",
            "id": "1e595535-e84d-4acc-a135-59e6a935b713",
            "name": "Hide",
            "parentId": "61518610-bea7-11ea-afe0-c72b6ad5d25a",
            "skillCategory": "Stealth",
            "value": 10
          },
          {
            "description": "This skill covers moving in silence, without alerting a foe. See the description of the Hide skill (prior) when using Hide and Move Quietly simultaneously.<p>Used near characters who are not actively using Listen, a simple success roll keeps the sneaker from discovery. If someone is stated to be using Listen in the area, it becomes an opposed roll.</p><p>Adventurers attempting to Move Quietly may do so at their full skill if moving up to 1 meter per strike rank, while moving at 2+ meters per strike rank reduces the Move Quietly skill by 1/2.</p><p>Make a Move Quietly roll for each melee round in which the sneaker is being listened for, but only once if there is no active listening.</p><p>Wearing armor penalizes the Move Quietly skill (see the Armor table on page 215 of the rulebook). If several different types of armor are worn, the penalty from the noisiest is applied.</p>",
            "id": "16d9e7c6-658f-4c10-a827-0684623c87f7",
            "name": "Move Quietly",
            "parentId": "61607a30-bea7-11ea-afe0-c72b6ad5d25a",
            "skillCategory": "Stealth",
            "value": 10
          }
          ], 
        }]);
    const [animal, setAnimal] = useState(null);

    const allCults = getAllCults(props.providerData.campaign)

    
    useEffect(() => {
        var entityCults = [];
        props.entity.cults.forEach(cult => {
            entityCults.push(cult);
        })
        setCults(entityCults);
    }, [props.entity.cults]);

    useEffect(() => {
        if (props.spirit) {
            var spiritDetails = {
                id: props.spirit.id,
                name: props.spirit.name.first,
                type: props.spirit.type,
                location: props.spirit.location
            }
            getCharacteristic(spiritDetails, "POW");
            getCharacteristic(spiritDetails, "INT");
            getCharacteristic(spiritDetails, "CON");
            getCharacteristic(spiritDetails, "DEX");
            getCharacteristic(spiritDetails, "CHA");
            getCharacteristic(spiritDetails, "STR");
            getCharacteristic(spiritDetails, "SIZ");
            if (props.spirit.cult) {
                setCult(props.spirit.cult);
                var cultIndex = props.providerData.character.cults.findIndex(item => item.name === props.spirit.cult.name)
                if (cultIndex > -1) {
                    var rpIndex = props.providerData.character.cults[cultIndex].runePoints.findIndex(item => item.entityId === props.spirit.id);
                    if (rpIndex > -1) {
                        spiritDetails.runePoints = props.providerData.character.cults[cultIndex].runePoints[rpIndex].total;
                        spiritDetails.cult = props.spirit.cult
                    }
                }
            }
            setSpirit(spiritDetails)
            setSpiritSpells(props.spirit.spiritSpells);
            setRuneSpells(props.spirit.runeSpells);
            updateDisabled(spiritDetails);
        }
    },[props.spirit, cult])

    useEffect(() => {
        if (cult) {
            let cultDetails = getCult(cult.parentId, props.providerData.campaign)
            if (cultDetails) {
                setCultDetails({...cultDetails});
                var spells = [];
                cultDetails.runeSpells.forEach(runeSpell => {
                    let spell = getRuneSpell(runeSpell.parentId, props.providerData.campaign)
                    if (spell.runes[0] === "Magic") {
                        spell.runes = cultDetails.runes;
                    }
                    spells.push(spell)
                });
                setCultSpells(spells);
            }
        }

    },[cult])

    function getCharacteristic(spiritDetails, name) {
        if (props.spirit.characteristics[name]) {
            spiritDetails[name] = props.spirit.characteristics[name].baseValue;
        }
        return spiritDetails;
    }

    function selectCult(cultName) {
        var index = cults.findIndex(obj => obj.name === cultName);
        setCult(cults[index]);
    }

    function handleOkClick() {
        if (spirit.type === "Fetch") {
            save();
        }
        else {
            setComplete(true);
        }
    }

    function save(selectedSpells){
        var updates = [];
        var spiritId = spirit && spirit.id ? spirit.id : uuidv4(); 
        var newSpirit = {
            name:{first: spirit.name},
            id: spiritId,
            type: spirit.type,
            location: spirit.location,
            familiar: familiar,
            characteristics: {
                POW:
                {
                    baseValue: spirit.POW,
                    statBonus: 0,
                    name: "POW", 
                    fullName: "Power",                    
                },
                CHA:
                {
                    baseValue: spirit.CHA,
                    statBonus: 0,
                    name: "CHA", 
                    fullName: "Charisma",                    
                },
            },
            spiritSpells: spiritSpells,
        }
        if (spirit.INT) {
            newSpirit.characteristics.INT = {
                baseValue: spirit.INT,
                statBonus: 0,
                name: "INT", 
                fullName: "Intelligence",                    
            }
        };
        if (spirit.STR) {
            newSpirit.characteristics.STR = {
                baseValue: spirit.STR,
                statBonus: 0,
                name: "STR", 
                fullName: "Strength",                    
            }
        };
        newSpirit.spiritCombat = {armour: 0, title: "Spirit Combat"};
        newSpirit.spiritCombat.damage = calculateSpiritCombatDamage(newSpirit.characteristics);

        if (cult) {
            if (cult.id) {
                newSpirit.cult = {...cult};
            }
            else {
                var cultIndex = props.entity.cults.findIndex(obj => obj.name === cult.name);
                newSpirit.cult = {name: cult.name, parentId: cultDetails.id, runes: cultDetails.runes}
            }
            newSpirit.runeSpells = runeSpells;
            newSpirit.runeSpells.forEach(runeSpell => {
                const spellData = getRuneSpell(runeSpell.parentId, props.providerData.campaign)
                if (spellData.effects) {
                    spellData.effects.forEach(effect => {
                        if (effect.name === "addWeapon") {
                            var updatedWeaponSkills = newWeaponSkill(effect, props.providerData.character);
                            updates.push({fieldName: "weaponSkills", value: updatedWeaponSkills});
                            var updatedWeapons = newWeapon(effect, props.providerData.character);
                            updates.push({fieldName: "weapons", value: updatedWeapons});
                        }
                    })
                }
            })
        }
        var updatedBoundSpirits = [...props.entity.boundSpirits];
        var index = updatedBoundSpirits.findIndex(obj => obj.id === spiritId);
        if (index < 0) {
            updatedBoundSpirits.push(newSpirit);
        }
        else {
            updatedBoundSpirits[index] = newSpirit;
        }     
        props.close()
        updates.push({fieldName: "boundSpirits", value: updatedBoundSpirits});

        var updatedMagicPoints = [...props.entity.magicPoints];
        var mpIndex = updatedMagicPoints.findIndex(obj => obj.entityId === spiritId);
        if (mpIndex < 0) {
            updatedMagicPoints.push({
                entityId: spiritId,
                type: spirit.type,
                total: spirit.POW,
                current: spirit.POW,
            })
        }
        else {

            updatedMagicPoints[mpIndex] = {...updatedMagicPoints[mpIndex], total: spirit.POW, current: spirit.POW}
        }      
        updates.push({fieldName: "magicPoints", value: updatedMagicPoints})

        if (cult) {
            var updatedCults = [...props.providerData.character.cults];
            var cultIndex = updatedCults.findIndex(obj => obj.name === cult.name);
            var rpIndex = updatedCults[cultIndex].runePoints.findIndex(obj => obj.entityId === spiritId);
            if (rpIndex < 0) {
                updatedCults[cultIndex].runePoints.push({entityId: spiritId, current: spirit.runePoints, total: spirit.runePoints, type: spirit.type})
            }
            else {
                updatedCults[cultIndex].runePoints[rpIndex] = {entityId: spiritId, current: spirit.runePoints, total: spirit.runePoints, type: spirit.type};
            }      
    
            updates.push({fieldName: "cults", value: updatedCults})
        }
        props.update(updates, SAVE.NO);
    }

    function update(field, value) {
        var updatedSpirit = {...spirit};
        updatedSpirit[field] = value;
        setSpirit(updatedSpirit);
        updateDisabled(updatedSpirit);
    }

    function updateDisabled(updatedSpirit) {
        var disabled = false; 
        if (updatedSpirit.type === "" || updatedSpirit.type === "Select") {
            disabled = true;
        }
        else if (updatedSpirit.type === "Bound elemental") {
            if ((!updatedSpirit.POW || !updatedSpirit.STR || !updatedSpirit.name)) {
                disabled = true;
            }
        }
        else if (updatedSpirit.type === "Allied spirit") {
            if ((!updatedSpirit.POW || !updatedSpirit.INT || !updatedSpirit.CHA || !updatedSpirit.name || !cult)) {
                disabled = true;
            }
        }
        else if (!updatedSpirit.POW || !updatedSpirit.CHA || !updatedSpirit.name || !updatedSpirit.type) {
            disabled = true;
        }
        setDisabled(disabled);
    }

    function handleCancelClick(){
        props.close();
    }

	function handleDeleteClick(deletedItem){
		var updatedSpirits = [...props.entity.boundSpirits]
		var index = updatedSpirits.findIndex(item => item.id === deletedItem.id);
		updatedSpirits.splice(index, 1);
        var updates = [{fieldName: "boundSpirits", value: updatedSpirits}];
        
		var updatedMagicPoints = [...props.entity.magicPoints]
		var index = updatedMagicPoints.findIndex(item => item.entityId === deletedItem.id);
		updatedMagicPoints.splice(index, 1);
        updates.push({fieldName: "magicPoints", value: updatedMagicPoints});
        
        if (deletedItem.cult) {
            var updatedCults = [...props.entity.cults];
            var cultIndex = updatedCults.findIndex(item => item.name === deletedItem.cult.name);
            if (cultIndex > -1) {
                var rpIndex = updatedCults[cultIndex].runePoints.findIndex(item => item.entityId === deletedItem.id);
                if (rpIndex > -1) {
                    updatedCults[cultIndex].runePoints.splice(rpIndex, 1);
                    updates.push({fieldName: "cults", value: updatedCults});
                }
            }
        }
        props.update(updates, SAVE.NO)
		props.close();
	}

    const updateSpiritType = (value) => {
        update("type", value);
    }

    const selectRuneSpells =(spell) => {
        setRuneSpells(spell);
    }

    const getAvailablePoints = () => {
        var points = 0;
        spiritSpells.forEach(spell => {
            points += spell.points; 
        })
        return spirit.CHA - points;
    }
        
      return (
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", minHeight: "500px", overflow: "none"}}>
                <div style={{padding: "10px"}}>
                    <div style={{display:"flex", alignItems: "flex-end"}}>
                        <Selector updateSelected={updateSpiritType} values={spiritTypes} label="Type:" style={{width: "200px", marginLeft: "6px"}} value={spirit.type} />
                        {spirit.type === "Allied spirit" ? 
                        <div style={{height: "45px", marginTop: "10px", display:"flex", marginLeft: "20px"}}>
                            <Selector updateSelected={selectCult} labelMargin="21px" values={cults} style={{width: "200px", marginTop: "10px" }} value={cult && cult.name} label="Cult:" />
                            <div style={{marginLeft:"14px", display:"flex", alignItems:"center", width: "160px"}}>
                                <EditBox number label="Rune points:" value={spirit.runePoints} onChange={(value) => {update("runePoints", value)}} />
                            </div>
                        </div>
                    : 
                        <div style={{height: "55px"}} />
                    }
                    </div>
                    <div style={{display:"flex", marginTop: "10px"}}>
                        <EditBox label="Name:" value={spirit.name} onChange={(value) => {update("name", value)}} width="300px" />
                        {familiar ? 
                            <Selector updateSelected={selectCult} labelMargin="21px" values={animals} style={{width: "200px", marginTop: "10px" }} value={animal} label="Animal type:" />
                            : 
                            <div style={{display:"flex", alignItems:"center", width: "430px", marginLeft: "10px"}}>
                                <EditBox label="Bound into:" value={spirit.location} onChange={(value) => {update("location", value)}} width="320px" />
                            </div>
                        }
                    </div>
                    {spirit.type ? 
                        <div style={{display:"flex", marginTop: "10px"}}>
                            {spirit.type === "Bound elemental" || familiar ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="STR:" value={spirit.STR} onChange={(value) => {update("STR", value)}} width="40px" />
                                </div>
                            : null
                            }
                            {familiar ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="CON:" value={spirit.CON} onChange={(value) => {update("CON", value)}} width="40px" />
                                </div>
                            : null
                            }
                            {familiar ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="SIZ:" value={spirit.SIZ} onChange={(value) => {update("SIZ", value)}} width="40px" />
                                </div>
                            : null
                            }
                            {familiar ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="DEX:" value={spirit.DEX} onChange={(value) => {update("DEX", value)}} width="40px" />
                                </div>
                            : null
                            }
                            {spirit.type === "Allied spirit" ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="INT:" value={spirit.INT} onChange={(value) => {update("INT", value)}} width="40px" />
                                </div>
                            : 
                            null
                            }
                            {spirit.type ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="POW:" value={spirit.POW} onChange={(value) => {update("POW", value)}} width="40px" />
                                </div>
                            : null
                            }
                            {spirit.type === "Allied spirit" || "Fetch" || spirit.type === "Bound spirit" ? 
                                <div style={{marginLeft:"7px", display:"flex", alignItems:"center"}}>
                                    <EditBox number label="CHA:" value={spirit.CHA} onChange={(value) => {update("CHA", value)}} width="40px" />
                                </div>
                            : 
                            null
                            }
                        </div>
                    : null
                    }
                </div>

                {spirit.type === "Bound elemental" ? 
                null
                :

                <div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", padding: "5px"}}>
                    <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="simple tabs example">
                        {spirit.type && ["Bound spirit", "Allied spirit", "Ancestor"].indexOf(spirit.type) > -1 ? 
                        <Tab style={{marginLeft: "60px", fontSize: "16px"}} label="Spirit spells" {...a11yProps(0)} />
                        : 
                        null
                        }
                        {spirit.type && ["Allied spirit", "Ancestor"].indexOf(spirit.type) > -1 ? 
                            <Tab style={{marginLeft: "60px", fontSize: "16px"}} label="Rune spells" {...a11yProps(1)} />
                        : 
                        null
                        }
                    </Tabs>
                    {spirit.type && ["Bound spirit", "Allied spirit", "Ancestor"].indexOf(spirit.type) > -1 ? 
                        <TabPanel value={value} index={0}>
                            <SpiritSpellPanel campaign={props.providerData.campaign} availableSpells={getAllSpiritSpells(props.providerData.campaign)} currentSpells={spiritSpells} setSpells={()=> {}} complete={complete} save={save} availablePoints={getAvailablePoints()} select={setSpiritSpells} />
                        </TabPanel>
                    : 
                    null
                    }
                    {spirit.type && ["Allied spirit", "Ancestor"].indexOf(spirit.type) > -1 ? 
                        <TabPanel value={value} index={1}>
                            <RuneSpellPanel campaign={props.providerData.campaign} availableSpells={cultSpells} characterRunes={props.providerData.character.runes} cult={cult} currentSpells={runeSpells} setSpells={()=> {}} complete={complete} save={save} storage={spirit.CHA} select={selectRuneSpells} />
                        </TabPanel>
                    : 
                    null
                    }
                </div>
                }
                <div style={{display:"flex", marginTop:"30px", padding: "7px", alignItems:"flex-end", flexDirection: "row-reverse", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        <Button type="submit" disabled={disabled} onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                        <Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"7px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
                    </div>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        {props.spirit ? 
                            <DeleteButton delete={handleDeleteClick} type="spirit" item={props.spirit} />
                        : 
                        null
                        }
                    </div>
                </div>
            </div>
    );
    
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
      style={{}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

export default withContext(withFirebase(SpiritDialog));

