import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import { withContext } from '../Context/context';
import RuneSpell from '../Magic/runeSpell';
import PanelHeader from '../Controls/panelHeader';
import MatrixDialog from './matrixDialog';
import * as SAVE from '../../constants/save';
import RuneMagicMatrix from './runeMagicMatrix';

const RuneMagicMatrices = (props) => {

    const [matrices, setMatrices] = useState([]);

    useEffect(() => {
        var matrices = [];
        props.providerData.character.matrices.forEach(matrix => {
            if (matrix.type === "Rune spell matrix") {
                matrices.push(matrix);
            }
        });
        setMatrices(matrices);
    },[props.providerData.character.matrices]);

    function update(matrix, persist) {
        var updatedMatrices = [...props.providerData.character.matrices];
        var index = updatedMatrices.findIndex(item => item.id === matrix.id);
        updatedMatrices[index] = matrix;
        props.update([{fieldName: "matrices", value: updatedMatrices}], persist);
    }

    function addMatrix(newMatrix) {
        var updatedMatrices = [...props.providerData.character.matrices];        
        var index = updatedMatrices.findIndex(item => item.id === newMatrix.id);
        if (index < 0) {
            newMatrix.active = true;
            updatedMatrices.push(newMatrix);
        }
        else {
            updatedMatrices[index] = newMatrix;
        }      
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO);
    }
     
	function deleteMatrix(matrix){
		var updatedMatrices = [...props.providerData.character.matrices]
		var index = updatedMatrices.findIndex(item => item.id === matrix.id);
		updatedMatrices.splice(index, 1);
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO)
	}

    return(
        <>
            {props.providerData.type === "character" ? 
                <PanelHeader title="Rune Magic Matrices" addTitle="Add rune spell matrix" addItem={addMatrix} width="900px" >
                    <MatrixDialog entity={props.providerData.character} type="Rune spell matrix" />
                </PanelHeader>
            : 
            null
            }
            {props.providerData.type === "character" ? 
                <div className={globalStyles.panel} style={{minHeight: "52px", overflow: "auto"}}>
                    {matrices && matrices.length > 0 ? 
                        matrices.map((matrix, index) => {
                            return (
                                <RuneMagicMatrix diceRoller={props.diceRoller} element={props.element} key={index} stylesheet={props.stylesheet} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} entity={props.providerData.character} spellcaster={props.providerData.character} matrix={matrix} processResult={props.processResult} update={update} powGain={props.powGain} delete={deleteMatrix} />
                            )
                        })
                    : 
                    <div style={{marginTop: "10px", padding: "5px"}}>
                        <div>You do not currently have any rune magic matrices</div>
                    </div>  
                    }   
                </div>
            : 
                <>
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", fontSize: "13px", backgroundColor: "#2F588A", color: "#fff", padding: "2px"}}>
                        <div>Matrices</div>
                    </div>
                    {matrices && matrices.length > 0 ? 
                        matrices.map((matrix, index) => {
                        if (matrix.type === "Rune spell matrix") {
                            return (
                                <RuneSpell diceRoller={props.diceRoller} displayItem={props.displayItem} key={index} matrix={matrix} storage={props.storage} spellcaster={props.providerData.character} spell={matrix.spell} processResult={props.processResult} update={update} character={props.character} powGain={props.powGain} />
                            )
                        }
                    })
                    :null
                    }   
                </>
            }
        </>
    );
}

export default withContext(withPageState(RuneMagicMatrices));