import React, { useEffect, useState } from "react";
import { withPageState } from "../Context/PageState";
import { v4 as uuidv4 } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import deleteIcon from '../../icons/delete-icon-red.svg'
import DeleteDialog from '../Dialogs/deleteDialog';

const CustomSkills = (props) => {

    const[skills, setSkills] = useState([]);
    const [deletedSkill, setDeletedSkill] = useState();
    const [confirm, setConfirm] = useState(false);

    const defaultNewSkill={name: "New Skill", description: "Description", variable: false, baseValue: 10, skillCategory: "Agility"}

    useEffect(() => {
        if (props.campaign.skills) {
            setSkills([...props.campaign.skills]);
        }
    },[props.campaign]);

    const displayItem = (skill) => {
        props.displayItem({type: "CustomSkill", item: skill})
    }

    const createSkill = () => {
        let skill = {...defaultNewSkill};
        skill.id = uuidv4();
        displayItem(skill);
    }

    function deleteItem(event, cult) {
        event.stopPropagation();
        setConfirm(true);
        setDeletedSkill(cult);
    }

    function confirmDelete() {
        props.delete(deletedSkill);
        setConfirm(false);
    }

    return (
        <div style={{border: "2px solid #2f588a", borderRadius: "5px", width: "100%", maxHeight: "300px"}}>
            <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div>Custom Skills</div>
                <div style={{width: "30px"}}>
                {props.pageState.editMode ? 
                    <AddCircleOutlineIcon onClick={createSkill} style={{height:"24px", width:"24px", color: "#fff"}}/>
                    :
                null
                }
            </div>
            </div>
            <div style={{display: "flex", alignItem: "center", padding: "10px", fontWeight: "bold"}}>
                <div style={{width: "200px"}}>Name</div>
                <div style={{width: "120px", textAlign: "center"}}>Base Value</div>
                <div style={{width: "100px", textAlign: "center"}}>Variable</div>
                <div style={{width: "150px", textAlign: "center"}}>Skill Category</div>
                <div style={{}}>Description</div>
            </div>
            {skills.map((skill, index) => {
                return (
                    <div key={index}>
                        <div style={{display: "flex", alignItems: "center", padding: "6px 10px", cursor: "pointer"}} onClick={() => displayItem(skill)}>
                            <div style={{width: "200px"}}>{skill.name}</div>
                            <div style={{width: "120px", textAlign: "center"}}>{skill.baseValue}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{skill.variable ? "Yes": "No"}</div>
                            <div style={{width: "150px", textAlign: "center"}}>{skill.skillCategory}</div>
                            <div style={{width: "559px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}} dangerouslySetInnerHTML={{__html: skill.description.substr(0, 90)}} />
                            {props.pageState.editMode ? 
                                <div style={{cursor: "pointer"}} onClick={(event) => deleteItem(event, skill)}>
                                    <img src={deleteIcon} style={{height: "24px"}} />
                                </div>
                            : 
                            null}
                        </div>
                    </div>
                )
            })}
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="skill" />
        </div>
    )
}

export default withPageState(CustomSkills);