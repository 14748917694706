export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const NEWCHARACTER = '/newCharacter';
export const ENCOUNTER = '/encounter';
export const NEWENCOUNTER = '/newEncounter';
export const ENCOUNTERS = '/encounters';
export const TEMPLATE = '/template';
export const NEWTEMPLATE = '/newtemplate';
export const TEMPLATES = '/templates';
export const BUILDER = '/builder';
export const EXISTING = '/existing';
export const CHARACTERS = '/characters';
export const CHARACTER = '/character';
export const CHARACTERSHEET = '/CharacterSheet';
export const CAMPAIGNS = '/campaigns';
export const CAMPAIGN = '/campaign';
export const SPIRITMAGIC = '/newSpiritMagic';
export const RUNEMAGIC = '/runemagic';
export const CULTS = '/cults';
export const NEWCULT = '/newCult';
export const INVITE = '/invite';