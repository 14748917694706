import * as SkillsData from '../data/skills.json';
import * as PassionsData from '../data/passions.json';
import * as RunesData from '../data/runes.json';
import * as CultsData from '../data/cults.json';
import * as SpiritSpells from '../data/spiritSpells.json';
import * as RuneSpellData from '../data/runeSpells.json';
import * as WeaponsData from '../data/weapons.json';
import compareValues from '../utils/compareValues';

const allSkills = {...SkillsData}
const allPassions = {...PassionsData}
const allRunes = {...RunesData}
const allCults = {...CultsData}
const allSpiritSpells = {...SpiritSpells}
const allRuneSpells = {...RuneSpellData}
const allWeapons = {...WeaponsData}

export function getSkill(id, campaign) {
    return getItem(id, allSkills.data, campaign, "skills");
}

export function getAllSkills(campaign, cultId, includeRestricted, ) {
    var skills = [...allSkills.data];
    if (campaign && campaign.skills) {
        if (includeRestricted) {
            skills = skills.concat(campaign.skills);
        }
        else {
            skills = skills.concat(campaign.skills).filter(function(skill) {return (!skill.restricted) });
        }
        skills = skills.filter(function(skill) {return (!skill.cults) || skill.cults.findIndex(obj => obj.id === cultId) });
    }
    return skills.sort(compareValues("name"));
}

export function getPassion(id, campaign) {
    return getItem(id, allPassions.data, campaign, "passions");
}

export function getAllPassions(campaign, cultId ) {
    var passions = [...allPassions.data];
    if (campaign && campaign.passions) {
        passions = passions.concat(campaign.passions);
    }
    return passions.sort(compareValues("name"));
}

export function getRune(id, campaign) {
    return getItem(id, allRunes.data, campaign, "runes");
}

export function getAllRunes(campaign) {
    return getAllItems(allRunes.data, campaign, "runes")
}

export function getCult(id, campaign) {
    return getItem(id, allCults.data, campaign, "cults");
}

export function getAllCults(campaign) {
    return getAllItems(allCults.data, campaign, "cults")
}

export function getSpiritSpell(id, campaign) {
    return getItem(id, allSpiritSpells.data, campaign, "spiritSpells");
}

export function getAllSpiritSpells(campaign, cultId, includeNonPlayable, ) {
    var spells = [...allSpiritSpells.data];
    if (campaign && campaign.spiritSpells) {
        if (includeNonPlayable) {
            spells = spells.concat(campaign.spiritSpells);
        }
        else {
            spells = spells.concat(campaign.spiritSpells).filter(function(spell) {return (!spell.unplayable) });
        }
        spells = spells.filter(function(spell) {return (!spell.cults) || spell.cults.findIndex(obj => obj.id === cultId) });
    }

    return spells.sort(compareValues("name"));
}

export function getRuneSpell(id, campaign) {
    return getItem(id, allRuneSpells.data, campaign, "runeSpells");
}

export function getAllRuneSpells(campaign) {
    var spells = [...allRuneSpells.data];
    if (campaign && campaign.runeSpells) {
        spells = spells.concat(campaign.runeSpells);
    }
    return spells.sort(compareValues("name"));
}

export function getWeapon(id, campaign) {
    return getItem(id, allWeapons.data, campaign, "weapons");
}

export function getAllWeapons(campaign) {
    return getAllItems(allWeapons.data, campaign, "weapons")
}

function getItem(id, data, campaign, field) {
    let item = null;
    let campaignData = [];
    if (campaign && campaign[field]) {
        campaignData = [...campaign[field]]
    }
    let index = data.findIndex(obj => obj.id === id);
    if (index > -1) {
        item = data[index];
    }
    else if (campaignData.length > 0) {
        index = campaignData.findIndex(obj => obj.id === id)
        if (index > -1) {
            item = campaignData[index]
        }
    }
    return item;
}

function getAllItems(collection, campaign, field) {
    let items = [...collection]
    if (campaign && campaign[field]) {
        items = items.concat(campaign[field]);
    }
    return items.sort(compareValues("name"));
}