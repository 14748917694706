import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

const EditBox = (props) => {

    const [value, setValue] = useState(props.number ? 0 : "");

    useEffect(() => {
        setValue(props.value);
    },[props.value]);

    function handleChange(event) {
        event.stopPropagation();
        if (props.number) {
            var previous = value || "";
            var newValue;
            if (event.target.value === '-') {
                newValue = '-';
            }
            if (event.target.value === '-') {
                newValue = '.';
            }
            else if (event.target.value == "") {
                newValue = "";
            }
            else if (event.target.value == "0") {
                newValue = 0;
            }
            else {
                newValue = parseFloat(event.target.value);
                if (! newValue) newValue = previous;
            }
            setValue(newValue);
            props.onChange(newValue);
        }
        else {
            setValue(event.target.value);
            props.onChange(event.target.value);
        }
    }

    var width = props.width || "100%";
    var editStyle = {borderRadius: "4px", width: width, fontSize:(props.fontSize) ? props.fontSize : "15px", height: props.size === "sm" ? "20px": "40px", padding: "0px 10px", paddingRight: props.number ? "0px" : "10px", textAlign: props.number ? "right" : "left", display:"flex", alignItems: "center", color: props.style && props.style.color ? props.style.color : "#121312"};

    return (
        <div style={{...props.parentStyle, display:"flex", alignItems: "center"}}> 
            {props.label ? 
            <div style={{marginRight: props.labelMargin ? props.labelMargin : "10px", width: "max-content", whiteSpace:"nowrap"}}>{props.label}</div>
            : 
            null
            }
            <div style={props.style ? props.style : {backgroundColor: "#fff", color: "#121312", borderRadius: "4px", width: "100%", border: "2px solid #2f588a"}}>
                <TextField
                  autoComplete="off"
                    type={props.type ? props.type : props.number ? "number": ""}
                    variant="outlined"
                    name={props.name}
                    placeholder={props.placeholder}
                    label={props.fieldLabel}
                    size="small"
                    required={props.required}
                    value={value} 
                    disabled={props.disabled}
                    multiline={props.multiline}
                    rows={props.rows}
                    autoFocus={props.autoFocus}
                    onChange={handleChange} 
                    inputProps={{
                        style: editStyle 
                    }}   
                    sx= {{...props.style, padding: "0px"}}
                    >
                    {value}
                </TextField>

            </div>
        </div>
    )

};

export default EditBox;



