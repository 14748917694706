import React, {useState, useEffect, useRef} from 'react';
import { withFirebase } from '../Firebase';
import { Button, Switch, FormGroup, FormControlLabel, FormControl, Checkbox } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { withPageState } from '../Context/PageState';
import { getAllSkills, getSkill, getWeapon } from '../../services/dataService';
import DescriptionDialog from './descriptionDialog';
import AddWeaponFilterDialog from './addWeaponFilterDialog';
import { CheckBox } from '@mui/icons-material';
import DialogButtons from '../Dialogs/dialogButtons';

const targets = [{name: "Character"}, {name: "Weapon"}];
const skillCategories = [{name: "Agility"}, {name: "Communication"}, {name: "Knowledge"}, {name: "Magic"}, {name: "Manipulation"}, {name: "Perception"}, {name: "Stealth"}];
const durations = [{name: "Instant"}, {name: "Temporal"}, {name: "Other"}];
const ranges = [{name: "Touch"}, {name: "Ranged"}, {name: "Other"}];

const CustomSkillContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [skill, setSkill] = useState({name: "", baseValue: 0, skillCategory: "", variable: false, restricted: false});
	const [campaign, setCampaign] = useState(props.campaign);
	const [variable, setVariable] = useState(false);
	const [specialization, setSpecialization] = useState();
	const [variableSkills, setVariableSkills] = useState([]);

	const infoBox = useRef(null)

	useEffect(() => {
		if (props.panelItem) {
			setSkill(props.panelItem.item);
			if (props.panelItem.item.specializationId) {
				setVariable(true);
				let parentSkill = getSkill(props.panelItem.item.specializationId, props.campaign);
				setSpecialization(parentSkill);
			}
		}
	},[props.panelItem]);

	useEffect(() => {
		let variable = [];
		getAllSkills(props.campaign).forEach(skill => {
			if (skill.variable) {
				variable.push({...skill});
			}
		});
		setVariableSkills(variable);
	},[])

    useEffect(() => {
        if (props.buttonClick === "ok") {
			props.update(campaign, true);
        }
    },[props.buttonClick])

	function setValue(field, value) {
		var updatedSkill = {...skill}
		updatedSkill[field] = value;
		setSkill(updatedSkill);
		updateCampaign(updatedSkill);
	}

	function updateCampaign(updatedSkill) {
		var updatedCampaign = {...campaign}
		let index = updatedCampaign.skills.findIndex(obj => obj.id === updatedSkill.id)
		if (index > -1) {
			updatedCampaign.skills[index] = updatedSkill;
		}
		else {
			updatedCampaign.skills.push(updatedSkill);
		}
		setCampaign(updatedCampaign)
	}

    function applyDescription(editorContent, title, index) {
        var updatedSkill = {...skill}
        updatedSkill.description = editorContent;
        setSkill(updatedSkill);
		updateCampaign(updatedSkill)
    }

    const handleSpecializationChange = (item) => {
		console.log('item', item)
        setSpecialization(item);
		var updatedSkill = {...skill};
		updatedSkill.name = item.name + " ()"
		updatedSkill.baseValue = item.baseValue;
		updatedSkill.skillCategory = item.skillCategory
		updatedSkill.description = item.description;
		updatedSkill.specializationId = item.id
		setSkill(updatedSkill);
    }

	if (skill) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: "800px", overflow: "auto"}}>
						{props.pageState.editMode ? 
						<>
							<div>To create a specialized version of a skill, usually to add it as a starting skill for a cult, tick the box marked "Specialization" and then select the parent skill.</div>
							<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px"}}>
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{display:"flex", alignItems: "center", justifyContent:"center"}}>
										<div style={{fontWeight: "bold"}}>Specialization:</div>
										<FormControl variant="outlined" 	>
											<Checkbox checked={variable} color='primary' onChange={setVariable} />
										</FormControl>
									</div>
								</div>
								<div style={{display: "flex", alignItems: "center"}}>
									<Selector disabled={!variable} style={{height: "28px", width:"160px"}} value={specialization && specialization.name} values={variableSkills} onChange={handleSpecializationChange} />
								</div>
							</div>
							<hr />
						</>
						:
						null 
						}
						<div style={{display: "flex", alignItems: "center"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "23px", fontWeight: "bold"}}>Skill name: </div>
									<EditBox size="sm" width="220px" value={skill.name} onChange={(value) => setValue("name", value)} />
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Skill name: </div>
									<div>{skill.name}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "50%"}}>
								<div style={{marginRight: "20px", fontWeight: "bold"}}>Base Value: </div>
								{props.pageState.editMode ? 
									<EditBox size="sm" number width="50px" value={skill.baseValue} onChange={(value) => setValue("baseValue", value)} />
									: 
									<div>{skill.baseValue}</div>
								}
							</div>
							<div style={{display: "flex", alignItems: "center", width: "145px", marginLeft: "20px"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel control={<Switch color="primary" checked={skill.variable} onChange={(event) => setValue("variable", event.target.checked)}></Switch>} label="Variable Skill"></FormControlLabel>
									</FormGroup>
								:
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Variable: </div>
										<div>{skill.variable ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "166px"}}>
								{props.pageState.editMode ? 
									<Selector labelMargin="32px" label="Type:" style={{height: "28px"}} value={skill.skillCategory} values={skillCategories} onChange={(item) => {setValue("skillCategory", item.name)}} />
								: 
									<>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Skill Category: </div>
										<div>{skill.skillCategory}</div>
									</>
								}
							</div>
							<div style={{display: "flex", alignItems: "center", width: "145px", marginLeft: "20px"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel control={<Switch color="primary" checked={skill.restricted} onChange={(event) => setValue("restricted", event.target.checked)}></Switch>} label="Restricted"></FormControlLabel>
									</FormGroup>
								:
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Restricted: </div>
										<div>{skill.restricted ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{marginTop: "20px"}}>
							{props.pageState.editMode ? 
							<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
								<div style={{fontWeight: "bold"}}>Description</div>
								<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
							</div>
						: 
							<>
								<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
								<div dangerouslySetInnerHTML={{__html: skill.description}}></div>
							</>
						}
						</div>
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={skill.description} />
				</DialogBox>
 			</>
		)
	}
	else {
		return null;
	}
}

export default withFirebase(withPageState(CustomSkillContextPanel));