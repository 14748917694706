import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import { FormControl, Checkbox } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditBox from '../Controls/editBoxSingle';
import { getSpiritSpell } from '../../services/dataService';

const SpiritSpellListItem = (props) => {

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [spell, setSpell] = useState(props.spell);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        var spell = {...props.spell}
        if (!spell.description) {
            const spellDetails = getSpiritSpell(spell.parentId, props.campaign)
            if (spellDetails) {
                spell.description = spellDetails.description
                spell.duration = spellDetails.duration;
                spell.range = spellDetails.range;
                spell.variable = spellDetails.variable;
                spell.type = spellDetails.type;
            }
        }
        setSpell(spell);
    },[props.spell]);

    useEffect(() => {
        var selected = false;
        if (props.selected && props.selected.length > 0) {
            props.selected.forEach(item => {
                if (item.id === spell.id) {
                    setChecked(true);
                    selected = true; 
                }
            })
        }
        var spellDisabled = false;
        if (!selected) {
            setChecked(false);
            if (spell.points > props.availablePoints) {
                spellDisabled = true;
            }
        }
        setDisabled(spellDisabled)
    },[spell, props.availablePoints, props.selected])

    const selectSpell = (event) => {
        setChecked(event.target.checked);
        if (spell.variable) {
            amendVariablePoints(0 - (spell.points - 1))
        }
        props.select(spell, event.target.checked);
    }
    
    function amendVariablePoints(value){
        var updatedSpell = {...spell};
        if (checked && ((updatedSpell.points + value) > 0)) {
            updatedSpell.points += value;
            setSpell(updatedSpell);
            props.amendSpell(updatedSpell);           
        }
   }

    function setVariant(value) {
        var updatedSpell = {...spell}
        updatedSpell.variant = value;
        setSpell(updatedSpell);
        props.amendSpell(updatedSpell);  
    }

    if (spell) {
        return(
            <>
                <div onClick={() => setOpen(!open)} style={{display:"flex", alignItems: "center", height: "28px", overflow: "hidden", padding: "0px 5px", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312"}}>
                    <div onClick={(event) => {event.stopPropagation()}} style={{flex: "0 0 50px"}}>
                        <FormControl variant="outlined" 	>
                            <Checkbox disabled={disabled} checked={checked} color='primary' onChange={selectSpell} />
                        </FormControl>
                    </div>
                    <div style={{fontWeight:700, display: "flex", alignItems: "center", flex: "0 0 180px", cursor:"pointer"}}>
                        <div>{spell.name}</div>
                        {spell.variant ? 
                        <div onClick={(event) => {event.stopPropagation()}} style={{width: "100px", marginLeft: "10px"}}>
                            <EditBox size="sm" value={spell.variant } onChange={setVariant} />
                        </div>
                        : 
                        null }
                    </div>
                    <div style={{flex:"0 0 80px", textAlign:"center", display:"flex", justifyContent:"center", cursor:"pointer"}}>
                        <div>{spell.points || "-"}</div>
                        {spell.variable ?
                            <div onClick={(event) => {event.stopPropagation()}} style={{display:"flex", marginLeft:"5px", alignItems:"center"}}>
                                    <RemoveCircleOutlineIcon style={{height:"16px", width:"16px"}} onClick={() => amendVariablePoints(-1)}/>
                                    {props.availablePoints > 0 && 
                                        <AddCircleOutlineIcon style={{height:"16px", width:"16px", marginLeft:"4px"}} onClick={() => amendVariablePoints(1)}/>
                                    }
                            </div>
                        :
                            null
                        }
                    </div>
                    <div style={{flex:"0 0 100px", textAlign:"center", cursor:"pointer"}} onClick={() => setOpen(!open)}>{spell.variable ? "Yes" : "No"}</div>
                    <div style={{flex: "1 1 100%", minWidth: 0}}>
                        <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", padding:"10px 0px", cursor:"pointer"}} onClick={() => setOpen(!open)}>{spell.description && spell.description.substr(0, 320)}</div>
                    </div>
                </div>
                {open ?
                    <div style={{minHeight:"80px", backgroundColor: "#fff", margin: "2px", padding: "10px", paddingLeft: "50px", position: "relative", cursor: "pointer"}} onClick={() => setOpen(!open)} >
                        <div style={{display:"flex", color:"#2F588A"}}>
                            <div style={{display:"flex"}}>
                                <div><b>Spell:</b></div>
                                <div style={{marginLeft: "10px"}}><b>{spell.name}</b></div>
                            </div>
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>Points:</b></div>
                                <div style={{marginLeft: "10px"}}>{spell.points}</div>
                            </div>
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>Variable:</b></div>
                                <div style={{marginLeft: "10px"}}>{spell.variable ? "Yes": "No"}</div>
                            </div>
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>Range:</b></div>
                                <div style={{marginLeft: "10px"}}>{spell.range}</div>
                            </div>
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>Duration:</b></div> 
                                <div style={{marginLeft: "10px"}}>{spell.duration}</div>
                            </div>
                        </div>
                        <div style={{marginTop: "10px"}} dangerouslySetInnerHTML={{ __html: spell.description}} />
                    </div>
                : 
                null
                }
            </>
        )
    }
    else return null;
};

export default withFirebase(SpiritSpellListItem);