import React, { useState} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import DialogBox from '../Dialogs/dialogBox';
import { withContext } from '../Context/context';
import PanelHeader from '../Controls/panelHeader';
import AddMatrixDialog from './matrixDialog';
import SpiritSpell from '../Magic/spiritSpell';
import SpiritMagicMatrix from './spiritMagicMatrix';
import * as SAVE from '../../constants/save';

const SpiritMagicMatrices = (props) => {

    function update(matrix) {
        var updatedMatrices = [...props.providerData.character.matrices];
        var index = updatedMatrices.findIndex(item => item.id === matrix.id);
        updatedMatrices[index] = matrix;
        props.update([{fieldName: "matrices", value: updatedMatrices}], SAVE.DELAYED);
    }
     
	function deleteMatrix(matrix){
		var updatedMatrices = [...props.providerData.character.matrices]
		var index = updatedMatrices.findIndex(item => item.id === matrix.id);
		updatedMatrices.splice(index, 1);
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO)
	}

    function addMatrix(newMatrix) {
        var updatedMatrices = [...props.providerData.character.matrices];        
        var index = updatedMatrices.findIndex(item => item.id === newMatrix.id);
        if (index < 0) {
            newMatrix.active = true;
            updatedMatrices.push(newMatrix);
        }
        else {
            updatedMatrices[index] = newMatrix;
        }      
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO);
    }

    return(
        <>
            {props.providerData.type === "character" ? 
                <PanelHeader title="Spirit Magic Matrices" addTitle="Add spirit spell matrix" addItem={addMatrix} width="900px" >
                    <AddMatrixDialog entity={props.providerData.character} type="Spirit spell matrix" />
                </PanelHeader>
            : 
            null
            }
            <div style={{}}>

            {props.providerData.type === "character" ? 
                <div className={globalStyles.panel} style={{height: "202px", overflow: "auto"}}>
                    {props.providerData.character.matrices.map((matrix, index) => {
                        if (matrix.type === "Spirit spell matrix") {
                            return (
                                <SpiritMagicMatrix diceRoller={props.diceRoller} element={props.element} key={index} stylesheet={props.stylesheet} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} entity={props.providerData.character} spellcaster={props.providerData.character} matrix={matrix} processResult={props.processResult} update={update} powGain={props.powGain} delete={deleteMatrix} />
                                )
                        }
                    })}
                </div>
            : 
                <>
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", fontSize: "13px", backgroundColor: "#2F588A", color: "#fff", padding: "2px"}}>
                        <div>Matrices</div>
                    </div>
                    {props.providerData.character.matrices.map((matrix, index) => {
                        if (matrix.spell) {
                            return (
                                <SpiritSpell diceRoller={props.diceRoller} element={props.element} displayItem={props.displayItem} matrix={matrix} storage={props.storage} spellcaster={props.providerData.character} item={matrix.spell} processResult={props.processResult} update={props.update} character={props.providerData.character} powGain={props.powGain} />
                            )
                        }
                    })}
                </>
            }
        </div>
        </>
    );
}

export default withContext(withPageState(SpiritMagicMatrices));