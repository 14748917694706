import React, { useState, useEffect } from 'react';
import CultCards from './cultCards';
import CultDetails from './cultDetails';
import { Paper, Tabs, Tab, Typography, Switch, FormControlLabel } from '@mui/material';
import CultSpiritSpells from './cultSpiritSpells';
import CultRuneSpells from './cultRuneSpells';
import CultSkills from './cultSkills';
import Gifts from './gifts';
import Geases from './geases';
import PropTypes from 'prop-types';
import { getAllCults } from '../../../services/dataService';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Typography>{children}</Typography>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const Cults = (props) => {
    const [cults, setCults] = useState([]);
    const [occupationCults, setOccupationCults] = useState([]);
    const [selectedCult, setSelectedCult] = useState();
    const [skillsComplete, setSkillsComplete] = useState(false);
    const [spiritSpellsComplete, setSpiritSpellsComplete] = useState(false);
    const [runeSpellsComplete, setRuneSpellsComplete] = useState(false);
    const [giftsComplete, setGiftsComplete] = useState(false);
    const [geasesComplete, setGeasesComplete] = useState(false);
    const [occupationOnly, setOccupationOnly] = useState(true);
    const [show, setShow] = useState("skills");
    const [maxPoints, setMaxPoints] = useState(5);
    const [displayCult, setDisplayCult] = useState(null);
    const[characterRunes, setCharacterRunes] = useState([]);
    const [value, setValue] = React.useState(0);
    const [geasCount, setGeasCount] = useState(0);


    useEffect(() => {
        var occupationCults = [];
        var eligibleRunes = [];
        var cultAdded = false;
        props.builder.runes.elementalRunes.forEach(rune => {
            if (rune.value >= 50) {
                eligibleRunes.push(rune);
            }
        })
        props.builder.runes.powerRunes.forEach(rune => {
            if (rune.first.value >= 50) {
                eligibleRunes.push(rune.first);
            }
            if (rune.second.value >= 50) {
                eligibleRunes.push(rune.second);
            }
        })
        var eligibleCults = [];
        var validCults = []
        var allCults = getAllCults(props.builder.campaign)
        allCults.forEach(cult => {
            if (! cult.restricted) {
                validCults.push(cult);
            }
        })
        setCults(validCults);

        allCults.forEach(cult => {
            if (props.builder.occupation.cults.indexOf(cult.name) > -1) {
                occupationCults.push(cult);
            }
        })
        occupationCults.forEach(cult => {
            cultAdded = false;
            cult.runes.forEach(rune => {
                if (!cultAdded && eligibleRunes.findIndex(eligibleRune => eligibleRune.name === rune) > -1) {
                    eligibleCults.push(cult);
                    cultAdded = true;
                }
            })
        })
        setOccupationCults(eligibleCults)
    },[]);

    useEffect(() => {
        if (props.builder.cult && props.builder.cult.selectedGifts) {
            var count = 0;
            props.builder.cult.selectedGifts.forEach(gift => {
                count += gift.geases;
            });
            setGeasCount(count);
            setGeasesComplete(props.builder.cult.selectedGeases && (props.builder.cult.selectedGeases.length >= count))
        }
    },[props.builder.cult])

    useEffect(() => {
        var runes = [];
        props.displayCharacter.builder.runes.elementalRunes.forEach(rune => {
			runes.push(
				{ 
				name: rune.name, 
				parentId: rune.id, 
				value: rune.value, 
			});
		})
		props.displayCharacter.builder.runes.powerRunes.forEach(rune => {
			runes.push({name: rune.first.name, parentId: rune.first.id, value: rune.first.value});
			runes.push({name: rune.second.name, parentId: rune.second.id, value: rune.second.value});
		});

        setCharacterRunes(runes);
        setMaxPoints(props.builder.occupation.spiritMagicPoints ? props.builder.occupation.spiritMagicPoints : 5);

    },[props.displayCharacter.builder]);

    useEffect(() => {
        if (props.builder.cult) {
            setSelectedCult({...props.builder.cult});
            if (props.builder.cult.selectedSpiritSpells) {
                var total = 0;               
                props.builder.cult.selectedSpiritSpells.forEach(spell => {
                    total += spell.points;
                });
                if (total == maxPoints) {
                    setSpiritSpellsComplete(true)
                }
                else {
                    setSpiritSpellsComplete(false);
                }
            }
            if (props.builder.cult.selectedRuneSpells) {
                var count = 0;             
                props.builder.cult.selectedRuneSpells.forEach(spell => {
                    count ++;
                });
                if (count >= 3) {
                    setRuneSpellsComplete(true)
                }
                else {
                    setRuneSpellsComplete(false);
                }
            }
            if (props.builder.cult.gifts) {
                setGiftsComplete(props.builder.cult.selectedGifts && props.builder.cult.selectedGifts.length > 0)
            }
        }
    },[props.builder.cult, maxPoints]);

    useEffect(() => {
        if (skillsComplete && spiritSpellsComplete && runeSpellsComplete) {
            if (selectedCult.gifts) {
                if (giftsComplete && geasesComplete) {
                    props.disableNext(false);
                }
                else {
                    props.disableNext(true);
                }
            }
            else {
                props.disableNext(false);
            }
        }
        else {
            props.disableNext(true);
        }
    },[skillsComplete, spiritSpellsComplete, runeSpellsComplete, giftsComplete, geasesComplete])

    function handleSelect(cult) {
        if (props.builder.cult && props.builder.cult.name === cult.name) {
            setSelectedCult({...props.builder.cult});
            props.disableNext(false);
        }
        else {
            setSkillsComplete(false);
            setSpiritSpellsComplete(false);
            setRuneSpellsComplete(false);
            setShow("skills")
            
            cult.weapons && cult.weapons.forEach((weaponGroup, index) => {
                if (weaponGroup.options.length == 0) {
                    var culturalWeaponGroup = getCulturalWeapons(weaponGroup);
                    cult.weapons[index] = culturalWeaponGroup;
                }
            });
            cult.selectedSpiritSpells = [];
            cult.selectedRuneSpells = [];
            cult.selectedGifts = [];
            cult.selectedGeases = [];
            setGeasCount(0)
            cult.primarySkill = null;
            cult.secondarySkill = null;
            setSelectedCult(cult);
            setDisplayCult(cult);
            updateBuilder(cult);
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    function getCulturalWeapons(weaponGroup) {
        var options = [];
        props.builder.homeland.weapons.forEach(weapon => {
            if (weaponGroup.class === "Melee") {
                if (weapon.class === "Melee") {
                    options.push(...weapon.options);
                }
            }
            else if (weaponGroup.class === "Missile") {
                if (weapon.class === "Missile") {
                    options.push(...weapon.options);
                }
            }
            else {
                options.push(...weapon.options);
            }
        });
        var weapon = {
            bonus: weaponGroup.bonus,
            options: options,
            selected: {},
            title: weaponGroup.subtitle
        }
        return weapon;
    }

    function updateSkills(updatedCult, complete) {
        setSelectedCult(updatedCult);
        updateBuilder(updatedCult);
    }

    function updateSpiritSpells(selected) {
        var updatedCult = {...selectedCult};
        updatedCult.selectedSpiritSpells = [...selected];
        // selected.forEach(spell => {
        //     console.log('spell is ', {...spell})
        // })
        setSelectedCult(updatedCult);

        updateBuilder(updatedCult);
    }

    function updateRuneSpells(selected) {
        var updatedCult = {...selectedCult};
        updatedCult.selectedRuneSpells = [...selected];
        setSelectedCult(updatedCult);
        updateBuilder(updatedCult);
    }

    function updateGifts(selected) {
        var updatedCult = {...selectedCult};
        updatedCult.selectedGifts = selected;
        setSelectedCult(updatedCult);
        updateBuilder(updatedCult);
        var count = 0; 
        selected.forEach(gift => {
            count += gift.geases;
        });
        setGeasCount(count);
        setGeasesComplete(updatedCult.selectedGeases && (updatedCult.selectedGeases.length >= count))
    }

    function updateGeases(selected) {
        var updatedCult = {...selectedCult};
        updatedCult.selectedGeases = selected;
        setSelectedCult(updatedCult);
        updateBuilder(updatedCult);
        setGeasesComplete(updatedCult.selectedGeases && (updatedCult.selectedGeases.length >= geasCount))
    }

    function updateBuilder(updatedCult) {
        var updatedBuilder = {...props.builder};
        updatedBuilder.cult = updatedCult;
        props.update(updatedBuilder, false);
    }

    function displayGifts() {
        return (
            <Gifts cult={selectedCult} update={updateGifts} isComplete={(complete) => setGiftsComplete(complete)} />
        )
    }

    var displayCults = occupationOnly ? occupationCults : cults; 

    return (
        <div style={{width: "100%"}}>
            <div style={{display: "flex", alignItems: "flex-start", position: "relative"}}>
                <div style={{display:"flex", alignItems: "center", marginLeft: "10px"}}>
                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", padding: "5px", width: "460px", marginTop: "10px"}}>
                        <div style={{fontSize: "16px"}}>Options</div>
                        <div style={{marginTop: "5px", display: "flex", alignItems: "center"}}>
                            <div>Occupational Cults*</div>
                            <FormControlLabel style={{marginLeft: "10px"}}
                            control={<Switch checked={occupationOnly} color="primary" onChange={(event) => setOccupationOnly(event.target.checked)} />}
                            />
                        </div>
                        <div style={{marginTop: "10px"}}>{occupationOnly ? "*Show the cults commonly associated with your chosen occupation. Note that only cults where the character has a cult-related rune of at least 50% are shown." : "*Show all player cults"}</div>                    
                        </div>
                </div>
            </div>
            <div style={{display:"flex", position: "relative"}}>
                <div style={{marginTop: "20px", width: "100%"}}>
                    <div style={{}} >	
                        <CultCards cults={displayCults} selected={selectedCult} size={{width: "120px", height: "120px", fontSize: "18px"}} handleSelect={handleSelect} />
                    </div>
                    {selectedCult && selectedCult.name ? 
                    <>
                        <div style={{display:"flex", margin: "10px 0px", color: "#121312"}}>
                            <div style={{width: "100%"}}>
                                <Paper square>
                                    <Tabs style={{width: "100%", marginBottom: "10px",}} value={value} onChange={handleChange} aria-label="simple tabs example">
                                        <Tab style={{color: skillsComplete ? "#121312" : "#fff", backgroundColor: skillsComplete ? "#fff" : "#851113", fontSize: "18px"}} label="Skills" {...a11yProps(0)} />
                                        <Tab style={{color: spiritSpellsComplete ? "#121312" : "#fff", backgroundColor: spiritSpellsComplete ? "#fff" : "#851113", fontSize: "18px"}} label="Spirit Magic" {...a11yProps(1)} />
                                        <Tab style={{color: runeSpellsComplete ? "#121312" : "#fff", backgroundColor: runeSpellsComplete ? "#fff" : "#851113", fontSize: "18px"}} label="Rune Magic" {...a11yProps(2)} />
                                        {selectedCult.gifts ? 
                                            <Tab style={{color: giftsComplete ? "#121312" : "#fff", backgroundColor: giftsComplete ? "#fff" : "#851113", fontSize: "18px"}} label="Gifts" {...a11yProps(3)} />
                                        : 
                                        null}
                                        {selectedCult.geases ? 
                                            <Tab style={{color: geasesComplete ? "#121312" : "#fff", backgroundColor: geasesComplete ? "#fff" : "#851113", fontSize: "18px"}} label="Geases" {...a11yProps(4)} />
                                        : 
                                        null}
                                    </Tabs>
                                </Paper>
                                {value === 0 && selectedCult ? 
                                    <CultSkills cult={selectedCult} builder={props.builder} update={updateSkills} isComplete={(complete) => setSkillsComplete(complete)} />
                                : null}
                                {value === 1 && selectedCult ? 
                                    <CultSpiritSpells allCults={cults} cult={selectedCult} builder={props.builder} update={updateSpiritSpells} maxPoints={maxPoints} isComplete={(complete) => setSpiritSpellsComplete(complete)} />
                                : null}
                                {value === 2 && selectedCult ? 
                                    <CultRuneSpells campaign={props.builder.campaign} allCults={cults} characterRunes={characterRunes} cult={selectedCult} homeland={props.builder.homeland} maxCount={3} update={updateRuneSpells} isComplete={(complete) => setRuneSpellsComplete(complete)} />
                                : null}
                                {value === 3 && selectedCult ? 
                                    <>
                                        {displayGifts()}
                                    </>
                                : null}
                                {value === 4 && selectedCult ? 
                                    <Geases cult={selectedCult} count={geasCount} update={updateGeases} />
                                : null}
                            </div>
                        </div>
                    </>
                    : 
                    null
                    }
                </div>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                    {displayCult ? 
                        <CultDetails item={displayCult} />
                    : null }
                </div>
        </div>
    );
}

export default Cults;