import React, { useEffect, useState } from "react";
import { withPageState } from "../Context/PageState";
import { v4 as uuidv4 } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import deleteIcon from '../../icons/delete-icon-red.svg'
import DeleteDialog from '../Dialogs/deleteDialog';

const CustomRuneSpells = (props) => {

    const[spells, setSpells] = useState([]);
    const [deletedSpell, setDeletedSpell] = useState();
    const [confirm, setConfirm] = useState(false);

    const defaultNewSpell={name: "New Rune Spell", description: "Description", duration: "Temporal", effects: [], points: 1, range: "Ranged", type: "Passive", target: {type: "Character"}, stackable: false, filter: {}, runes: []}

    useEffect(() => {
        if (props.campaign.runeSpells) {
            setSpells([...props.campaign.runeSpells]);
        }
    },[props.campaign]);

    const displayItem = (spell) => {
        props.displayItem({type: "CustomRuneSpell", item: spell})
    }

    const createSpell = () => {
        let spell = {...defaultNewSpell};
        spell.id = uuidv4();
        spell.parentId = spell.id;
        displayItem(spell);
    }

    function deleteItem(event, cult) {
        event.stopPropagation();
        setConfirm(true);
        setDeletedSpell(cult);
    }

    function confirmDelete() {
        props.delete(deletedSpell);
        setConfirm(false);
    }

    return (
        <div style={{border: "2px solid #2f588a", borderRadius: "5px", width: "100%", maxHeight: "300px"}}>
            <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div>Custom Rune Spells</div>
                <div style={{width: "30px"}}>
                    {props.pageState.editMode ? 
                        <AddCircleOutlineIcon onClick={createSpell} style={{height:"24px", width:"24px", color: "#fff"}}/>
                        :
                    null
                    }
                </div>
            </div>
            <div style={{display: "flex", alignItem: "center", padding: "10px", fontWeight: "bold"}}>
                <div style={{width: "200px"}}>Name</div>
                <div style={{width: "60px", textAlign: "center"}}>Points</div>
                <div style={{width: "100px", textAlign: "center"}}>Stackable</div>
                <div style={{width: "100px", textAlign: "center"}}>Restricted</div>
                <div style={{width: "100px", textAlign: "center"}}>Duration</div>
                <div style={{width: "100px", textAlign: "center"}}>Range</div>
                <div style={{width: "100px", textAlign: "center"}}>Type</div>
                <div style={{}}>Description</div>
            </div>
            {spells.map((spell, index) => {
                return (
                    <div key={index}>
                        <div style={{display: "flex", alignItems: "center", padding: "6px 10px", cursor: "pointer"}} onClick={() => displayItem(spell)}>
                            <div style={{width: "200px"}}>{spell.name}</div>
                            <div style={{width: "60px", textAlign: "center"}}>{spell.points}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{spell.stackable ? "Yes": "No"}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{spell.playable ? "Yes": "No"}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{spell.duration}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{spell.range}</div>
                            <div style={{width: "100px", textAlign: "center"}}>{spell.type}</div>
                            <div style={{width: "369px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}} dangerouslySetInnerHTML={{__html: spell.description}} />
                            {props.pageState.editMode ? 
                                <div style={{cursor: "pointer"}} onClick={(event) => deleteItem(event, spell)}>
                                    <img src={deleteIcon} style={{height: "24px"}} />
                                </div>
                            : 
                            null}
                       </div>
                    </div>
                )
            })}
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="rune spell" />
        </div>
    )
}

export default withPageState(CustomRuneSpells);