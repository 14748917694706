import React from 'react';

const OccupationDetails = (props) => {

    if (props.item && props.item.cults) {
        var cults = props.item.cults[0];
        for(var i = 1; i < props.item.cults.length; i++) {
            cults += ", " + props.item.cults[i];
        }
        return (
            <div style={{padding: "20px", backgroundColor: "#676767", color: "#fff", border:"5px solid #454645", borderRadius: "15px"}}>
                <div id="props.item-rune" style={{fontSize:"24px"}}>
                    {props.item.name}
                </div>
                <div style={{margin: "5px 0px", marginBottom: "20px"}} dangerouslySetInnerHTML={{__html: props.item.description}}></div>
                <div style={{margin: "0px", display:"flex"}}>
                    <div style={{display:"flex", margin: "10px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Base Income:&nbsp;</div>
                        <div>{props.item.income}</div>
                    </div>
                    <div style={{display:"flex", margin: "10px 20px"}}>
                        <div style={{fontWeight: "bold"}}>Ransom:&nbsp;</div>
                        <div>{props.item.ransom}</div>
                    </div>
                </div>
                <div style={{}}>
                    <div style={{display:"flex", margin: "10px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Standard of Living:&nbsp;</div>
                        <div>{props.item.livingStandard}</div>
                    </div>
                </div>
                <div style={{}}>
                    <div style={{display:"flex", margin: "10px 0px"}}>
                        <b>Favored Passions:&nbsp;</b>
                        {props.item.passions.map((passion, index) => {
                            return (
                                <div key={index}>{passion.name + (passion.focus ? " (" + passion.focus + "), " : ", ")}</div>
                            )
                        })}
                    </div>
                    <div style={{display:"flex", margin: "10px 0px"}}>
                        <div><b>Cults:&nbsp;</b>{cults}</div>
                    </div>
                    <div style={{display:"flex", margin: "10px 0px"}}>
                        <div><b>Equipment:&nbsp;</b>{props.item.equipmentList}</div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default OccupationDetails;
