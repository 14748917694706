import React, {useState, useEffect} from 'react';
import { withPageState } from '../Context/PageState';
import { withContext } from '../Context/context';
import globalStyles from '../Styles/styles.module.css';
import { FormControl, Checkbox } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';

const InventoryWeapon = (props) => {

    const [checked, setChecked] = useState(false);
    const [variant, setVariant] = useState("");

    useEffect(() => {
        let index = props.selected.findIndex(obj => obj.parentId === props.item.id);
        if (index > -1) {
            setChecked(true);
        }
    },[props.selected, props.item])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.select(props.item, event.target.checked)
    };

    return (
        <>
            <div className={globalStyles.row} style={{display:"flex", height: "24px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px", justifyContent: "space-between"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                            <FormControl variant="outlined" 	>
                                <Checkbox checked={checked} color='primary' onChange={handleChange} />
                            </FormControl>
                        </div>
                        <div style={{width:"150px", alignItems: "center", display: "flex"}}>{props.item.name}</div>
                        <div style={{width:"150px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.baseValue}</div>
                        <div style={{width:"100px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.skillCategory}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withContext(withPageState(InventoryWeapon));