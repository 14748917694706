import React, {useState, useEffect, useRef} from 'react';
import { withPageState } from '../Context/PageState';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { getAllCults, getAllPassions, getAllRunes, getAllRuneSpells, getAllSkills, getAllSpiritSpells } from '../../services/dataService';
import MultiSelect from '../Controls/multiSelect';
import DialogBox from '../Dialogs/dialogBox';
import EditSkillsDialog from './editSkillsDialog';
import compareValues from '../../utils/compareValues';
import campaign from './campaign';
import DescriptionDialog from './descriptionDialog';

const pantheons=[{id: "Lightbringers", name: "Lightbringers"}, {id: "Earth", name: "Earth"}, {id: "Lunar", name: "Lunar"}, {id: "Solar", name: "Solar"}, {id: "Water", name: "Water"}, {id: "Darkness", name: "Darkness"}, {id: "Lords of Terror", name: "Lords of Terror"}, ]
const freeSkills = [
	{
	  bonus: 15,
	  options: [
		{
		  name: "Cult Lore",
		  parentId: "5eb111a0-bea7-11ea-afe0-c72b6ad5d25a",
		  variant: "<deity>"
		}
	  ]
	},
	{
	  bonus: 5,
	  options: [
		{
		  name: "Meditate",
		  parentId: "6033e110-bea7-11ea-afe0-c72b6ad5d25a"
		}
	  ]
	},
	{
	  bonus: 10,
	  options: [
		{
		  name: "Worship",
		  parentId: "60953af0-bea7-11ea-afe0-c72b6ad5d25a",
		  variant: "<deity>"
		}
	  ]
	}
]


const CustomCultContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [campaign, setCampaign] = useState(props.campaign);
	const [cult, setCult] = useState(null);
	const [openSkills, setOpenSkills] = useState(false);
	const [openPassions, setOpenPassions] = useState(false);
	const [selected, setSelected] = useState({
		runes: [],
		commonRuneSpells: [],
		runeSpells: [],
		spiritSpells: [],
		skills: [],
		prohibited: [],
		associated: []
	})

	useEffect(() => {
		if (props.panelItem) {
			setCult(props.panelItem.item);
			let selectedDetails = {}
			selectedDetails.runes = props.panelItem.item.runes;
			selectedDetails.commonRuneSpells = getNames(props.panelItem.item.commonRuneSpells);
			selectedDetails.runeSpells = getNames(props.panelItem.item.runeSpells);
			selectedDetails.spiritSpells = getNames(props.panelItem.item.spiritSpells);
			selectedDetails.skills = props.panelItem.item.skills;
			selectedDetails.prohibited = getNames(props.panelItem.item.prohibited);
			selectedDetails.associated = getNames(props.panelItem.item.associated);
			setSelected(selectedDetails);
		}
	},[props.cult]);

    useEffect(() => {
        if (props.buttonClick === "ok") {
			let updatedCampaign = {...campaign};
			updatedCampaign.cults.forEach(cult => {
				cult.freeSkills = [];
				freeSkills.forEach(freeSkill => {
					let freeSkillCopy = {...freeSkill};
					if (freeSkillCopy.options[0].variant) {
						freeSkillCopy.options[0].variant = cult.name; 
					}
					cult.freeSkills.push(freeSkillCopy);
				})
			})
			props.update(updatedCampaign, true);
        }
    },[props.buttonClick])

	function getNames(items) {
		let names = [];
		items.forEach(item => {
			names.push(item.name);
		})
		return names;
	}

	function updateSkills(fieldName, selectedItems, getAllItems) {
		let updatedSelected = {...selected};
		let cultSkills = []
		selectedItems.forEach(skill => {
			let newSkill = {}
			if (skill.variant) {
				newSkill = {bonus: 0, options: [{name: skill.name, parentId: skill.parentId, variant: skill.variant}]}
			}
			else {
				newSkill = {bonus: 0, options: [{name: skill.name, parentId: skill.parentId}]}
			}
			cultSkills.push(newSkill);
		})
		updatedSelected[fieldName] = cultSkills;
		setSelected(updatedSelected)
		setValue(fieldName, cultSkills);
	}

	function setCultRunes(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.runes = selectedItems;
		setSelected(updatedSelected);
		setValue("runes", selectedItems);
	}

	function updateCult(fieldName, selectedItems, getAllItems) {
		var allItems = getAllItems(props.campaign);
		let updatedSelected = {...selected};
		updatedSelected[fieldName] = selectedItems;
		let cultItems = []
		updatedSelected[fieldName].forEach(name => {
			const index = allItems.findIndex(obj => obj.name === name)
			if (index > -1) {
				let item = {...allItems[index]}
				cultItems.push({name: name, parentId: item.id});
			}
		})
		setSelected(updatedSelected)
		setValue(fieldName, cultItems);
	}

	function setValue(field, value) {
		var updatedCult = {...cult}
		updatedCult[field] = value;
		setCult(updatedCult);
		updateCampaign(updatedCult);
	}

    function applyDescription(editorContent, title, index) {
		var updatedCult = {...cult}
        updatedCult.description = editorContent;
        setCult(updatedCult);
		updateCampaign(updatedCult)
    }

	function updateCampaign(updatedCult) {
		var updatedCampaign = {...campaign}
		let index = updatedCampaign.cults.findIndex(obj => obj.id === updatedCult.id)
		if (index > -1) {
			updatedCampaign.cults[index] = updatedCult;
		}
		else {
			updatedCampaign.cults.push(updatedCult);
		}
		setCampaign(updatedCampaign)
	}

	function getSkills() {
		
		var skills = [];
		cult.skills.forEach(skill => {
			skills.push(skill.options[0])
		});
		cult.freeSkills.forEach(skill => {
			skills.push(skill.options[0])
		});
		return skills.sort(compareValues("name", "asc"));
	}

	if (cult) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: (props.height - 100) + "px", overflow: "auto", padding: "5px"}}>
						{props.pageState.editMode ? 
							<>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div>Cult name: </div>
									<EditBox size="sm" width="200px" value={cult.name} onChange={(value) => setValue("name", value)} />
								</div>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px 0px"}}>
									<div>Cult subtitle: </div>
									<EditBox size="sm" width="200px" value={cult.subtitle} onChange={(value) => setValue("subtitle", value)} />
								</div>
							</>
							: 
							<div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
								<div style={{fontWeight: "bold", marginRight: "20px"}}>Name: </div>
								<div>{cult.name}</div>
								<div style={{marginLeft: "10px"}}>{cult.subtitle ? "(" + cult.subtitle + ")": ""}</div>
							</div>
						}
						{props.pageState.editMode ? 
							<>
							<Selector labelMargin="43px" label="Pantheon:" style={{height: "28px", marginTop: "10px"}} value={cult.pantheon} values={pantheons} onChange={(item) => {setValue("pantheon", item.name)}} />
							<FormGroup>
								<FormControlLabel control={<Switch color="primary" checked={cult.restricted} onChange={(event) => setValue("restricted", event.target.checked)}></Switch>} label="Restricted"></FormControlLabel>
							</FormGroup>
							</>
						: 
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "166px"}}>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Pantheon: </div>
								<div>{cult.pantheon}</div>
							</div>
							<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Restricted: </div>
								<div>{cult.restricted ? "Yes": "No"}</div>
							</div> 
						</div>
						}
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Runes: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRunes(props.campaign))} selectedValues={selected.runes} onChange={setCultRunes} />
								: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.runes && cult.runes.length > 0 && cult.runes.map((rune, index) => (
									rune + (index < cult.runes.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Common Rune Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRuneSpells(props.campaign).filter(function (spell){return spell.common}))} selectedValues={selected.commonRuneSpells} onChange={(selected) => updateCult("commonRuneSpells", selected, getAllRuneSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.commonRuneSpells && cult.commonRuneSpells.length > 0 && cult.commonRuneSpells.map((spell, index) => (
									spell.name + (index < cult.commonRuneSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Special Rune Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRuneSpells(props.campaign))} selectedValues={selected.runeSpells} onChange={(selected) => updateCult("runeSpells", selected, getAllRuneSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.runeSpells && cult.runeSpells.length > 0 && cult.runeSpells.map((spell, index) => (
									spell.name + (index < cult.runeSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						{/* <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Enchantments: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={commonSpells} selectedValues={selectedCommon} onChange={updateCommon} />
							: 
								<div>{cult.commonRuneSpells && cult.commonRuneSpells.length > 0 && cult.commonRuneSpells.map((spell, index) => (
									spell.name + (index < cult.commonRuneSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div> */}
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Spirit Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllSpiritSpells(props.campaign))} selectedValues={selected.spiritSpells} onChange={(selected) => updateCult("spiritSpells", selected, getAllSpiritSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.spiritSpells && cult.spiritSpells.length > 0 && cult.spiritSpells.map((spell, index) => (
									spell.name + (index < cult.spiritSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Skills: </div>
									<Button style={{width: "135px"}} variant='contained' color='primary' onClick={() => setOpenSkills(true)}>Edit Skills</Button>
								</div>
							: 
							<>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Skills: </div>
							<div style={{display: "flex", alignItems: "center"}}>{getSkills().map((skill, index) => (
									skill.name + (skill.variant ? " (" + skill.variant + ")" : "") + (index < (cult.skills.length + (cult.freeSkills ? cult.freeSkills.length : 0)) -1 ? ", " : "")
								))}</div>
							</>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Favored Passions: </div>
									<Button style={{width: "135px"}} width="135px" variant='contained' color='primary' onClick={() => setOpenPassions(true)}>Edit Passions</Button>
								</div>
						: 
							<>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Favored Passions: </div>
								<div style={{display: "flex", alignItems: "center"}}>{cult.passions && cult.passions.length > 0 && cult.passions.map((passion, index) => (
									passion.name + (passion.focus ? " (" + passion.focus + ")" : "") + (index < cult.passions.length -1 ? ", " : "")
								))}</div>
							</>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Prohibited Spirit Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllSpiritSpells(props.campaign))} selectedValues={selected.prohibited} onChange={(selected) => updateCult("prohibited", selected, getAllSpiritSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.prohibited && cult.prohibited.length > 0 && cult.prohibited.map((spell, index) => (
									spell.name + (index < cult.prohibited.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Associated Cults: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllCults(props.campaign))} selectedValues={selected.associated} onChange={(selected) => updateCult("associated", selected, getAllCults)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.associated && cult.associated.length > 0 && cult.associated.map((assoc, index) => (
									assoc.name + (index < cult.associated.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{marginTop: "20px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{fontWeight: "bold"}}>Description</div>
									<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
								</div>
							: 
								<>
									<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
									<div dangerouslySetInnerHTML={{__html: cult.description}}></div>
								</>
							}
						</div>
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={cult.description} />
				</DialogBox>
				<DialogBox isOpen={openSkills} title="Cult Skills" width="600px">
					<EditSkillsDialog update={(selected) => updateSkills("skills", selected, getAllSkills)} campaign={props.campaign} selected={selected.skills} close={() => setOpenSkills(false)} />
				</DialogBox>
			</>
		)
	}
	else {
		return null;
	}
}
export default withPageState(withFirebase(CustomCultContextPanel));