import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import DamageButton from '../Buttons/damageButton';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
import CrushingDamageButton from './crushingDamageButton';
import SlashingDamageButton from './slashingDamageButton';
import WeaponButton from './weaponButton';
import ModifierBox from '../Controls/modifierBox';
import EditBox from '../Controls/editBoxSingle';
import { withContext } from '../Context/context';
import { withPageState } from '../Context/PageState';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import DiceButton from '../Buttons/diceButton';
import SmallDiceButton from '../Buttons/smallDiceButton';
import * as SAVE from '../../constants/save';
import SpiritCombatDamageButton from './spiritCombatDamageButton';
import { getEnchantmentsModifier } from '../../services/enchantmentsService';
import { getAllSkills } from '../../services/dataService';

const SpiritCombatTileContextMenu = (props) => {

    const [modifier, setModifier] = useState("");
    const [selectedType, setSelectedType] = useState("Normal");
    const [item, setItem] = useState({...props.panelItem.item});
    const [hidden, setHidden] = useState(false);
    const [categoryModifier, setCategoryModifier] = useState(0);
    const [enchantmentsModifier, setEnchantmentsModifier] = useState(0);
    const [currentAugment, setCurrentAugment] = useState(0);
    const [description, setDescription] = useState("");

    const allSkills = getAllSkills(props.providerData.campaign)

    const damageTypes=["Normal", "Special", "Critical"]

    useEffect(() => {
        setItem({...props.panelItem.item});
    },[props.panelItem.item])

    useEffect(() => {
        setCategoryModifier(props.providerData.character.categoryModifiers.Magic || 0);
        var index = props.providerData.character.skills.findIndex(obj => obj.name === "Spirit Combat");
        if (index > -1) {
            var updatedItem = props.providerData.character.skills[index];
            var value = updatedItem.value + (props.providerData.character.categoryModifiers.Magic || 0);
            var enchantmentsMod = getEnchantmentsModifier("Skill", props.providerData.character, updatedItem, value);
            setEnchantmentsModifier(enchantmentsMod);
        }
        var skillIndex = allSkills.findIndex(obj => obj.name === "Spirit Combat");
        if (skillIndex > -1) {
            setDescription(allSkills[skillIndex].description);
        }
    },[props.providerData.character.categoryModifiers])

    useEffect(() => {
        if (props.buttonClick === "ok") {
            update(item, SAVE.NO);
        }
    },[props.buttonClick])

    function handleChange(event, ) {
        var type = event.target.value;
        setSelectedType(type)
    }

    function handleSkillChange(field, value, ) {
        var updatedItem = {...item};
        updatedItem.skill[field] = value;
        setItem(updatedItem);
    }

    function update(updatedItem, persist) {
        console.log('updatedItem', updatedItem)
        var updatedSkills = [...props.entity.skills]
        var index = updatedSkills.findIndex(obj => obj.name === "Spirit Combat")
        updatedSkills[index].value = updatedItem.skill.value;
        props.update([{fieldName: "skills", value: updatedSkills}], persist);
    }

    const classNameGenerator = (...classes)=>{
        return classes.join(" ");
    }

    const processResult = (roll) => {
        var messageText={critical: " achieved a critical success with {pronoun} Spirit Combat skill", special: " achieved a special success with {pronoun} Spirit Combat skill", success: " succeeded with {pronoun} Spirit Combat skill", failure: " failed with {pronoun} Spirit Combat skill", fumble: " fumbled {pronoun} Spirit Combat skill"};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment, hidden: hidden}
        props.processResult(dieRoll, {...roll.action, type:"skills"}, roll.title)
    }

    if (props.pageState.editMode){
        return(
                <div style={{padding: "10px"}}>
                    <div style={{display:"flex", margin: "10px", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{marginRight: "10px"}}>Value*: </div>
                        <EditBox onChange={(value) => handleSkillChange("value", value)} value={item.skill.value} width="100px" number>{item.skill.value}</EditBox>
                    </div>
                    <div>* Does not include any category modifiers</div>
                <hr style={{width:"100%"}} />
                <div style={{fontWeight: "bold", lineHeight: "20px"}}>
                    <div>Base Value: {item.skill.value}%{((categoryModifier && item.skill.value > 0) ? " + Category Modifier (" + categoryModifier + "%)" + " = " + (item.skill.value + categoryModifier) + "%" : "")} </div>
                    {enchantmentsModifier ? 
                        <div>Enchantments: {enchantmentsModifier}%</div>
                    : null}
                    {currentAugment ? 
                        <div>Inspiration: {currentAugment}%</div>
                    : null}
                    {props.providerData.character.despondent ? 
                        <div>Despondent: -10%</div>
                    : null}
                </div>
                <hr style={{width:"100%"}} />
                <div style={{}}>
                    <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{item.name}</div>
                    <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px"}}>
                        <div dangerouslySetInnerHTML={{__html: description}}></div>
                    </div>
                </div>
                </div>
        )
    }
    else {
        return (
                <div style={{padding: "10px", fontSize: "14px", lineHeight: "1"}}>
                    <ModifierBox handleChange={(value) => setModifier(value)} />
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                        <div>Attack/Defend</div>
                        <div style={{width: "100px", marginRight: "4px"}}>
                            <DiceButton diceRoller={props.diceRoller} item={item.skill} processResult={processResult} entity={props.entity} modifier={modifier} >
                                <SmallDiceButton />
                            </DiceButton>
                        </div>
                    </div>
                    <hr style={{width:"360px"}} />

                    <div style={{width: "100%", display: "flex", padding: "10px 0px", alignItems: "center"}}>
                        <div style={{fontSize: "14px"}}>
                            <FormControl variant="outlined">
                                <FormLabel id="damage-type-label">Damage type</FormLabel>
                                <RadioGroup aria-label="damage-type-label" row color="primary" value={selectedType} onChange={handleChange}>
                                    {damageTypes.map(type => {
                                        return (
                                            <FormControlLabel key={type} style={{fontSize: "14px"}} checked={selectedType == type} value={type} control={<Radio color='primary' />} label={<span style={{fontSize: "14px"}}>{type}</span>} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div style={{width:"160px", display:"flex", justifyContent:"flex-end", alignItems: "center"}}>
                            <div className={classNameGenerator(globalStyles.largeDamageButton, globalStyles.buttonTile)} style={{width: "110px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <SpiritCombatDamageButton processDamage={props.processDamage} weapon={item.weapon} entity={props.entity}>
                                    <DamageButton diceRoller={props.diceRoller} type={selectedType} />
                                </SpiritCombatDamageButton>
                            </div>
                        </div>
                    </div>
                    <hr style={{width:"360px"}} />
                    <div>
                        <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>Spirit Combat</div>
                        <div style={{fontWeight: "bold"}}>Base Value: {item.skill.value}%{(categoryModifier ? " + Category Modifier (" + categoryModifier + "%) = " + (item.skill.value + categoryModifier) + "%": "")}</div>
                        <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px"}}>
                            <div dangerouslySetInnerHTML={{__html: "Spirit Combat covers contests of psychic energy, such as when a spirit tries to possess (or intrude into) a corporeal body, or a shaman tries to bind a spirit, or when two magicians enter a trance, discorporate, and do magical battle. See the Spirits chapter for more information about this skill. This skill may increase from experience or from training.<p>Spirit Combat may take many different forms, depending on the magic technique used by the adventurer.</p>"}}></div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default withPageState(withContext(SpiritCombatTileContextMenu));