import React, {useState, useEffect} from "react";
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import globalStyles from '../../Styles/styles.module.css';
import AddItemButton from "../buttons/addItemButton";
import ManageSpiritSpellDialog from "../../Magic/manageSpiritSpellDialog";
import * as SAVE from '../../../constants/save'
import { calculateSpellStorage } from '../../../services/magicPointsService';
import compareValues from "../../../utils/compareValues";

const SpiritMagicPanel = (props) => {

    const [storage, setStorage] = useState(-1);

    useEffect(() => {
        var storage = calculateSpellStorage(props.providerData.character);
        setStorage(storage);
    },[props.providerData.character]);
    
    function setSpells(newSpells) {
        var updatedSpells = [...newSpells];
        props.update([{fieldName: "spiritSpells", value: updatedSpells}], SAVE.NO)
    }

    return (
        <div style={{border: "2px solid #676767", margin: "5px", width: "260px"}}>
        <div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between "}}>
            <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Spirit Magic</div>
            <div style={{width: "30px"}}>
                {props.pageState.editMode ?
                    <AddItemButton edit addTitle="Edit spirit spells" title="Manage spirit spells" width="800px">
                        <ManageSpiritSpellDialog currentSpells={props.providerData.character.spiritSpells} storage={storage} setSpells={setSpells} />
                    </AddItemButton>
                : 
                null}
            </div>
        </div>
        { 
            props.providerData.character.spiritSpells.length > 0 ? 
                props.providerData.character.spiritSpells.sort(compareValues('name')).map((item, index) => {
                    return(
                        <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                            <div>{item.name}{item.variant ? " " + item.variant : ""}</div>
                            {item.variable ? 
                                <div style={{marginRight: "12px"}}>{item.points}</div>
                                : 
                                <div style={{marginRight: "12px"}}>{item.points}{item.points == 1 ? "pt" : "pts"}</div>
                            }
                        </div>
                    );
                })
            : 
            <div style={{padding: "10px"}}>None</div>
        }
    </div>

    )
}

export default withPageState(withContext(SpiritMagicPanel))