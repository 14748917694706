import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import SpiritSpellListItem from './spiritSpellListItem';

const SpiritSpellList = (props) => {

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", height: "500px", fontSize: "14px"}}>
			<div style={{display:"flex", padding: "5px", width: "99%", margin: "2px", fontWeight: "bold"}}>
				<div style={{width:"50px"}}></div>
				<div style={{width:"180px"}}>Spell</div>
				<div style={{width:"80px", textAlign:"center"}}>Points</div>
				<div style={{width:"100px", textAlign:"center"}}>Variable</div>
				<div>Description (click to show or hide details)</div>
			</div>
			<div style={{maxHeight: "480px", overflow: "auto", ...props.style}}>
			{props.spells.map((spell, index) => {
					return(
						<SpiritSpellListItem campaign={props.campaign} key={index} selected={props.selected} spell={spell} amendSpell={props.amendSpell} availablePoints={props.availablePoints} select={props.select}/>
					)
			})}
			</div>
		</div>
	);
};

export default withFirebase(SpiritSpellList);