import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context'
import { Button } from '@mui/material';
import * as SAVE from '../../constants/save';
import { applySpellEffects } from '../../services/spellsService';
import compareValues from '../../utils/compareValues';
import RuneSpellRow from './runeSpellRow';
import { getRuneSpell } from '../../services/dataService';

const runeSpells = [{id: "475b8210-da36-11ea-a1ab-954a693b194a", name: "Axe Trance"}, {id: "478c07f0-da36-11ea-a1ab-954a693b194a", name: "Bear's Skin"}, {id: "4795cbf0-da36-11ea-a1ab-954a693b194a", name: "Bear's Strength"}, {id: "47b33f00-da36-11ea-a1ab-954a693b194a", name: "Berserker"},{id: "484e4680-da36-11ea-a1ab-954a693b194a", name: "Charisma"}, {id: "485fd2b0-da36-11ea-a1ab-954a693b194a", name: "Claws"}, {id: "486bb990-da36-11ea-a1ab-954a693b194a", name: "Clever Tongue"}, {id: "4b9449c0-da36-11ea-a1ab-954a693b194a", name: "Shield"}, {id: "4b9a3d30-da36-11ea-a1ab-954a693b194a", name: "Slash"}, {id: "4bc98a90-da36-11ea-a1ab-954a693b194a", name: "Spirit Block"}, {id: "4c521b80-da36-11ea-a1ab-954a693b194a", name: "Sword Trance"}, {id: "4c842800-da36-11ea-a1ab-954a693b194a", name: "True Weapon"}]

const ApplyRuneSpellDialog = (props) => {
    const [selected, setSelected] = useState({});
    const [selectedItem, setSelectedItem] = useState();
    const [spells, setSpells] = useState([]);

    useEffect(() => {
        var eligibleSpells = []
        runeSpells.forEach(validSpell => {
            let spell = getRuneSpell(validSpell.id)
            if (spell) {
                eligibleSpells.push({...spell})
            }
        });
        if (props.providerData.campaign.runeSpells) {
            props.providerData.campaign.runeSpells.forEach(customSpell => {
                if (customSpell.effects && customSpell.effects.length > 0) {
                    eligibleSpells.push(customSpell);
                } 
            })
        }
        setSpells(eligibleSpells.sort(compareValues('name')))
    },[]);

    function handleOkClick() {

        var updatedCharacter = {...props.providerData.character};
        var effects = selected.effects;
        var spellDetails = {
            spell: selected,
            selection: selectedItem, 
            points: selected.points
        }

        var updates = applySpellEffects(updatedCharacter, spellDetails, effects);

        props.update(updates, SAVE.YES)
        props.close();
    }

    function handleCancelClick() {
        props.close();
    }

    function select(checked, spell, item) {
        if (checked) {
            setSelected(spell);
            setSelectedItem(item)
        }
        else {
            setSelected(null)
            setSelectedItem(null)
        }
    }

    function amendSpell(spell) {
        setSelected(spell)
    }

    return (
        <div>
            <div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", height: "500px", overflow: "auto", fontSize: "14px", backgroundColor: "#fff"}}>
                <div style={{padding: "10px"}}>
                    <div>To apply a rune spell to this character, select the spell from the list below. Only one spell can be selected at a time. If the spell is a stackable spell, use the + and - to adjust the strength of the spell. If the spell applies to a specific weapon, use the drop down to select that weapon.</div>
                    <div style={{marginTop: "15px"}}> Once complete, click OK to apply the effects of the spell to your character.</div>
                </div>
                <div style={{backgroundColor: "#fff"}}>
                    <div style={{display:"flex", padding: "5px", width: "99%", margin: "2px", fontWeight: "bold"}}>
                        <div style={{width:"50px"}}></div>
                        <div style={{width:"180px"}}>Spell</div>
                        <div style={{width:"80px", textAlign:"center"}}>Points</div>
                    </div>
                    <div style={{maxHeight: "480px", overflow: "auto", ...props.style}}>
                        {spells.map((spell, index) => {
                                return(
                                    <RuneSpellRow key={index} selected={selected} spell={spell} amendSpell={amendSpell} selectItem={setSelectedItem} availablePoints={999} select={select}/>
                                )
                        })}
                    </div>
                </div>
            </div>
            <hr style={{margin: 0}} />
            <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end", padding: "10px"}}>
                <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default withContext(ApplyRuneSpellDialog)