import React, { useState, useEffect} from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { withFirebase } from '../Firebase';
import * as NewCharacter from '../../data/newCharacter.json';
import * as ExistingCharacter from '../../data/existingCharacter.json';
import { createBlankCharacter } from '../../services/characterService';

const CreateCharacterDialog = (props) => {
	const navigate = useNavigate();

	function createCharacter(authUser, useBuilder) {
		var char = useBuilder ? {...NewCharacter} : {...ExistingCharacter};
		var newcharacter = char.default;
		newcharacter.uid = authUser.uid;
		var now = new Date();
		newcharacter.name = {first: authUser.firstName + " " + now.getHours() + now.getMinutes() + now.getSeconds()} ;
		props.firebase
		.characters()
		.add(
			newcharacter
		)
		.then(function(docRef) {
            newcharacter.id = docRef.id
            newcharacter.campaignId = docRef.id
            newcharacter.created = new Date();
            props.firebase
            .character(docRef.id)
            .set(newcharacter)
            .then( function() {
                navigate("/builder/" + docRef.id);
            });
		})
	}

    return (
        <div style={{padding: "20px"}}>
            <div  style={{display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:"space-between", padding:"0px 20px", overflow:"hidden", }}>
                <h3 style={{marginLeft: "20px"}}>What would you like to do today?</h3>
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                    <div>
                        <Button onClick={() => createCharacter(props.authUser, true)} style={{width:"480px", height:"200px"}} size="large" variant="contained" color="primary">
                            <div>
                                <h3>Create a new adventurer</h3>
                                <p>Create a new adventurer using the RuneQuest character generation rules. This will take you through each step listed in the RuneQuest core rulebook.</p>
                            </div>
                        </Button>
                    </div>
                </div>
                <h3 style={{marginLeft: "20px"}}>Or...</h3>
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", marginTop:"10px"}}>
                    <div>
                        <Button disabled={false} onClick={props.importCharacter} style={{width:"480px", height:"200px"}} size="large" variant="contained" color="primary">
                            <div>
                                <h3>Import an existing character</h3>
                                <p>Import an existing adventurer previously created using the RuneQuest character generation system.</p>
                            </div>
                        </Button>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", marginTop:"30px"}}>
                    <div>
                        <Button onClick={() => props.close()} style={{width:"480px", height:"60px", backgroundColor:"#bbbbbb", color: "#2f588a"}} size="large" variant="outlined" color="primary">
                            <div>
                                <h3>Close</h3>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>                

    );
}

export default withFirebase(CreateCharacterDialog);