import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context'
import { Button } from '@mui/material';
import * as SAVE from '../../constants/save';
import SpiritSpellRow from './spiritSpellRow';
import { applySpellEffects } from '../../services/spellsService';
import compareValues from '../../utils/compareValues';
import { getSpiritSpell } from '../../services/dataService';

const spiritSpells = [{id: "796c7c70-d052-11ea-a3e0-8702f9fd9b66", name: "Spirit Screen",}, {id: "acb387d0-d01c-11ea-a5a6-031bfb87f1db", name: "Bladesharp"}, {id: "ddd125a0-da7d-11ea-b960-4bca669b4931", name: "Ironhand"}, {id: "ad20efa0-d01c-11ea-a5a6-031bfb87f1db", name: "Shimmer"}, {id: "ae4bb400-d01c-11ea-a5a6-031bfb87f1db", name: "Demoralize"}, {id: "ae800a70-d01c-11ea-a5a6-031bfb87f1db", name: "Speedart"},{id: "af1c9890-d01c-11ea-a5a6-031bfb87f1db", name: "Protection"},{id: "dc8062b0-da7d-11ea-b960-4bca669b4931", name: "Bludgeon"},{id: "dca15830-da7d-11ea-b960-4bca669b4931", name: "Coordination"},{id:"ix73hu9FxWN2wxcyIAfZ", name: "Glamour"},{id: "dd5e8db0-da7d-11ea-b960-4bca669b4931", name: "Dullblade"},{id: "dd6eba50-da7d-11ea-b960-4bca669b4931", name: "Fanaticism"},{id: "dd94dff0-da7d-11ea-b960-4bca669b4931", name: "Firearrow"},{id: "dda6e150-da7d-11ea-b960-4bca669b4931", name: "Fireblade"},{id: "de092590-da7d-11ea-b960-4bca669b4931", name: "Mobility"},{id: "de2698a0-da7d-11ea-b960-4bca669b4931", name: "Parry"},{id: "de58f340-da7d-11ea-b960-4bca669b4931", name: "Slow"},{id: "de8a6380-da7d-11ea-b960-4bca669b4931", name: "Strength"}, {id:"58a93d90-d032-11ea-95c7-dd3621a1bc0c", name: "Vigor"}, ]

const ApplySpiritSpellDialog = (props) => {
    const [selected, setSelected] = useState({});
    const [selectedItem, setSelectedItem] = useState();
    const [spells, setSpells] = useState([]);

    useEffect(() => {
        var eligibleSpells = []
        spiritSpells.forEach(validSpell => {
            let spell = getSpiritSpell(validSpell.id)
            if (spell) {
                eligibleSpells.push({...spell})
            }
        });
        if (props.providerData.campaign.spiritSpells) {
            props.providerData.campaign.spiritSpells.forEach(customSpell => {
                if (customSpell.effects && customSpell.effects.length > 0) {
                    eligibleSpells.push(customSpell);
                } 
            })
        }
        setSpells(eligibleSpells.sort(compareValues('name')))
    },[]);

    function handleOkClick() {

        var updatedCharacter = {...props.providerData.character};
        var effects = selected.effects;
        var spellDetails = {
            spell: selected,
            selection: selectedItem, 
            points: selected.points
        }

        var updates = applySpellEffects(updatedCharacter, spellDetails, effects);

        props.update(updates, SAVE.YES)
        props.close();
    }

    function handleCancelClick() {
        props.close();
    }

    function select(checked, spell, item) {
        if (checked) {
            setSelected(spell);
            setSelectedItem(item)
        }
        else {
            setSelected(null)
            setSelectedItem(null)
        }
    }

    function amendSpell(spell) {
        setSelected(spell)
    }

    return (
        <div>
            <div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", height: "500px", overflow: "auto", fontSize: "14px", backgroundColor: "#fff"}}>
                <div style={{padding: "10px"}}>
                    <div>To apply a spirit spell to this character, select the spell from the list below. Only one spell can be selected at a time. If the spell is a variable spell, use the + and - to adjust the strength of the spell. If the spell applies to a specific weapon, use the drop down to select that weapon.</div>
                    <div style={{marginTop: "15px"}}> Once complete, click OK to apply the effects of the spell to your character.</div>
                </div>
                <div style={{backgroundColor: "#fff"}}>
                    <div style={{display:"flex", padding: "5px", width: "99%", margin: "2px", fontWeight: "bold"}}>
                        <div style={{width:"50px"}}></div>
                        <div style={{width:"180px"}}>Spell</div>
                        <div style={{width:"80px", textAlign:"center"}}>Points</div>
                    </div>
                    <div style={{maxHeight: "480px", overflow: "auto", ...props.style}}>
                        {spells.map((spell, index) => {
                                return(
                                    <SpiritSpellRow key={index} selected={selected} spell={spell} amendSpell={amendSpell} selectItem={setSelectedItem} availablePoints={999} select={select}/>
                                )
                        })}
                    </div>
                </div>
            </div>
            <hr style={{margin: 0}} />
            <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end", padding: "10px"}}>
                <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default withContext(ApplySpiritSpellDialog)