import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { withPageState } from '../../Context/PageState';
import CharacterContext from '../../Context/context';
import * as SAVE from '../../../constants/save';
import globalStyles from '../../Styles/styles.module.css';
import styles from '../character.module.css';
import {getResultType, postMessage, postDamageMessage} from '../../../services/dieRollService';
import SaveIcon from '../../../icons/white-save-icon.svg';
import PrintIcon from '@mui/icons-material/Print';
import EditButton from '../../Buttons/editButton';
import TablesButton from '../../Buttons/tablesButton';
import DicePoolButton from '../../DicePool/dicePoolButton';
import ExperienceButton from '../../Experience/experienceButton';
import EnchantmentsButton from '../../Enchantments/enchantmentsButton';
import ResetButton from '../buttons/resetButton';
import InventoryButton from '../../Inventory/inventoryButton'
import NotesButton from '../../Buttons/notesButton';
import BackgroundButton from '../buttons/backgroundButton';
import DramatisPersonaeButton from '../../Buttons/dramatisPersonaeButton';
import ConditionPanel from '../../Panels/conditionPanel';
import AugmentationPanel from '../../Panels/augmentationPanel';
import HitLocationPanel from '../hitLocationPanel';
import StatRow from '../tiles/statTile';
import AbilityRow from '../../Abilities/characterAbilityRow';
import SpiritSpellRow from '../tiles/spiritSpellRow';
import Characteristics from '../../Characteristics/characteristics';
import Reputation from '../reputation';
import Messages from '../../Messages/Messages';
import Skills from './skillsPanel';
import Passions from './passionsPanel';
import Runes from './runesPanel';
import Weapons from './weapons';
import WeaponRow from '../tiles/weaponRow';
import SpiritCombat from './spiritCombatPanel';
import Magic from './magic';
import AbilityTileContextMenu from '../../Abilities/abilityTileContextMenu';
import WeaponTileContextMenu from '../../Combat/weaponTileContextMenu';
import CharacteristicTileContextMenu from '../../Characteristics/characteristicTileContextMenu';
import SpiritSpellTileContextMenu from '../../Magic/spiritSpellTileContextMenu';
import RuneSpellTileContextMenu from '../../Magic/runeSpellTileContextMenu';
import BoundSpiritContextMenu from '../../MagicItems/boundSpiritContextMenu';
import SpiritCombatTileContextMenu from '../../Combat/spiritCombatTileContextMenu';
import AttributeBar from './attributeBar';
import InventoryContextPanel from '../../Inventory/inventoryContextPanel';
import DicePoolContextPanel from '../../DicePool/dicePoolContextPanel';
import EditBox from '../../Controls/editBoxSingle';
import ProfileImage from '../controls/profileImage';
import { AuthUserContext } from '../../Session';
import CampaignButton from '../buttons/campaignButton';
import { calculateCurrentEncumbrance } from '../../../services/attributeService';
import EnchantmentsContextPanel from '../../Enchantments/enchantmentsContextPanel';
import ExperienceContextPanel from '../../Experience/experienceContextPanel';
import DetailsPanel from '../../Controls/detailsPanel';
import CancelButton from '../../Buttons/cancelButton';
import ResultWindow from '../controls/resultWindow';

const blankDieRoll = {name: "", result: 0, target: 0, show: false, resultType: "", title:"" };
const blankDamageRoll = {result: 0, diceRolled: "", show: false, location: 0 };

const Character = (props) => {

    const params = useParams()

    const [uid, setUid] = useState();
    const [character, setCharacter] = useState({});
    const [campaign, setCampaign] = useState();
    const [campaignCharacters, setCampaignCharacters] = useState({});
    const [authUser, setAuthUser] = useState(null);
    const [augmenting, setAugmenting] = useState({state: "", value: 0, type: "", label: ""});
    const [timeout, setTimeout] = useState(-1440);
    const [cache, setCache] = useState(null);
    const [show, setShow] = useState("abilities & combat");
    const [panelItem, setPanelItem] = useState({type: "ability", item: { name: "Battle", value: 60, variant: "", cult: null}})
    const [panelVisibility, setPanelVisibility] = useState("hidePanel");
    const [panelPosition, setPanelPosition] = useState(0);
    const [panelHeight, setPanelHeight] = useState(800);
    const [panelMargin, setPanelMargin] = useState(-400)
    // const [resultRight, setResultRight] = useState(0);
    const [activeEncounterId, setActiveEncounterId] = useState("");
    const [enemies, setEnemies] = useState([]);
    const [result, setResult] = useState(null);
    const [rolls, setRolls] = useState(null);
    const [resultClass, setResultClass] = useState("moveFromBottomFade");
    const [currentEncumbrance, setCurrentEncumbrance] = useState(0);
    const [imported, setImported] = useState(false);

    useEffect(() => {
        var width = window.innerWidth;
        if (width > 1928) {
            var result = (((window.innerWidth -1200)/2))
            setPanelPosition(result);
        }
        else {
            setPanelPosition(364);
        }
        if (window.innerHeight < 850) {
            setPanelHeight(window.innerHeight - 50)
            setPanelMargin((window.innerHeight * -0.5) + 50)
        }
        // if (width > 1804) {
        //     var result = (((window.innerWidth -1200)/2) - 300)
        //     setResultRight(result);
        // }
        // else {
        //     setResultRight(0);
        // }
    },[window.innerWidth])

    useEffect(() => {
        var uid = params.uid;
        setUid(uid);
    }, [params]);

    useEffect(() => {
        setAuthUser({...props.authUser});
    }, [props.authUser]);

    useEffect(() => {
        setUid(uid);
        var unsubscribe = 
        props.firebase
        .character(uid)
        .onSnapshot((doc) => {
            if (doc.exists) {
                var character = {...doc.data()};
                character.id = doc.id;
                setCharacter(character);
                setImported(character.import);
                document.title = "RuneQuesting: " + character.name.first + (character.name.last ? " " + character.name.last : "");
                setCurrentEncumbrance(calculateCurrentEncumbrance(character));

            }
        });
        return () => {
            unsubscribe();
        }
    }, [uid]);

    useEffect(() => {
        var characters = [];
        const fetchData = async () => {
            props.firebase
            .characters()
            .where('campaignId', '==', character.campaignId)
            .orderBy('name')
            .get()
            .then(function(collection) {
                collection.forEach(function(doc) {
                    if (! doc.data().deceased && doc.id != character.id) {
                        var char = {...doc.data()};
                        char.id = doc.id;
                        characters.push(char);
                    }
                });
                setCampaignCharacters(characters);
            });
        };

        if (character.campaignId) {
            fetchData();
            props.firebase
            .campaign(character.campaignId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    var campaign = {...doc.data(), id: doc.id}
                    setCampaign(campaign)
                    setActiveEncounterId(campaign.currentEncounterId);
                    loadNPCs(campaign.currentEncounterId);

                }
            })
        }
        else {
            setCampaignCharacters([]);
        }
    }, [character.campaignId]);

    function loadNPCs(encounterId) {
        var npcs = [];
        props.firebase
        .npcs()
        .where("encounterId", "==", encounterId)
        .get()
        .then(docs => {
            docs.forEach(doc => {
                npcs.push({...doc.data(), id: doc.id})
            })
            setEnemies(npcs);
        });
    }
    
    function updateFields(updates, persist, noCache) {
        var updatedCharacter = {...character};
        updates.forEach(update => {
            updatedCharacter[update.fieldName] = update.value;
        })
        if (!noCache) {
            setCharacter({...updatedCharacter});
        }
        if (persist === SAVE.DELAYED) {
            delayedSave(updates)
        }
        else if (persist === SAVE.YES){
            var saveObjects = {}
            updates.forEach(update => {
                saveObjects[update.fieldName] = update.value;
            })
            saveUpdatedFields(saveObjects);
        }
    }
 
    function delayedSave(updates) {
        var updatedCache = {...cache};
        updates.forEach(update => {
            updatedCache[update.fieldName] = update.value;
        })
        setCache(updatedCache);

        window.clearTimeout(timeout)
        var interval = window.setTimeout(function() {
            saveUpdatedFields(updatedCache);
        }, 1000);
        setTimeout(interval);
    }

    function saveUpdatedFields(updatedFields) {
        updatedFields.modified = new Date();
        // console.log('saving updated field: ', updatedFields)
        props.firebase
        .character(uid)
        .update(updatedFields)
        .then( function() {
            setCache({});
        });
    }

    function updateImage(image) {
        var updatedCharacter = {...character}
        updatedCharacter.profileImage = image;
        setCharacter(updatedCharacter);
    }

    function updateName(name, value) {
        var updatedCharacter = {...character}
        updatedCharacter.name[name] = value;
        setCharacter(updatedCharacter);
    }

    function updateAge(value) {
        var updatedCharacter = {...character}
        updatedCharacter.personalInfo.age = value;
        setCharacter(updatedCharacter);
    }
    
    function updateCharacter(updatedCharacter, persist) {
        setCharacter({...updatedCharacter});
        if (persist) {
            saveCharacter(updatedCharacter);
        }
    }

    function saveCharacter(updatedCharacter) {
        updatedCharacter.modified = new Date();
        // console.log('saving character: ', updatedCharacter);
        props.firebase
        .character(uid)
        .set(updatedCharacter)
        .then( function() {
        });
    }
   
    const saveChanges = () => { 
        var updatedCharacter = {...character};
        saveCharacter(updatedCharacter);
        changeMode();
    }
    
    const cancelChanges = () => { 
        changeMode();
        props.firebase
        .character(uid)
        .get()
        .then((doc) => {
            if (doc.exists) {
                var character = doc.data();
                setCharacter(character);    
            }
        });
    }

    function changeMode() {
        var mode = props.pageState.editMode;
        props.pageState.setEditMode(!mode);
    }

    function editMode() {
        props.pageState.setEditMode(true);
    }

    const postExperienceMessage = (gain, message) => {
        var result = gain > 0 ? "Success" : "Failure";
        if (character.campaignId) {
            props.firebase.messages().add({
                text: message,
                result:result,
                userId: authUser.uid,
                firstName: authUser.firstName,
                characterName: character.name.first,
                campaignId: character.campaignId,
                createdAt: props.firebase.fieldValue.serverTimestamp(),
            });
        }
    }

    const displayPanelItem = (displayDetails) => {
        console.log('displayDetails', displayDetails)
        if (displayDetails.type === "Weapon" || displayDetails.type === "Spirit Combat") {
            if (panelItem.item.weapon && panelItem.item.weapon.id === displayDetails.item.weapon.id) {
                closePanel();
            }
            else {
                showPanel(displayDetails)
            }
        }
        else {
            if (panelItem.item && panelItem.item.id && displayDetails.item && panelItem.item.id === displayDetails.item.id) {
                closePanel();
            }
            else {
                showPanel(displayDetails)
            }
        }
    }

    const showPanel = (details) => {
        setPanelItem(details)
        setPanelVisibility("showPanel")
}

    const closePanel = () => {
        setPanelVisibility("hidePanel");
        setPanelItem({type: "", item: {name: ""}})
    }

    function getCults() {
        var cults = "";
        character.cults.forEach((cult, index) => {
            cults += cult.rank + " of " + cult.name;
            cults += index < character.cults.length -1 ? ", " : ""; 
        });
        return cults;
    }
    
    function processResult(dieRoll, action, title){
        postMessage(authUser, dieRoll, props.firebase, character, action)
        var updateRequired = false;
        var updatedCharacter = {...character}

        var augment = {...augmenting};
        if (augment.state === "next" && augment.modifier !== 0 && action) {
            var index = updatedCharacter.enchantments.findIndex(enchantment => (enchantment.name === "Inspiration" && enchantment.target === action.name ));
            if (index < 0) {
                if (action.type === "weaponSkills") {
                    updatedCharacter.weapons.forEach(weapon => {
                        if (weapon.id === action.id) {
                            var enchantment = {name: "Inspiration", points: 0, spell: "Augmentation", effects: [], target: action.name}
                            enchantment.effects.push({item: {id: action.id}, name: "attackModifier", value: augment.modifier});
                            enchantment.effects.push({item: {id: action.id}, name: "parryModifier", value: augment.modifier});
                            updatedCharacter.enchantments.push(enchantment)
                            updateRequired = true;
                        }
                    })
                }

                else if (action.name === "Spirit Combat" || augment.type == "passions" || augment.type === "runes") {
                    if (augment.modifier !== 0) {
                        updatedCharacter.skills.forEach(skill => {
                            if (skill.id === action.id) {
                                var enchantment = {name: "Inspiration", points: 0, spell: "Augmentation", effects: [], target: action.name}
                                enchantment.effects.push({item: {id: action.id}, name: "skillModifier", value: augment.modifier});
                                updatedCharacter.enchantments.push(enchantment)
                                updateRequired = true;
                            }
                        })
                    }
                }
            }
        }

        var resultType = getResultType(dieRoll.target, dieRoll.result, action.type);
        setResult({dieRoll: dieRoll, resultType: resultType, title: title, action: action, type: action.type ? action.type === "weaponSkills" ? "" : action.type.substring(0, action.type.length - 1): ""});
        setRolls(null)
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")

        if (dieRoll.hidden) {
            resultType.style = {border: "4px solid #676767", backgroundColor: "#fff", color: "#121312", borderRadius: "5px", height: "246px"};
        }

        if (dieRoll.augmentRoll){
            if (action.type === "runes"  && resultType.name === "Failure") {
                updatedCharacter.runes.forEach(ability => {
                    if (ability.id === action.id) {
                        var enchantment = {name: "Runic Failure", points: 0, spell: "Augmentation", effects: [], target: action.name}
                        enchantment.effects.push({item: {id: action.id}, name: "runeModifier", value: -20});
                        updatedCharacter.enchantments.push(enchantment)
                        updateRequired = true;
                    }
                })
            }
            else if ((action.type === "runes" || action.type === "passions") && resultType.name === "Fumble") {
                updatedCharacter[action.type].forEach(ability => {
                    if (ability.id === action.id) {
                        var modifier = Math.ceil(Math.random() * 10);
                        resultType.augmentationLabel = resultType.augmentationLabel.replace("[reduction]", modifier)
                        ability.value -= modifier
                        var updatedPanelItem = {...panelItem};
                        updatedPanelItem.item.value -= modifier;
                        setPanelItem(updatedPanelItem)
                        updateRequired = true;
                    }
                })
            }
            else if (action.type === "passions"  && resultType.name === "Failure") {
                var enchantment = {name: "Despondency", points: 0, spell: "Augmentation", effects: [], target: "Character"}
                updatedCharacter.enchantments.push(enchantment)
                updatedCharacter.despondent = true;
                updateRequired = true;
            }            
            augment.state = "next";
            augment.modifier = resultType.modifier;
            augment.type = action.type
            augment.label = resultType.augmentationLabel
            setAugmenting(augment);
        }
        else {
            if (augment.state === "next") {
                setAugmenting({state: "", modifier: 0, type: "", label: ""});
            }
        }
        if (action.type && resultType.isSuccess && character.uid === authUser.uid) {
            var abilities = [];
            switch (action.type) {
                case "runes":
                    abilities = character.runes;
                    break;
                case "skills":
                    abilities = character.skills;
                    break;
                case "passions":
                    abilities = character.passions;
                    break;
                case "weaponSkills":
                    abilities = character.weaponSkills;
                    break;
            }
            if (action.type === "weaponSkills"){
                abilities.forEach((ability) => {
                    if (ability.parentId === action.weaponSkillId || ability.parentId === action.parentId) {
                        if (!ability.hasSucceeded) {
                            ability.hasSucceeded = true;
                            updateRequired = true;
                        }
                    }
                });
            }
            else {
                abilities.forEach((ability) => {
                    if (ability.id === action.id) {
                        if (action.type === "skills"){
                            if (action.canLearn) {
                                if (!ability.hasSucceeded) {
                                    ability.hasSucceeded = true;
                                    updateRequired = true;
                                }
                            }
                        }
                        else {
                            if (!ability.hasSucceeded) {
                                ability.hasSucceeded = true;
                                updateRequired = true;
                            }
                        }
                    }
                });
            }
        }
        if (updateRequired) {
            updateCharacter(updatedCharacter, true)
        }
    }

    function processDamage(result){
        var damage = {result: result.result, rolls: result.rolls, show: true, location: result.location};
        postDamageMessage(damage, props.authUser, props.firebase, character, result.weapon.title === "Spirit Combat")
        setResult(null);
        setRolls({result: result.result, rolls: result.rolls.reverse(), doubled: result.doubled, title: result.title, modifier: result.modifier});
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")
    }

    function displayTab(name, imageName) {
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width: "300px", cursor: "pointer", padding: "2px"}} onClick={() => setShow(name.toLowerCase())}>
                <div style={{borderBottom: "2px solid transparent", display: "flex"}}>
                    <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-40x40.png"} style={{width:"30px"}} /></div>
                    <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                </div>
            </div>
        )
    }

    function displaySelectedTab(name, imageName) {
        return (
            <div style={{backgroundColor: "transparent", color: "#121312", display:"flex", justifyContent:"center", alignItems:"center", width: "300px", padding: "2px"}}>
                    <div style={{borderBottom: "2px solid #2F588A", display: "flex"}}>
                        <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-40x40.png"} style={{width:"30px"}} /></div>
                        <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                    </div>
            </div>
        )
    }

    function removeImport() {
        setImported(false);
        var updatedCharacter = {...character}
        updatedCharacter.import = false;
        saveCharacter(updatedCharacter);
    }

    function displayResult(result, diceRolled, modifier) {
        var resultType = getResultType(-1, result);
        var title = "";
        var name = "";
        var rolls = []
        if (diceRolled.d100) {
            title = "Rolled D100";
            name = "Rolled " + result;
        }
        else if (diceRolled.d20) {
            title = "Rolled D20";
            name = "Rolled " + result;
        }
        else {
            for (var prop in diceRolled) {
                if (diceRolled.hasOwnProperty(prop)) {
                    if (prop.substring(0,3) === "d10"){
                        title += "D10+";
                    }
                    else {
                        title += prop.substring(0,2) + "+";
                    }
                    name += diceRolled[prop] + "+"
                    rolls.push({type: prop, roll: diceRolled[prop]});
                }
            }
        }
        var dieRoll = {target: -1, result: result}
        if (title.endsWith("+")){
            title = title.substring(0, title.length -1);
        }
        name = name.substring(0, name.length -1);
        if (modifier) {name += (modifier > 0 ? "+" : "") + modifier}
        resultType.name = name;

        setResult({dieRoll: dieRoll, resultType: resultType, title: title, action: {}, type: ""});
        setRolls({result: result.result, rolls: rolls.reverse(), doubled: result.doubled, title: result.title, modifier: result.modifier});
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")

    }

    // if (character.name) {
        var type = "character";
        // var encounters = props.activeEncounters.getEncounters();
        var encounters = [];
        if (activeEncounterId) {

        }

        var citizen = false;
        if (character && character.background) {
            citizen = ["Esrolia", "Lunar Tarsh"].indexOf(character.background.homeland) > -1
        }
        return (
            <div id="character" style={{width: "100%", height: "100%"}}>
                {character && character.name ? 
                    <AuthUserContext.Consumer>
                    {authUser =>
                        <CharacterContext.Provider value={{augmenting, setAugmenting, character, campaign, alliedCharacters: campaignCharacters, activeEnemies: enemies, type, currentEncumbrance: currentEncumbrance}}>
                            {props.pageState.editMode ? 
                                <div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"200px", left:"0px", zIndex: "999"}}>
                                    <div className={globalStyles.floatingButton} onClick={saveChanges} style={{width:"50px", cursor:"pointer", height:"50px", borderRadius:"25px", backgroundColor:"#2F7589", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                                        <img style={{width:"30px"}} src={SaveIcon} title="Save changes" />
                                    </div>
                                    <EnchantmentsButton character={character} user={authUser} update={(updateFields)} />
                                    <InventoryButton character={character} displayPanel={() => displayPanelItem({type: "Inventory"})} currentEncumbrance={currentEncumbrance} />
                                    <BackgroundButton character={character} user={authUser} update={updateCharacter} />
                                    <CancelButton cancelChanges={cancelChanges} type="character" />
                                    {/* <div className={globalStyles.floatingButton} onClick={cancelChanges} style={{width:"50px", cursor: "pointer", height:"50px", borderRadius:"25px", backgroundColor:"#851113", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                                        <img style={{width:"40px"}} src={CancelIcon} title="Cancel changes" />
                                    </div> */}
                                </div>
                            :                    

                                <div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"60px", left:"0px", zIndex: "999"}}>
                                    {character.uid === authUser.uid ? 
                                    <>
                                    <EnchantmentsButton character={character} user={authUser} displayPanel={() => displayPanelItem({type: "Enchantments", item: {}})} update={updateFields} />
                                    <InventoryButton character={character} displayPanel={() => displayPanelItem({type: "Inventory", item: {}})} currentEncumbrance={currentEncumbrance} />
                                    <DicePoolButton diceRoller={props.diceRoller} authUser={authUser} name={character.name} displayPanel={() => displayPanelItem({type: "DicePool"})} />
                                    <TablesButton />
                                    <ExperienceButton displayPanel={() => displayPanelItem({type: "Experience", item: {}})} />
                                    <ResetButton character={character} user={authUser} update={updateCharacter} />
                                    <BackgroundButton character={character} user={authUser} update={updateCharacter} />
                                    <NotesButton character={character} user={authUser} update={updateCharacter} campaignId={character.campaignId} /> 
                                    </>
                                    : null
                                    }
                                    <a href={"/charactersheet/" + uid}>
                                        <div title="Print character sheet" style={{width:"50px", height:"50px", borderRadius:"30px", border: "2px solid #2f588a", cursor: "pointer", backgroundColor:"#fff", display:"flex", justifyContent:"center", alignItems:"center", marginTop: "10px"}} >
                                            <PrintIcon style={{height:"30px", width:"30px", color: "#2f588a"}}/>
                                        </div>
                                    </a>
                                    {/* <DramatisPersonaeButton character={character} user={authUser} update={updateCharacter} /> */}
                                    {character.campaignId && (character.campaignId !== character.id) ? 
                                        <CampaignButton campaignId = {character.campaignId} />
                                    : 
                                    null}
                                    {character.uid === authUser.uid ? 
                                    <>
                                        <EditButton character={character} user={authUser} update={updateCharacter} onClick={editMode} imported={imported} close={removeImport} />
                                    </>
                                    : null
                                    }

                                </div>
                            }
                            <div style={{display: "flex", backgroundColor: "#8a612f", justifyContent: "center", alignItems: "center", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", position: "relative"}}>
                                <div style={{width: "1200px", position: "relative", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", flexDirection: "column", marginTop: "50px", minHeight: "calc(100vh - 50px", height: "100%", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
                                    {/* <div style={{position: "absolute", opacity: 0.2, left: "200px", top: "100px", zIndex: 29}}><img src="https://storage.googleapis.com/runequest/runes/moon-rune-600x600.png" style={{width: "800px", height: "800px"}} /></div> */}
                                    <div style={{display:"flex", height: "312px", position: "relative", zIndex: 199}}>
                                        <div style={{width: "866px"}}>

                                            <div style={{display:"flex", margin: "5px"}}>
                                                <ProfileImage profileImage={character.profileImage} gender={character.personalInfo.gender} updateImage={updateImage} />
                                                <div style={{height: "240px", padding: "2px"}}>
                                                    <div style={{padding:"10px", position: "relative", width: "395px", height: "240px", fontSize: "15px", overflow: "hidden"}}>
                                                        {props.pageState.editMode ? 
                                                            <div style={{marginBottom: "10px", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                                                <EditBox value={character.name.first} onChange={(value) => updateName("first", value)} />
                                                                <EditBox value={character.name.last} onChange={(value) => updateName("last", value)} />
                                                            </div>
                                                            : 
                                                            <div style={{marginBottom: "10px", fontSize: "30px"}}>{character.name.first} {character.name.last}</div>
                                                        }
                                                        {/* <ConditionPanel /> */}
                                                        { character.personalInfo.gender === "Male" || character.personalInfo.gender === "Female" ?  
                                                            <div style={{marginBottom: "10px", display: "flex", alignItems: "center"}}>
                                                                <div>{character.personalInfo.gender}&nbsp;{character.occupation.name.toLowerCase()}</div>
                                                                {props.pageState.editMode ? 
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div>, age </div>
                                                                        <div style={{width: "50px", paddingLeft: "5px"}}>
                                                                            <EditBox number size="sm" value={character.personalInfo.age} onChange={(value) => updateAge(value)} />
                                                                        </div>
                                                                    </div>
                                                                : 
                                                                    <div>, age {character.personalInfo.age}</div>
                                                                }
                                                            </div>
                                                        : 
                                                            <div style={{marginBottom: "10px"}}>{character.occupation.name}, age {character.personalInfo.age}</div>
                                                        }
                                                        { character.background.clan ? 
                                                            citizen ? 
                                                            <div style={{marginBottom: "10px"}}>Member of the {character.background.clan} clan from the city of {character.background.tribe} in {character.background.homeland}</div>
                                                            : 
                                                            <div style={{marginBottom: "10px"}}>Member of the {character.background.clan} clan of the {character.background.tribe} tribe in {character.background.homeland}</div>
                                                        : 
                                                        character.background.tribe ? 
                                                            citizen ? 
                                                            <div style={{marginBottom: "10px"}}>Citizen of the city of {character.background.tribe} in {character.background.homeland}</div>
                                                            : 
                                                            <div style={{marginBottom: "10px"}}>Member of the {character.background.tribe} tribe in {character.background.homeland}</div>
                                                        :
                                                            <div style={{marginBottom: "10px"}}>Born in {character.background.homeland}</div>
                                                        }
                                                        <div style={{marginBottom: "10px"}}>{getCults()}</div>
                                                        <div className={globalStyles.panel} style={{height:"108px", overflow: "auto"}}>
                                                            <i>
                                                                {character.background.reputation && character.background.reputation.slice(-4).map((characterEvent, index) => {
                                                                    return (
                                                                        <div key={index} style={{display:"flex",marginBottom: "10px"}}>
                                                                            {/* <div key={index} style={{width: "100px"}}>+{characterEvent.value}%</div> */}
                                                                            <div>{characterEvent.event}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                        augmenting.state === "next" ? 
                                                        <AugmentationPanel augmentation={augmenting} />
                                                        : 
                                                        <HitLocationPanel update={updateFields} />
                                                }
                                            </div>
                                        <div id="characteristics" style={{marginLeft: "5px", width: "1190px", borderRadius: "5px", display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                            <Characteristics diceRoller={props.diceRoller} tile={<StatRow />} displayItem={displayPanelItem} processResult={processResult} update={updateFields} entity={{...character}}></Characteristics>
                                            <Reputation diceRoller={props.diceRoller} processResult={processResult} update={updateCharacter} entity={character} />

                                        </div>
                                        </div>
                                        <div style={{display:"flex", flexDirection: "row-reverse", margin: "5px 0px", height: "254px"}}>
                                            <div style={{width: "328px", backgroundColor: "#cdcdcd", marginLeft: "5px", borderRadius: "5px"}}>
                                                <Messages campaignId={character.campaignId} isGM={false}></Messages>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{position: "relative", zIndex: 99}}>
                                        <AttributeBar update={updateFields} />
                                        {
                                            show === "abilities & combat" ? 
                                                <div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px", borderRadius: "5px"}}>
                                                    {displaySelectedTab("Abilities & Combat", "air")}
                                                    {displayTab("Magic & Magic Items", "magic")}
                                                </div>
                                            : 
                                            show === "magic & magic items" || show === "spirit magic" || show === "magic items" ? 
                                                <div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px"}}>
                                                    {displayTab("Abilities & Combat", "air")}
                                                    {displaySelectedTab("Magic & Magic Items", "magic")}
                                                </div>
                                            : 
                                            null
                                        }
                                        {
                                            show === "abilities & combat" ? 
                                            <div className={globalStyles.moveFromBottomFade} style={{display: "flex", padding: "0px 5px"}}>
                                                <div style={{marginRight: "5px", width: "710px"}}>
                                                    <Skills diceRoller={props.diceRoller} style={{maxHeight: "612px", overflow: "auto", display:"flex", padding: "5px"}} columnCount={3} processResult={processResult} entity={character} update={updateFields} displayItem={displayPanelItem} tile={<AbilityRow type="skill" />} />
                                                    <Passions diceRoller={props.diceRoller} style={{height: "100px", overflow: "auto", display:"flex", padding: "5px"}} processResult={processResult} update={updateFields} entity={character} columnCount={3} displayItem={displayPanelItem} tile={<AbilityRow />} />
                                                </div>

                                                <div>
                                                    <Runes diceRoller={props.diceRoller} style={{height: "246px", overflow: "auto", display:"flex", padding: "5px"}} processResult={processResult} update={updateFields} entity={character} columnCount={2} displayItem={displayPanelItem} tile={<AbilityRow />} />
                                                    <Weapons diceRoller={props.diceRoller} style={{fontSize: "15px", display: "flex", margin: "2px", flexWrap:"wrap", maxHeight: "210px", overflow: "auto"}} tile={<WeaponRow />} processResult={processResult} displayItem={displayPanelItem} processDamage={processDamage} update={updateFields} entity={character} />
                                                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
                                                        <div id="header" style={{background: "rgb(133,17,19)", background: "linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)", marginBottom: "10px", padding: "10px", paddingLeft: "15px", fontSize: "18px", color: "#fff"}}>Spirit Combat</div>
                                                        <div style={{height: "100px"}}>
                                                            <SpiritCombat diceRoller={props.diceRoller} displayItem={displayPanelItem} processResult={processResult} processDamage={processDamage} update={updateFields} entity={character} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            show === "magic & magic items" ? 
                                                <div className={globalStyles.moveFromBottomFade2}>
                                                    <Magic diceRoller={props.diceRoller} character={character} stylesheet={styles} processResult={processResult} update={updateFields} displayItem={displayPanelItem} />
                                                </div>
                                            :
                                            <div style={{display: "flex", padding: "5px"}}/>
                                        }

                                    </div>
                                </div>
                            </div>

                            {result || rolls  ? 
                                <ResultWindow result = {result} rolls={rolls} />
                            : 
                            null}

                            <div className={globalStyles.contextBar} style={{right: panelPosition, top: "50vh"}}>
                                <div id="contextPanel" className={globalStyles[panelVisibility]} style={{width: "360px", borderRadius: "5px", border: "2px solid #2f588a", fontSize: "15px", height: panelHeight + "px", left: "auto", marginTop: panelMargin + "px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-panel-background.png)"}}>
                                    {panelItem && panelItem.type === "Skill" || panelItem && panelItem.type === "Rune" || panelItem && panelItem.type === "Passion" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <AbilityTileContextMenu currentEncumbrance={currentEncumbrance} character={character} authUser={authUser} diceRoller={props.diceRoller} augmenting={augmenting} processResult={processResult} entity={panelItem.entity || character} update={updateFields} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Weapon" ?
                                        <>
                                            <DetailsPanel red panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <WeaponTileContextMenu diceRoller={props.diceRoller} processResult={processResult} processDamage={processDamage} entity={panelItem.entity || character} update={updateFields} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Spirit Combat" ?
                                        <>
                                            <DetailsPanel red panelItem={{item: {...panelItem.item, name: "Spirit Combat"}, type: "skill"}} close={closePanel} panelHeight={panelHeight}>
                                                <SpiritCombatTileContextMenu diceRoller={props.diceRoller} processResult={processResult} processDamage={processDamage} entity={panelItem.entity || character} update={updateFields} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Stat" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <CharacteristicTileContextMenu diceRoller={props.diceRoller} panelItem={panelItem} processResult={processResult} update={updateFields} entity={panelItem.entity || character} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Spirit Spell" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <SpiritSpellTileContextMenu diceRoller={props.diceRoller}  element={<SpiritSpellRow />} spellcaster={panelItem.entity || character} processDamage={processDamage} character={character} panelItem={panelItem} processResult={processResult} update={updateFields} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Rune Spell" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <RuneSpellTileContextMenu diceRoller={props.diceRoller} spellcaster={panelItem.entity || character} character={character} panelItem={panelItem} processDamage={processDamage} processResult={processResult} update={updateFields} entity={panelItem.entity || character} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Bound Spirit" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
                                                <BoundSpiritContextMenu diceRoller={props.diceRoller} spellcaster={panelItem.entity || character} panelItem={panelItem} character={character} processResult={processResult} processDamage={processDamage} update={updateFields} displayItem={displayPanelItem} entity={panelItem.entity || character} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Inventory" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Inventory"}, type: "inventory"}} close={closePanel} panelHeight={panelHeight}>
                                                <InventoryContextPanel entity={character} update={updateFields} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "DicePool" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Dice Pool"}, type: "dicepool"}} close={closePanel} panelHeight={panelHeight}>
                                                <DicePoolContextPanel displayResult={displayResult} diceRoller={props.diceRoller} firebase={props.firebase} authData={authUser} campaignId={character.campaignId} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Enchantments" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Enchantments & Augments"}, type: "enchantments"}} close={closePanel} panelHeight={panelHeight}>
                                                <EnchantmentsContextPanel character={character} update={(updateFields)} close={closePanel} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Experience" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Experience"}, type: "experience"}} close={closePanel} panelHeight={panelHeight}>
                                                <ExperienceContextPanel  diceRoller={props.diceRoller} character={character} displayMessage={postExperienceMessage} user={authUser} update={updateFields} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    null
                                    }
                                </div>
                            </div>
                        </CharacterContext.Provider>
                    }
                    </AuthUserContext.Consumer>
                  : null}
            </div>
        )
}

export default withFirebase(withPageState(Character))