import React, { useEffect, useState } from 'react';
import { withPageState } from '../Context/PageState';
import EnemySpell from './spells/enemySpell';
import CharacterSpell from './spells/characterSpell';
import WeaponSpell from './spells/weaponSpell';
import RuneSpellTile from './runeSpellTile';
import RuneSpellMatrixTile from '../MagicItems/runeSpellMatrixTile';
import { getRuneSpell } from '../../services/dataService';
import { withContext } from '../Context/context';

const RuneSpell = (props) => {

    const [item, setItem] = useState(props.spell);

    useEffect(() => {
        let updatedItem = getRuneSpell(props.spell.parentId, props.providerData.campaign)
        setItem({...updatedItem, parentId: props.spell.parentId, id: props.spell.id, default: props.spell.default})
    },[props.spell]);

    // if (item.target && item.target.type === "enemy") {
    //     return (
    //         <EnemySpell diceRoller={props.diceRoller} item={item} processResult={props.processResult} update={props.update} displayItem={props.displayItem} spellcaster={props.spellcaster} character={props.character} powGain={props.powGain}>
    //             {props.matrix ? 
    //                 <RuneSpellMatrixTile matrix={props.matrix} element={props.element} update={props.update} />
    //                 : 
    //                 <RuneSpellTile cult={props.cult} totalPoints={props.totalPoints} element={props.element} />
    //             }
    //        </EnemySpell>
    //     )
    // }
    if (item.target && item.target.type === "Character") {
        return (
            <CharacterSpell diceRoller={props.diceRoller} item={item} update={props.update} processResult={props.processResult} displayItem={props.displayItem} spellcaster={props.spellcaster} character={props.character}>
            {props.matrix ? 
                <RuneSpellMatrixTile matrix={props.matrix} element={props.element} update={props.update} />
                : 
                <RuneSpellTile cult={props.cult} totalPoints={props.totalPoints} element={props.element} />
            }
            </CharacterSpell>
        )
    }
    else if (item.target && item.target.type === "Weapon") {
        return (
            <WeaponSpell diceRoller={props.diceRoller} item={item} update={props.update} processResult={props.processResult} displayItem={props.displayItem} spellcaster={props.spellcaster} character={props.character}>
            {props.matrix ? 
                <RuneSpellMatrixTile matrix={props.matrix} element={props.element} update={props.update} />
                : 
                <RuneSpellTile cult={props.cult} totalPoints={props.totalPoints} element={props.element} />
            }
            </WeaponSpell>
        )
    }
    else {
        if (props.matrix) {
            return (
                <RuneSpellMatrixTile diceRoller={props.diceRoller} item={item} element={props.element} matrix={props.matrix} processResult={props.processResult} update={props.update} spellcaster={props.spellcaster} displayItem={props.displayItem} character={props.character} />
            )            
        }
        else {
            return (
                <RuneSpellTile diceRoller={props.diceRoller} spellcaster={props.spellcaster} element={props.element} item={item} cult={props.cult} totalPoints={props.totalPoints} processResult={props.processResult} update={props.update} displayItem={props.displayItem} character={props.character} />
            )            
        }
    }
}

export default withPageState(withContext(RuneSpell));