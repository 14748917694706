import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import SpiritMagicEntity from './spiritMagicEntity';
import { withContext } from '../Context/context';
import { getAvailableMagicPoints } from '../../services/magicPointsService';
import PanelHeader from '../Controls/panelHeader';
import DialogBox from '../Dialogs/dialogBox';
import MatrixDialog from '../MagicItems/matrixDialog';
import SpiritMagicMatrix from '../MagicItems/spiritMagicMatrix';
import * as SAVE from '../../constants/save';

const SpiritMagic = (props) => {

    const [availableMagicPoints, setAvailableMagicPoints] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        var points = getAvailableMagicPoints(props.providerData.character);
        setAvailableMagicPoints(points.current);
    },[props.providerData.character.magicPoints]);

    function displayItem(details) {
        props.displayItem({...details, providerData: props.providerData})
    }

    function powGain(gained) {
        if (gained) {
            props.update([{fieldName: "powGain", value: true}], SAVE.DELAYED);
        }
    }

    function updateMatrix(matrix) {
        var updatedMatrices = [...props.providerData.character.matrices];
        var index = updatedMatrices.findIndex(item => item.id === matrix.id);
        updatedMatrices[index] = matrix;
        props.update([{fieldName: "matrices", value: updatedMatrices}], SAVE.DELAYED);
    }

    return(
        <>
            {props.providerData.type === "character" ? 
                <PanelHeader title="Spirit Magic" />
            : 
            null
            }

            <div className={globalStyles.panel} style={{maxHeight:props.entityHeight, overflow: "auto"}}>
                <SpiritMagicEntity diceRoller={props.diceRoller} element={props.element} stylesheet={props.stylesheet} availableMagicPoints={availableMagicPoints} entity={props.providerData.character} spellcaster={props.providerData.character} processResult={props.processResult} update={props.update} powGain={powGain} displayItem={displayItem} />
                {props.pageState.editMode ? 
                null
                :
                    props.providerData.character.matrices.length > 0 ? 
                        <>
                                {props.providerData.character.matrices?.map((matrix, index) => {
                                    if (matrix.type === "Spirit spell matrix") {
                                        return (
                                            <SpiritMagicMatrix diceRoller={props.diceRoller} element={props.element} key={index} availableMagicPoints={availableMagicPoints} stylesheet={props.stylesheet} displayItem={props.displayItem} entity={props.providerData.character} spellcaster={props.providerData.character} matrix={matrix} processResult={props.processResult} powGain={powGain} update={updateMatrix} />
                                        )
                                    }
                                })}
                        </>
                    : 
                        null
                    
                }

                <DialogBox title="Add Spirit Spell Matrix" isOpen={open} size="md" >
                    <MatrixDialog type="Spirit spell matrix" close={() => setOpen(false)} update={props.update} />
                </DialogBox>
            </div>
        </>
    );
}

export default withContext(withPageState(SpiritMagic));