import React, { useState, useEffect } from 'react';
import globalStyles from '../../Styles/styles.module.css';
import { Button } from '@mui/material';
import styles from '../builder.module.css';
import {calculateDamageBonus, calculateMagicPoints, calculateStrikeRanks, calculateMaxEncumbrance} from '../../../services/attributeService';
import {calculateHitPoints} from '../../../services/hitPointsService';
import compareValues, {CompareValues} from '../../../utils/compareValues';
 
const BuilderDialog = React.forwardRef((props, ref) => {

	const [skills, setSkills] = useState({
		agility: [],
		communication: [],
		knowledge: [],
		magic: [], 
		manipulation: [],
		perception: [],
		stealth: []
	});
	const [dmgBonus, setDmgBonus] = useState({quantity: 0, dieType: 0});

	useEffect(() => {
		// var currentSkills = JSON.parse(JSON.stringify(props.character.builder.skills));
		var updatedSkills = {...skills};
		if (props.character.builder.skills && props.character.builder.skills.length > 0) {
			props.character.builder.skills.forEach(skill => {
			switch(skill.skillCategory) {
				case "Agility": 
					updatedSkills.agility.push({...skill});
					return;
				case "Communication": 
					updatedSkills.communication.push({...skill});
					return;
				case "Knowledge": 
					updatedSkills.knowledge.push({...skill});
					return;
				case "Magic": 
					updatedSkills.magic.push({...skill});
					return;
				case "Manipulation": 
					updatedSkills.manipulation.push({...skill});
					return;
					case "Perception": 
					updatedSkills.perception.push({...skill});
					return;
				case "Stealth": 
					updatedSkills.stealth.push({...skill});
					return;
				}
			});
			setSkills(updatedSkills);
			if (props.character.builder.stats) {
				setDmgBonus(calculateDamageBonus(props.character.builder.characteristics));
			}
		}
	},[]);

	function handleCancelClick(){
		props.close();
	}
	  
	return (
		<div ref={ref} style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px", minHeight: "520px", backgroundColor:"#cdcdcd"}}>
				<div style={{display: "flex"}}>
					<div id="stats" style={{display:"flex", flexWrap: "wrap", width: "450px"}}>
						{props.character.builder.stats && props.character.builder.stats.details.map((item, index) => {
							var stat = props.character.builder.characteristics[item.name];
							return(
								<div key={index}  style={{border: "2px solid #2F588A", boxShadow: "5px 10px 8px #888888", margin: "10px", cursor: "pointer", backgroundColor: "#fff", borderRadius: "5px", fontSize: "16px", color: "#121312", display:"flex", justifyContent:"center", alignItems: "center", textAlign:"center", width: "80px", height: "40px"}}>
									<div>{stat.name}: </div>
									<div>{stat.currentValue + (stat.bonus || 0)}</div>
								</div>
							);
						})}
						{ props.character.builder.stats && dmgBonus ? 
							<div style={{border: "2px solid #676767", opaborderRadius: "10px", boxShadow: "5px 10px 8px #888888", margin: "10px", cursor: "pointer", backgroundColor: "#851113", borderRadius: "5px", fontSize: "16px", color: "#fff", display:"flex", justifyContent:"center", alignItems: "center", textAlign:"center", width: "80px", height: "40px"}}>
								<div>DB: </div>
								<div>{dmgBonus.dieType > 0 ? dmgBonus.quantity + "D" + dmgBonus.dieType : 0}</div>
							</div>
						: 
							null
						}
					</div>
					{ props.character.builder.characteristics ? 
						<div style={{display:"flex", margin: "10px", border: "2px solid #2F588A", backgroundColor: "#fff", padding: "12px 20px", width: "300px", borderRadius: "10px"}}>
							<div>
								<div style={{}}><b>Hit Points</b>: {calculateHitPoints(props.character.builder.characteristics, props.character.builder.hitPoints).total}</div>
								<div style={{marginTop: "12px"}}><b>Magic Points</b>: {calculateMagicPoints(props.character.builder.characteristics)}</div>
								<div style={{marginTop: "12px"}}><b>Max Enc</b>: {calculateMaxEncumbrance(props.character.builder.characteristics)}</div>
							</div>
							<div style={{marginLeft: "40px"}}>
								<div style={{}}><b>SIZ SR</b>: {calculateStrikeRanks(props.character.builder.characteristics).SIZ}</div>
								<div style={{marginTop: "12px"}}><b>DEX SR</b>: {calculateStrikeRanks(props.character.builder.characteristics).DEX}</div>
							</div>
						</div>
					: 
					null 
					}		
					<div style={{display:"flex", margin: "10px", border: "2px solid #2F588A", backgroundColor: "#fff", padding: "12px 20px", width: "200px", borderRadius: "10px"}}>
						<div>
							<div style={{}}><b>Homeland</b>: {props.character.builder.homeland && props.character.builder.homeland.name}</div>
							<div style={{marginTop: "12px"}}><b>Occupation</b>: {props.character.builder.occupation && props.character.builder.occupation.name}</div>
						</div>
					</div>
				</div>
			<div style={{display:"flex", border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"96%"}}>
				<div style={{width: "25%"}}>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Agility Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Agility < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Agility : 0}%</div>
						</div>
						{ 
							skills.agility.sort(compareValues('name', 'asc')).map((item, index) => {
								var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Agility : item.value;
								return(
									<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
										<div style={{marginRight: "12px"}}>{total}%</div>
									</div>
								);
							})
						}
					</div>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Manipulation Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Manipulation < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Manipulation : 0}%</div>
						</div>
						{ 
							skills.manipulation.sort(compareValues('name', 'asc')).map((item, index) => {
								var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Manipulation : item.value;
								return(
									<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
										<div style={{marginRight: "12px"}}>{total}%</div>
									</div>
								);
							})
						}
					</div>
				</div>
				<div style={{width: "25%"}}>
					<div style={{border: "2px solid #676767", margin: "5px 10px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Knowledge Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Knowledge < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Knowledge : 0}%</div>
						</div>
						{ 
							skills.knowledge.sort(compareValues('name', 'asc')).map((item, index) => {
								if (item.value) {
									var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Knowledge : item.value;
									return(
										<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
											<div style={{marginRight: "12px"}}>{total}%</div>
										</div>
									);
								}
							})
						}
					</div>
				</div>
				<div style={{width: "25%"}}>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Communication Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Communication < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Communication : 0}%</div>
						</div>
						{ 
							skills.communication.sort(compareValues('name', 'asc')).map((item, index) => {
								var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Communication : item.value;
								return(
									<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
										<div style={{marginRight: "12px"}}>{total}%</div>
									</div>
								);
							})
						}
					</div>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Stealth Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Stealth < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Stealth : 0}%</div>
						</div>
						{ 
							skills.stealth.sort(compareValues('name', 'asc')).map((item, index) => {
								var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Stealth : item.value;
								return(
									<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
										<div style={{marginRight: "12px"}}>{total}%</div>
									</div>
								);
							})
						}
					</div>
				</div>
				<div style={{width: "25%"}}>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Perception Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Perception < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Perception : 0}%</div>
						</div>
						{ 
							skills.perception.sort(compareValues('name', 'asc')).map((item, index) => {
								var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Perception : item.value;
								return(
									<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
										<div style={{marginRight: "12px"}}>{total}%</div>
									</div>
								);
							})
						}
					</div>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Magic Skills</div>
							<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Magic < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Magic : 0}%</div>
						</div>
						{ 
							skills.magic.sort(compareValues('name', 'asc')).map((item, index) => {
								if (item.value) {
									var total = props.character.builder.stats ? item.value + props.character.builder.categoryModifiers.Magic : item.value;
									return(
										<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name}{item.variant ? " (" + item.variant + ")" : ""}</div>
											<div style={{marginRight: "12px"}}>{total}%</div>
										</div>
									);
								}
							})
						}
					</div>
				</div>
			</div>
			<div className={globalStyles.pagebreak} />
			<div style={{display: "flex"}}>
				<div style={{border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"48%"}}>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
							<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
								<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Weapons</div>
								<div style={{marginLeft: "20px"}}>{props.character.builder.stats && props.character.builder.categoryModifiers.Manipulation < 0 ? "" : "+"}{props.character.builder.stats ? props.character.builder.categoryModifiers.Manipulation : 0}%</div>
							</div>
							{ 
							props.character.builder.weapons ? 
								props.character.builder.weapons.sort(compareValues('value', 'desc')).map((item, index) => {
									var value = item.value + props.character.builder.categoryModifiers.Manipulation;
									return(
										<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
											<div>{item.name}</div>
											<div style={{marginRight: "12px"}}>{value}%</div>
										</div>
									);
								})
							:
							<div style={{backgroundColor: "#BFD0CD", color: "#851113", fontSize: "20px", display: "flex", alignItems: "center", justifyContent:"center", marginTop: "2px", height: "83px"}}>None</div>
							}
						</div>
				</div>
				<div style={{border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"48%"}}>
					<div style={{backgroundColor: "#b3722a", margin: "5px", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>{props.character.builder.cult ? props.character.builder.cult.name : "Cult not yet selected"}</div>
						</div>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Rune Magic</div>
						</div>
						{ 
							props.character.builder.cult && props.character.builder.cult.selectedRuneSpells.map((item, index) => {
								return(
									<div className={styles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name} {item.variant ? item.variant : ""}</div>
										<div style={{marginRight: "12px"}}>{item.points} {item.points == 1 ? "pt" : "pts"}</div>
									</div>
								);
							})
						}
					</div>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
						<div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
							<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Spirit Magic</div>
						</div>
						{ 
							props.character.builder.cult && props.character.builder.cult.selectedSpiritSpells && props.character.builder.cult.selectedSpiritSpells.sort(compareValues('name')).map((item, index) => {
								return(
									<div className={styles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
										<div>{item.name} {item.variant ? item.variant : ""}</div>
										<div style={{marginRight: "12px"}}>{item.points} {item.points == 1 ? "pt" : "pts"}</div>
									</div>
								);
							})
						}
					</div>
				</div>
			</div>
			<div style={{display: "flex"}}>
				<div style={{border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"33%"}}>
					<div style={{border: "2px solid #676767", margin: "5px"}}>
							<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
								<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Passions</div>
							</div>
							{ 
								props.character.builder.passions && props.character.builder.passions.map((item, index) => {
									return(
										<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
											<div>{item.name}{item.focus ? " (" + item.focus + ")" : ""}</div>
											<div style={{marginRight: "12px"}}>{item.value} %</div>
										</div>
									);
								})
							}
						</div>
				</div>
				<div style={{border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"66%", display:"flex"}}>
					<div style={{border: "2px solid #676767", margin: "5px", width: "50%"}}>
							<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
								<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Elemental Runes</div>
							</div>
							{ 
								props.character.builder.runes && props.character.builder.runes.elementalRunes.sort(compareValues('name', 'asc')).map((item, index) => {
									return(
										<div className={styles.row} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
											<div>{item.name}</div>
											<div style={{marginRight: "12px"}}>{item.value} %</div>
										</div>
									);
							})
							}
						</div>
						<div style={{border: "2px solid #676767", margin: "5px", width: "50%"}}>
							<div style={{backgroundColor: "rgb(61, 135, 125)", width: "100%", color: "#fff", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
								<div style={{paddingLeft: "5px", fontWeight:"bold"}}>Power/Form Runes</div>
							</div>
							{ 
								props.character.builder.runes && props.character.builder.runes.powerRunes.sort(compareValues('name', 'asc')).map((item, index) => {
									return(
										<div key={index}>
											<div className={styles.row} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
												<div>{item.first.name}</div>
												<div style={{marginRight: "12px"}}>{item.first.value} %</div>
											</div>
											<div className={styles.row} style={{display:"flex", alignItems:"center", justifyContent:"space-between", minHeight: "24px", overflow: "hidden", padding: "2px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px"}}>
												<div>{item.second.name}</div>
												<div style={{marginRight: "12px"}}>{item.second.value} %</div>
											</div>
										</div>
									);
								// if (item.second.value > 50) {
								// 		return(
								// 		);
								// 	}
								})
							}
						</div>
				</div>
			</div>
			<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
				<Button type="submit" onClick={handleCancelClick} style={{marginTop:"30px", width: "100px", backgroundColor:"#2f588a", color:"#fff"}} variant="contained">Close</Button>
			</div>
			</div>
	);
});

export default BuilderDialog;