import React, {useState, useEffect} from 'react';
import { withPageState } from '../Context/PageState';
import globalStyles from '../Styles/styles.module.css';
import { FormControl, Checkbox } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-blue.svg';
import DialogButtons from '../Dialogs/dialogButtons';
import SkillList from '../Lists/skillList';

const EditSkillsDialog = (props) => {
    
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        let existing = [];
        props.selected.forEach(item => {
            let skill = item.options[0];
            let existingSkill = {name: skill.name, parentId: skill.parentId}
            if (skill.variant) {existingSkill.variant = skill.variant};
            existing.push(existingSkill);
        });
        setSelected(existing);
    }, [props.selected])
    
    const handleOkClick = () => {
        props.update(selected)
        props.close();
    }

    return (
        <div>
            <div style={{padding: "10px"}}>To include a skill within the cults skills, select it using the checkbox on the left, and then click Ok. Adding a skill with specializations (e.g. Ride, Speak etc.) leaves the choice of that specialization open to the player. If a specific value is required for the cult (e.g. Ride Horse, Speak Tradetalk), create those as custom skills within the campaign's Custom Content, and then select them here.</div>
            <SkillList selected={selected} update={setSelected} campaign={props.campaign} />
			<div style={{borderTop: "2px solid #2f588a", marginTop: "10px"}}>
            <DialogButtons handleOkClick={() => handleOkClick()} handleCancelClick={props.close} title="OK" />
			</div>
        </div>
    )
}

export default EditSkillsDialog;