import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context';
import { TextField, OutlinedInput, ListItemText, InputLabel, MenuItem, FormControl, Select, Button, Checkbox, FormGroup, FormControlLabel, Switch, RadioGroup, Radio } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-red.svg'
import compareValues from '../../utils/compareValues';
import MultiSelect from '../Controls/multiSelect';
import { getAllWeapons } from '../../services/dataService';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

const options = [{name: "armourModifier", display: "Armor modifier", prefix: "", suffix: "pts"}, {name: "attackModifier", display: "Attack modifier", prefix: "", suffix: "%", type: "weapon"}, {name: "attackMultiplier", display: "Attack multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "statModifier", display: "Characteristic modifier", prefix: "+", suffix: "", type: "Characteristic"}, {name: "statMultiplier", display: "Characteristic multiplier", prefix: "x", suffix: "", type: "Characteristic"}, {name: "damageModifier", display: "Damage modifier", prefix: "+", suffix: "", prefix: "", suffix: "", type: "weapon"}, {name: "damageMultiplier", display: "Damage multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "defenseModifier", display: "Defense modifier", prefix: "", suffix: "%"}, {name: "moveModifier", display: "Move modifier", prefix: "", suffix: ""}, {name: "moveMultiplier", display: "Move multiplier", prefix: "x", suffix: ""}, {name: "parryModifier", display: "Parry modifier", prefix: "", suffix: "%", type: "weapon"}, {name: "parryMultiplier", display: "Parry multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "skillModifier", display: "Skill modifier", prefix: "", suffix: "%", type: "Skill"}, {name: "skillMultiplier", display: "Skill multiplier", prefix: "x", suffix: "", type: "Skill"}, {name: "spiritArmourModifier", display: "Spirit combat armor modifier", prefix: "", suffix: "pts"}, {name: "strikeRankModifier", display:"Strike rank modifier", prefix: "", suffix: ""}]
// const options = [{name: "armourModifier", display: "Armor modifier", prefix: "", suffix: "pts"}, {name: "attackModifier", display: "Attack modifier", prefix: "", suffix: "%", type: "weapon"}, {name: "attackMultiplier", display: "Attack multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "statModifier", display: "Characteristic modifier", prefix: "+", suffix: "", type: "Characteristic"}, {name: "statMultiplier", display: "Characteristic multiplier", prefix: "x", suffix: "", type: "Characteristic"}, {name: "damageModifier", display: "Damage modifier", prefix: "+", suffix: "", prefix: "", suffix: "", type: "weapon"}, {name: "damageMultiplier", display: "Damage multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "defenseModifier", display: "Defense modifier", prefix: "", suffix: "%"}, {name: "moveModifier", display: "Move modifier", prefix: "", suffix: ""}, {name: "moveMultiplier", display: "Move multiplier", prefix: "x", suffix: ""}, {name: "parryModifier", display: "Parry modifier", prefix: "", suffix: "%", type: "weapon"}, {name: "parryMultiplier", display: "Parry multiplier", prefix: "x", suffix: "", type: "weapon"}, {name: "passionModifier", display: "Passion modifier", prefix: "", suffix: "%", type: "Passion"}, {name: "passionMultiplier", display: "Passion multiplier", prefix: "x", suffix: "", type: "Passion"}, {name: "runeModifier", display: "Rune modifier", prefix: "", suffix: "%", type: "Rune"}, {name: "runeMultiplier", display: "Rune multiplier", prefix: "x", suffix: "", type: "Rune"}, {name: "skillModifier", display: "Skill modifier", prefix: "", suffix: "%", type: "Skill"}, {name: "skillMultiplier", display: "Skill multiplier", prefix: "x", suffix: "", type: "Skill"}, {name: "spiritArmourModifier", display: "Spirit combat armor modifier", prefix: "", suffix: "pts"}, {name: "strikeRankModifier", display:"Strike rank modifier", prefix: "", suffix: ""}]
const characteristics = [{id: "STR", name: "STR"}, {id: "CON", name: "CON"}, {id: "SIZ", name: "SIZ"}, {id: "DEX", name: "DEX"}, {id: "INT", name: "INT"}, {id: "POW", name: "POW"}, {id: "CHA", name: "CHA"}]
const ordinalityOptions = [{name: "per magic point", id: "perMagicPoint"}, {name: "per rune point", id: "perRunePoint"}, {name: "in total", id: "total"}]
const filter = {
    weaponClasses: ["Melee", "Missile", "Shield"],
    damageTypes: ["Crushing (C)", "Cut & Thrust (CT)", "Impaling (I)", "Slashing (S)"],
    meleeCategories: ["Axe", "Dagger", "Sword", "Fist", "Grapple", "Kick", "Hammer", "Mace", "Quarterstaff", "Spear", "Lasso"],
    missileCategories: ["Bow", "Crossbow", "Atlatl", "Javelin", "Throwing Axe", "Throwing Dagger", "Rock", "Sling", "Staff Sling"],
}
const strikeRankTypes = [{id: "DEX", name: "DEX"}, {id: "SIZ", name: "SIZ"}]

const AddWeaponFilterDialog = (props) => {
    const [selectedEffect, setSelectedEffect] = useState({name: "Select", display: "Select"});
	const [value, setValue] = useState(0);
	const [variant, setVariant] = useState("");
    const [effects, setEffects] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [ordinality, setOrdinality] = useState({name: "Select"})
    const [filtered, setFiltered] = useState(props.target === "Weapon"? true: false);
    const [classes, setClasses] = useState([]);
    const [damageTypes, setDamageTypes] = useState([]);
    const [damageInitials, setDamageInitials] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedWeapons, setSelectedWeapons] = useState([])
	const [disabled, setDisabled] = useState(true);
    const [eligible, setEligible] = useState()
    const [weapons, setWeapons] = useState([]);

    const allWeapons = getAllWeapons(props.providerData.campaign);
    const restrictedWeapons = allWeapons.filter(function(weapon) {return !weapon.restricted}).sort(compareValues("name"));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    useEffect(() => {
        if (damageTypes) {
            var types = [];
            damageTypes.forEach(type => {
                switch (type) {
                    case "Crushing (C)": 
                        types.push("C");
                        break;
                    case "Cut & Thrust (CT)": 
                        types.push("CT");
                        break;
                    case "Impaling (I)": 
                        types.push("I");
                        break;
                    case "Slashing (S)": 
                        types.push("S");
                        break;
                }
            })
            setDamageInitials(types);
        }

    },[damageTypes])

    useEffect(() => {
        if (props.filter) {
            setClasses(props.filter.classes ? [...props.filter.classes] : [])
            setCategories(props.filter.categories ? [...props.filter.categories] : [])
            setWeapons(props.filter.weapons ? [...props.filter.weapons] : [])
            setDamageInitials(props.filter.damageTypes ? [...props.filter.damageTypes] : [])
            var types = [];
            props.filter.damageTypes && props.filter.damageTypes.forEach(type => {
                switch (type) {
                    case "C": 
                        types.push("Crushing (C)");
                        break;
                    case "CT": 
                        types.push("Cut & Thrust (CT)");
                        break;
                    case "I": 
                        types.push("Impaling (I)");
                        break;
                    case "S": 
                        types.push("Slashing (S)");
                        break;
                }
            })
            setDamageTypes(types)
        }
    },[props.filter]);

    useEffect(() => {
        let disabled = false;
        if (selectedEffect.name === "Select") {disabled = true;}
        if (value === 0) {disabled = true;}
        if (ordinality.name === "Select") {disabled = true;}
        if (["strikeRankModifier", "statModifier", "statMultiplier", "skillModifier", "skillMultiplier"].indexOf(selectedEffect.name) > -1) {
            if (! selectedItem) {disabled = true;}
        }
        setDisabled(disabled);

    },[selectedEffect, value, ordinality, selectedItem]);

    useEffect(() => {
        if (props.target === "Weapon") {
            let weapons = [];
            restrictedWeapons.forEach(weapon => {
                weapons.push(weapon.name);
            });
            setWeapons(weapons);
        }
    },[props.target])
    
    function handleOkClick() {
        var filter = {
            classes: classes,
            categories: categories,
            damageTypes: damageInitials,
            weapons: selectedWeapons
        }
        props.update(filter)
        props.close();
    }

    function handleCancelClick() {
        props.close();
    }

    const handleAddClick = () => {
        var updatedEffects = [...effects]
        updatedEffects.push({
            prefix: selectedEffect.prefix,
            name: selectedEffect.name,
            item : selectedItem,
            display: selectedEffect.display,
            value: value,
            ordinality: ordinality,
            suffix: selectedEffect.suffix === "pts" ? value === 1 ? "pt" : "pts" : selectedEffect.suffix,
            variant: variant
        });
        setEffects(updatedEffects)
        setSelectedEffect({name: "Select", display: "Select"})
        setValue(0);
    }

	function handleTypeChange(event) {
        var index = options.findIndex(obj => obj.display === event.target.value);
        setSelectedEffect({...options[index]});
	}

    function getOrdinalityDisplayName(effect) {
        let index = ordinalityOptions.map(item => item.id).indexOf(effect.ordinality);
        if (index > -1) {
            return ordinalityOptions[index].name
        }
        else {
            return effect.ordinality.name
        }
    }

    function deleteEffect(index) {
        var updatedEffects = [...effects]
        updatedEffects.splice(index, 1);
        setEffects(updatedEffects)
    }

    function displayEffects() {
        return (
            <div style={{}}>
                {effects && effects.map((effect, index) => {
                return (
                    <div key={index} style={{display:"flex", alignItems: "center", margin: "5px"}}>
                        <div style={{marginRight: "20px", width: "160px"}}>
                            {effect.display}
                        </div>
                        <div style={{display: "flex", alignItems: "center", width: "60px"}}>
                            <div>{effect.prefix}</div>
                            <div style={{marginLeft: "5px"}}>
                                {effect.value}
                            </div>
                            <div>{effect.suffix === "pts" ? effect.value === 1 ? "pt" : "pts" : effect.suffix}</div>
                        </div>
                        <div style={{width: "160px"}}>
                            {getOrdinalityDisplayName(effect)}
                        </div>
                        <div style={{width: "160px"}}>
                            {(effect.item ? effect.item.name : "") + (effect.variant ? " (" + effect.variant + ")" : "") }
                        </div>
                        <div style={{marginRight: "20px", cursor: "pointer"}} onClick={() => deleteEffect(index)}><img src={deleteIcon} style={{height: "24px"}} /></div>
                        </div>
                )
            })}            
            </div>
        )
    }

    const displayChoices = (choices) => {
        let types = "";
        choices.forEach((item, index) => {
            types += item + (index < choices.length -1 ? ", ": "")
        });
        return types;
    }

    const getCategories = () => {
        let categories = [];
        if (classes && classes.length > 0) {
            if (classes.indexOf('Melee') > -1) {
                categories = categories.concat(filter.meleeCategories)
            }
            if (classes.indexOf('Missile') > -1) {
                categories = categories.concat(filter.missileCategories)
            }
            if (classes.indexOf('Shield') > -1) {
                categories.push('Shield');
            }
        }
        else {
            categories = filter.meleeCategories.concat(filter.missileCategories);
            categories.push('Shield');
        }
        return categories;
    }

    const getWeapons = () => {
        let weapons = [];
        if (classes.length === 0 && categories.length === 0 && damageTypes.length === 0) {
            restrictedWeapons.forEach(weapon => {
                weapons.push(weapon.name);
            })
        }
        else {
            let filteredWeapons = []
            if (classes.length > 0) {
                if (classes.indexOf('Melee') > -1) {
                    filteredWeapons = filteredWeapons.concat(restrictedWeapons.filter(function(item) {return item.class === "Melee"}))
                }
                if (classes.indexOf('Missile') > -1) {
                    filteredWeapons = filteredWeapons.concat(restrictedWeapons.filter(function(item) {return item.class === "Missile"}))
                }
                if (classes.indexOf('Shield') > -1) {
                    filteredWeapons = filteredWeapons.concat(restrictedWeapons.filter(function(item) {return item.class === "Shield"}))
                }
            }
            if (filteredWeapons.length === 0) {filteredWeapons = [...restrictedWeapons]};
            if (categories && categories.length > 0) {
                filteredWeapons = filteredWeapons.filter(function(item) { return categories.indexOf(item.type) > -1})
            }
            if (damageInitials && damageInitials.length > 0) {
                filteredWeapons = filteredWeapons.filter(function(item) { return damageInitials.indexOf(item.damage.type) > -1})
            }
            filteredWeapons.forEach(weapon => {
                weapons.push(weapon.name);
            })
        }
        return weapons;
    }
        
    return (
        <div style={{padding: "10px"}}>
            <div>Weapon filters determine the type of weapon(s) that will be affected when the spell is cast or, in the case of a spell with a Weapon target type, the type of weapon(s) that can be selected by the caster when the spell is cast. Multiple filters can be added, with each limiting the range of weapons that are valid targets for the spell.</div>
            <div style={{marginTop: "10px"}}><b>Example 1: </b>to recreate the Bladesharp spirit spell, use the Damage Type filter to select Cut & Thrust weapons, Impaling weapons, and Slashing Weapons, along with a Weapon Class filter of Melee.</div>
            <div style={{marginTop: "10px"}}><b>Example 2: </b>to recreate the Sword Trance rune spell, use the Weapon Category filter to select a category of Sword.</div>
            <hr style={{margin: "10px 0px"}} />
            <div style={{fontWeight: "bold", marginTop: "10px"}}>Filter by: </div>
            <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
                <div style={{width: "120px"}}>Weapon Class: </div>
                <MultiSelect values={filter.weaponClasses} selectedValues={classes} onChange={setClasses} />
                <div style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
                    {displayChoices(classes)}
                </div>
            </div>
            <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
                <div style={{width: "120px"}}>Damage Type: </div>
                <MultiSelect values={filter.damageTypes} selectedValues={damageTypes} onChange={setDamageTypes} />
                <div style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
                    {displayChoices(damageTypes)}
                </div>
            </div>
            <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
                <div style={{width: "120px"}}>Weapon Category: </div>
                <MultiSelect values={getCategories()} selectedValues={categories} onChange={setCategories} />
                <div style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
                    {displayChoices(categories)}
                </div>
            </div>
            <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
                <div style={{width: "120px"}}>Specific Weapon: </div>
                <MultiSelect values={getWeapons()} selectedValues={selectedWeapons} onChange={setSelectedWeapons} />
                <div style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
                    {displayChoices(selectedWeapons)}
                </div>
            </div>

            <hr style={{margin: "10px 0px"}} />
            <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end"}}>
                <Button disabled={classes.length === 0 && damageTypes.length === 0 && categories.length === 0 && weapons.length === 0} type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default withContext(AddWeaponFilterDialog)