import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context';
import { MenuItem, FormControl, Select, Button } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-red.svg'
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import compareValues from '../../utils/compareValues';
import { getAllSkills } from '../../services/dataService';

const options = [{name: "armourModifier", display: "Armor modifier", prefix: "", suffix: "pts"}, {name: "attackModifier", display: "Attack modifier", prefix: "", suffix: "%", type: "Weapon"}, {name: "attackMultiplier", display: "Attack multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "statModifier", display: "Characteristic modifier", prefix: "+", suffix: "", type: "Characteristic"}, {name: "statMultiplier", display: "Characteristic multiplier", prefix: "x", suffix: "", type: "Characteristic"}, {name: "damageModifier", display: "Damage modifier", prefix: "+", suffix: "", prefix: "", suffix: "", type: "Weapon"}, {name: "damageMultiplier", display: "Damage multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "defenseModifier", display: "Defense modifier", prefix: "", suffix: "%"}, {name: "moveModifier", display: "Move modifier", prefix: "", suffix: ""}, {name: "moveMultiplier", display: "Move multiplier", prefix: "x", suffix: ""}, {name: "parryModifier", display: "Parry modifier", prefix: "", suffix: "%", type: "Weapon"}, {name: "parryMultiplier", display: "Parry multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "skillModifier", display: "Skill modifier", prefix: "", suffix: "%", type: "Skill"}, {name: "skillMultiplier", display: "Skill multiplier", prefix: "x", suffix: "", type: "Skill"}, {name: "spiritArmourModifier", display: "Spirit combat armor modifier", prefix: "", suffix: "pts"}, {name: "strikeRankModifier", display:"Strike rank modifier", prefix: "", suffix: ""}]
const characteristics = [{id: "STR", name: "STR"}, {id: "CON", name: "CON"}, {id: "SIZ", name: "SIZ"}, {id: "DEX", name: "DEX"}, {id: "INT", name: "INT"}, {id: "POW", name: "POW"}, {id: "CHA", name: "CHA"}]
const ordinalityOptions = [{name: "per magic point", id: "perMagicPoint"}, {name: "per rune point", id: "perRunePoint"}, {name: "in total", id: "total"}]
const filter = {
    weaponClass: ["Melee", "Missile", "Shield"],
    damageTypes: ["Crushing (C)", "Cut & Thrust (CT)", "Impaling (I)", "Slashing (S)"],
    meleeCategories: ["Axe", "Dagger", "Sword", "Fist", "Grapple", "Kick", "Hammer", "Mace", "Quarterstaff", "Spear", "Lasso"],
    missileCategories: ["Bow", "Crossbow", "Atlatl", "Javelin", "Throwing Axe", "Throwing Dagger", "Rock", "Sling", "Staff Sling"],
}
const strikeRankTypes = [{id: "DEX", name: "DEX"}, {id: "SIZ", name: "SIZ"}]
const dieTypes = [{name: "D3"},{name: "D4"},{name: "D6"},{name: "D8"},{name: "D10"},]

const AddSpellEffectsDialog = (props) => {
    const [selectedEffect, setSelectedEffect] = useState({name: "Select", display: "Select"});
	const [value, setValue] = useState(0);
	const [variant, setVariant] = useState("");
    const [effects, setEffects] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [ordinality, setOrdinality] = useState({name: "Select"})
	const [disabled, setDisabled] = useState(true);
    const [damage, setDamage] = useState({dieType: "", quantity: 0, modifier: 0})
    const allSkills = getAllSkills(props.campaign)
    const sortedSkills = allSkills.filter(function(skill) {return !skill.restricted}).sort(compareValues("name"));

    useEffect(() => {
        if (props.effects) {
            var existingEffects = [];
            props.effects.forEach(effect => {
                let index = options.findIndex(obj => obj.name === effect.name)
                if (index > -1) {
                    let existingEffect = {
                        prefix: options[index].prefix,
                        name: options[index].name,
                        item : effect.item,
                        display: options[index].display,
                        value: effect.value,
                        ordinality: effect.ordinality || {name: "in total", id: "total"},
                        suffix: options[index].suffix
                    };
                    if (effect.item && effect.item.variant) {
                        existingEffect.variant = effect.item.variant;
                    }
                    if (effect.name === "damageModifier") {
                        existingEffect.damage = {quantity: effect.value.quantity, modifier: effect.value.modifier, dieType: "D" + effect.value.dieType}
                        existingEffect.value = effect.value.modifier
                    }
                    existingEffects.push(existingEffect);
                }
            })
            setEffects(existingEffects);
        }
    },[props.effects]);

    useEffect(() => {
        let disabled = false;
        if (selectedEffect.name === "Select") {disabled = true;}
        if (selectedEffect.name !== "damageModifier") {
            if (value === 0) {disabled = true;}
        }
        else if (damage.dieType === 0 || damage.quantity === 0) {
            disabled = true;
        }
        if (ordinality.name === "Select") {disabled = true;}
        if (["strikeRankModifier", "statModifier", "statMultiplier", "skillModifier", "skillMultiplier"].indexOf(selectedEffect.name) > -1) {
            if (! selectedItem) {disabled = true;}
        }
        setDisabled(disabled);

    },[selectedEffect, value, ordinality, selectedItem]);
    
    function handleOkClick() {
        var spellEffects = [];
        effects.forEach(effect => {
            var spellEffect = {
                name: effect.name,
                ordinality: effect.ordinality,
            }
            if (effect.item && effect.item.id) {
                spellEffect.item = {
                    id: effect.item.id,
                    name: effect.item.name
                }
            }
            if (effect.suffix) {
                spellEffect.suffix = effect.suffix;
            }
            if (effect.variant) {
                spellEffect.item.variant = effect.variant;
            }
            if (effect.damage) {
                spellEffect.value = {quantity: effect.damage.quantity, modifier: effect.value};
                spellEffect.value.dieType = parseInt(effect.damage.dieType.substring(1));
            }
            else {
                spellEffect.value = effect.value;
            }
            spellEffects.push(spellEffect)
        })
        props.update({effects: spellEffects, filter: filter})
        props.close();
    }

    function handleCancelClick() {
        props.close();
    }

    const handleAddClick = () => {
        var updatedEffects = [...effects]
        let newEffect = {
            prefix: selectedEffect.prefix,
            name: selectedEffect.name,
            item : {...selectedItem},
            display: selectedEffect.display,
            value: value,
            ordinality: {...ordinality},
            suffix: selectedEffect.suffix === "pts" ? value === 1 ? "pt" : "pts" : selectedEffect.suffix,
            variant: variant,
        }
        if (newEffect.name === "damageModifier") {
            newEffect.damage = {...damage}
        }
        updatedEffects.push(newEffect);
        setEffects(updatedEffects)
        setSelectedEffect({name: "Select", display: "Select"})
        setValue(0);
    }

	function handleTypeChange(event) {
        var index = options.findIndex(obj => obj.display === event.target.value);
        setSelectedEffect({...options[index]});
	}

    function getOrdinalityDisplayName(effect) {
        let index = ordinalityOptions.map(item => item.id).indexOf(effect.ordinality);
        if (index > -1) {
            return ordinalityOptions[index].name
        }
        else {
            return effect.ordinality.name
        }
    }

    function deleteEffect(index) {
        var updatedEffects = [...effects]
        updatedEffects.splice(index, 1);
        setEffects(updatedEffects)
    }

    function displayDamage(effect) {
        var damage = "";
        if (effect.damage.quantity) {
            damage += effect.damage.quantity;
        }
        if (effect.damage.dieType) {
            damage += effect.damage.dieType;
        }
        if (effect.value) {
            damage = effect.value < 0 ? damage + "" + effect.value : damage + "+" + effect.value;
        };
        return damage;
    }

    function displayEffects() {
        return (
            <div style={{}}>
                {effects && effects.map((effect, index) => {
                return (
                    <div key={index} style={{display:"flex", alignItems: "center", margin: "5px"}}>
                        <div style={{marginRight: "10px", width: "160px"}}>
                            {effect.display}
                        </div>
                        <div style={{display: "flex", alignItems: "center", width: "60px"}}>
                            {effect.damage ? 
                                displayDamage(effect)
                            : 
                                <>
                                    <div>{effect.prefix}</div>
                                    <div style={{marginLeft: "5px"}}>
                                        {effect.value}
                                    </div>
                                    <div>{effect.suffix === "pts" ? effect.value === 1 ? "pt" : "pts" : effect.suffix}</div>
                                </>
                            }
                        </div>
                        <div style={{width: "160px", marginLeft: "10px"}}>
                            {getOrdinalityDisplayName(effect)}
                        </div>
                        <div style={{width: "160px"}}>
                            {(effect.item && effect.item.name ? effect.item.name : "") + (effect.variant ? " (" + effect.variant + ")" : "") }
                        </div>
                        <div style={{marginRight: "20px", cursor: "pointer"}} onClick={() => deleteEffect(index)}><img src={deleteIcon} style={{height: "24px"}} /></div>
                        </div>
                )
            })}            
            </div>
        )
    }

    const updateDamage = (field, value) => {
        let updatedDamage = {...damage};
        updatedDamage[field] = value;
        setDamage(updatedDamage)
    }

    const selectSpecificItem = () => {
        var values = [];
        switch(selectedEffect.name) {
            case "strikeRankModifier": 
                values =  strikeRankTypes;
                break;
            case "statModifier": 
            case "statMultiplier": 
                values =  characteristics;
                break;
            case "skillModifier": 
            case "skillMultiplier": 
                values =  sortedSkills;
                break;
        }
        if (values.length > 0) {
            return (
                <Selector style={{width: "180px"}} values={values} onChange={(value) => setSelectedItem(value)} />
            )   
        }
    }
        
    return (
        <div style={{padding: "10px"}}>
            <div style={{display:"flex", marginTop: "20px", alignItems: "center", fontWeight: "bold"}}>
                <div style={{width: "250px"}}>Effect Type</div>
                {selectedEffect.name === "damageModifier" ? 
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{width: "60px", margin: "0px 10px"}}>Quantity</div>
                        <div style={{width: "96px", textAlign: "center"}}>Die Type</div>
                    </div>
                : null }
                <div style={{width: "80px", textAlign: "center", margin: "0px 10px"}}>Modifier</div>
                <div style={{width: "150px", textAlign: "center"}}>Per point/total</div>
            </div>
            <div style={{display:"flex", marginTop: "10px", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{display:"flex", alignItems: "center"}}>
                    <FormControl variant="outlined" >
                        <Select value={selectedEffect.display} style={{backgroundColor:"#fff", width: "250px", height: "45px"}} autoWidth={true} onChange={handleTypeChange} color="primary">
                            <MenuItem key={0} value="Select">
                                <em>Select effect</em>
                            </MenuItem>
                            { 
                                options.filter(function (item) {return ((props.target === "Character" && ["Damage multiplier", "Damage modifier"].indexOf(item.display) < 0 ) || item.type === props.target)}).map((option, index) => {
                                    return( <MenuItem key={index} value={option.display}>{option.display}</MenuItem> );
                                })
                            }
                        </Select>
                    </FormControl>
                    {selectedEffect.name === "damageModifier" ? 
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{margin: "0px 10px", width: "60px"}}>
                                <EditBox number value={value} onChange={(value) => updateDamage("quantity", value)} />
                            </div>
                            <Selector style={{width: "100px"}} values={dieTypes} onChange={(dieType) => updateDamage("dieType", dieType.name)} />
                        </div>
                    : null }
                    <div style={{display:"flex", alignItems: "center", margin: "0px 10px", width: "80px"}}>
                        <div style={{marginLeft: "10px"}}>{selectedEffect.prefix}</div>
                        <div style={{marginLeft: "5px", width: "80px"}}>
                            <EditBox number onChange={setValue} value={value} />
                        </div>
                        <div style={{marginLeft: "5px"}}>{selectedEffect.suffix}</div>
                    </div>
                    <Selector style={{width: "140px"}} values={ordinalityOptions} onChange={(value) => setOrdinality(value)} />
                    <div style={{marginLeft: "20px"}}>
                        {selectSpecificItem()}
                    </div>
                    {selectedItem && selectedItem.variable ? 
                        <div style={{marginLeft: "15px", width: "140px"}}>
                            <EditBox placeholder="Specialization" onChange={setVariant} value={variant} />
                        </div>
                    : null}
                </div>
            </div>
            <div style={{display:"flex", flexDirection: "row-reverse", marginTop: "20px"}}>
                <Button disabled={disabled} style={{borderRadius: "3px", width: "120px", height: "40px", marginLeft: "20px"}} color="primary" variant="contained" onClick={handleAddClick}>Add effect</Button>
            </div>

            <div style={{fontSize: "18px", fontWeight: "bold", marginTop: "20px"}}>Effects: </div>
            {displayEffects()}

            <hr style={{marginTop: "30px"}} />
            <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end"}}>
                <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default withContext(AddSpellEffectsDialog)