import React from 'react';
import RuneSpellListItem from './runeSpellListItem';
import { withPageState } from '../Context/PageState';

const RuneSpellList = (props) => {

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", height: "500px", margin: "10px"}}>
			<div>
				<div style={{fontSize: "14px"}}>
					<div style={{display:"flex", padding: "5px", width: "99%", margin: "2px", fontWeight: "bold"}}>
						<div style={{width:"50px"}}></div>
						<div style={{width:"225px"}}>Spell</div>
						<div style={{width:"80px", textAlign:"center"}}>Points</div>
						<div style={{width:"80px", textAlign:"center"}}>Cast %</div>
						<div style={{width:"100px", textAlign:"center"}}>Stackable</div>
						<div style={{width:"240px"}}>Description</div>
					</div>
					<div style={{maxHeight: "480px", overflow: "auto"}}>
					{props.spells.map((spell, index) => {
						if (!props.pageState.editMode){
							return(
								<RuneSpellListItem campaign={props.campaign} matrix={props.matrix} characterRunes={props.characterRunes} key={index} spell={spell} cult={props.cult} update={props.update} availableSpellCount={props.availableSpellCount} selected={props.selected} select={props.select}/>
							)
						}
						else if (! spell.common) {
							return(
								<RuneSpellListItem campaign={props.campaign} matrix={props.matrix} characterRunes={props.characterRunes} key={index} spell={spell} cult={props.cult} update={props.update} availableSpellCount={props.availableSpellCount} selected={props.selected} select={props.select}/>
							)
						}
					})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withPageState(RuneSpellList);