import React, { useState, useEffect } from 'react';
import { withContext } from '../Context/context';
import { withPageState } from '../Context/PageState';
import compareValues from '../../utils/compareValues';
import * as SAVE from '../../constants/save';
import { calculateSpellStorage } from '../../services/magicPointsService';
import SpiritSpell from './spiritSpell';
import DialogBox from '../Dialogs/dialogBox';
import ManageSpiritSpellDialog from './manageSpiritSpellDialog';
import MagicPointIncrementor from '../Controls/magicPointIncrementor';
import EditIcon from '../../icons/edit-icon-white.svg'

const SpiritMagicEntity = (props) => {

    const [open, setOpen] = useState(false);
    const [spiritSpells, setSpiritSpells] = useState();
    const [storage, setStorage] = useState(-1);

    useEffect(() => {
        var sorted = props.entity.spiritSpells.sort(compareValues('name'));
        setSpiritSpells(sorted);
    },[props.entity.spiritSpells]);

    useEffect(() => {
        setStorage(calculateSpellStorage(props.entity));
    },[props.entity]);

    function update(updatedSpell){
        var updatedSpells = [...spiritSpells];
        var index = updatedSpells.findIndex(spell => spell.id === updatedSpell.id);
        if (index > -1) {
            updatedSpells[index] = updatedSpell;
        }
        props.update([{fieldName: "spiritSpells", value: updatedSpells}], SAVE.DELAYED)
    }
    
    function powGain(gained) {
        if (gained) {
            props.update([{fieldName: "powGain", value: true}], SAVE.DELAYED);
        }
    }

    function setSpells(newSpells) {
        var updatedSpells = [...newSpells];
        props.update([{fieldName: "spiritSpells", value: updatedSpells}], SAVE.NO)
    }

    function openPanel(event) {
        event.stopPropagation();
        setOpen(true);
    }

    function displayItem(details) {
        props.displayItem({...details, entity: props.spellcaster});
    }

    if (spiritSpells) {

        return(
            <>
                {props.entity.id === props.providerData.character.id ? 
                    <div style={{backgroundColor: "#8a612f", color: "#ffffff", fontSize: "15px", height: "30px", display:"flex", alignItems: "center", justifyContent: "space-between", position: "relative"}} >
                        <div style={{display:"flex", justifyContent: "center", alignItems: "center", paddingLeft: "5px"}}>
                            <div style={{marginRight:"20px"}}>{props.entity.name.first + " spells"}</div>
                        </div>
                        {props.pageState.editMode ? 
                            <div style={{position: "absolute", right: "10px", top: "4px", cursor: "pointer"}} onClick={(event) => openPanel(event)}>
                                <img src={EditIcon} style={{width: "22px"}} alt="Edit matrix" title="Edit spirit spells" />
                            </div>
                        : 
                            <MagicPointIncrementor update={props.update} entityId={props.entity.id} magicPoints={props.providerData.character.magicPoints} />
                        }
                    </div>
                : null
                }
                {(props.entity.id !== props.providerData.character.id) && (props.spellcaster.id === props.providerData.character.id) ? 
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#2F7589", color: "#fff", padding: "0px 5px", height: "30px"}}>
                        <div>{props.entity.name.first + " spells (cast by " + props.spellcaster.name.first + ")"}</div>
                    </div>
                :
                null
                }
                {props.providerData.type === "npc" ? 
                    props.pageState.editMode ? 
                        <div style={{display:"flex", fontWeight: "bold", padding: "5px 5px 0px"}}>
                            <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                            <div style={{width: "80px", textAlign: "center"}}>Variable</div>
                            <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                        </div>
                    :
                    <div style={{display:"flex", padding:"2px 5px 2px 0px", fontWeight:"700", fontSize:"13px"}}>
                        <div style={{width:"154px", marginLeft :"10px"}}>Name</div>
                        <div style={{width:"80px", textAlign:"center"}}>Variable</div>
                        <div style={{width:"30px", textAlign:"center"}}>Pts</div>
                        <div style={{width:"30px", textAlign: "center", marginLeft: "10px"}}>SR</div>
                        <div style={{width:"40px", textAlign:"center", marginLeft: "20px"}}>Cast</div>
                    </div>
                :
                <>
                    {props.pageState.editMode ? 
                        <div style={{display:"flex", fontWeight: "bold", padding: "5px 5px 0px"}}>
                            <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                            <div style={{width: "80px", textAlign: "center"}}>Variable</div>
                            <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                        </div>
                : 
                        <div style={{display:"flex", fontWeight: "bold", padding: "5px 5px 0px"}}>
                            <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                            <div style={{width: "30px", textAlign: "center"}}>Pts</div>
                            <div style={{width: "70px", textAlign: "center"}}>Variable</div>
                            <div style={{width: "30px", textAlign: "center"}}>SR</div>
                            <div style={{width: "40px", textAlign: "right", marginLeft: "8px"}}>Cast</div>
                        </div>
                    }
                    </>
                }
                <div className={props.stylesheet.spellList}>
                    {spiritSpells.map((item, index) => {
                        return (
                            <SpiritSpell diceRoller={props.diceRoller} element={props.element} key={index} spellcaster={props.spellcaster} character={props.providerData.character} availableMagicPoints={props.availableMagicPoints} item={item} processResult={props.processResult} update={update} displayItem={displayItem} powGain={powGain} />
                        )
                    })}

                </div>
                <DialogBox isOpen={open} size="md" title="Manage Spirit Spells">
                    <ManageSpiritSpellDialog campaign={props.providerData.campaign} currentSpells={spiritSpells} storage={storage} open={open} setSpells={setSpells} close={() => setOpen(false)} />
                </DialogBox>
            </>
        );
    }
    else {
        return null;
    }
}

export default withContext(withPageState(SpiritMagicEntity));