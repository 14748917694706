import React, { useState, useEffect } from 'react';
import styles from '../character.module.css';
import { withPageState } from '../../Context/PageState';
import { withContext } from '../../Context/context';
import EditBox from '../../Controls/editBoxSingle';
import { calculateCurrentEncumbrance } from '../../../services/attributeService';
import { calculateMove } from '../../../services/characterService';
import HitPoints from './hitPoints';
import MagicPoints from './magicPoints';
import Wealth from './wealth';
import calculateSR from '../../../services/strikeRankService';

const AttributeBar = (props) => {

    const [move, setMove] = useState();
    const [enc, setEnc] = useState(0);
    const [editMode, setEditMode] = useState();

    useEffect(() => {
        setEditMode(props.pageState.editMode);
    },[props.pageState.editMode]);

    useEffect(() => {
        var currentEnc = calculateCurrentEncumbrance(props.providerData.character);
        setEnc(currentEnc);
    },[props.providerData.character.armour, props.providerData.character.weapons])

    useEffect(() => {
        setMove(props.providerData.character.move);
    },[props.providerData.character.move]);

    function handleChange(value) {
        setMove(value);
        var updatedCharacter = {...props.providerData.character};
        updatedCharacter.move = value;
        props.update(updatedCharacter);
    }

    return (
        <div style={{height: "20px", backgroundColor: "#121312", color: "#fff", padding: "5px", border: "1px solid #2F588A", margin: "0px 5px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <HitPoints update={props.update} />
            <MagicPoints update={props.update} />
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                <div>Move: </div>
                {editMode ? 
                    <div style={{width: "60px"}}><EditBox onChange={handleChange} height="12px" fontSize="14px" value={move} size="sm" number>{move}</EditBox></div>
                    :
                    <div>{calculateMove(props.providerData.character, props.providerData.currentEncumbrance)}</div>
                }
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                <div>DB: </div>
                {props.providerData.character.damageBonus.quantity == 0 ? 
                <div>0</div>
            : 
                <div style={{display:"flex"}}>
                    <div>{props.providerData.character.damageBonus.quantity < 0 ? null : <div>+</div>}</div>
                    <div>{props.providerData.character.damageBonus.quantity}D{props.providerData.character.damageBonus.dieType}</div>
                </div>
            }
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                <div>SIZ SR: </div>
                <div>{calculateSR("SIZ", props.providerData.character)}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                <div>DEX SR: </div>
                <div>{calculateSR("DEX", props.providerData.character)}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", backgroundColor: enc > props.providerData.character.maxEnc ? "#851113" : "#121312"}}>
                <div>Enc: </div>
                <div>{enc}/{props.providerData.character.maxEnc}</div>
            </div>
            <Wealth update={props.update} />
        </div>
    )
}

export default withPageState(withContext(AttributeBar));