import React, { useState, useEffect } from 'react';
import { withPageState } from '../../Context/PageState';
import Abilities from '../../Abilities/abilities';
import compareValues from '../../../utils/compareValues';
import { withContext } from '../../Context/context';
import AddSkillDialog from "../dialogs/addNewSkillDialog";
import * as SAVE from '../../../constants/save'
import PanelHeader from '../../Controls/panelHeader';

const SkillsPanel = (props) => {
    const [allSkills, setAllSkills] = useState();
    const [displaySkills, setDisplaySkills] = useState([]);
    const [sortOrder, setSortOrder] = useState("alphabetical");
    const [knownOnly, showKnownOnly] = useState(false);
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.skills.favourites)
            showKnownOnly(props.providerData.character.configuration.skills.known)
            setSortOrder(props.providerData.character.configuration.skills.sortOrder)
        }
        var skills = [];
        var sorted = props.entity.skills.sort(compareValues('name'));
        sorted = sorted.sort(compareValues('variant'));
        sorted.forEach(skill => {
            // if ((!props.pageState.editMode) && ((skill.value <= 0) || (skill.value + (props.providerData.character.categoryModifiers[skill.skillCategory] || 0) <= 0))) return; #Scotty answer, don't trust
            if (!props.pageState.editMode && skill.value <= 0) return;
            if ((props.providerData.character.configuration && props.providerData.character.configuration.skills.known) && (skill.value <= skill.baseValue) && (!props.pageState.editMode)) {
                return;
            }
            if (props.providerData.character.configuration && props.providerData.character.configuration.skills.favourites && (!skill.favourite) && (!props.pageState.editMode)) {
                return;
            }
            skills.push({...skill});
        })
        setDisplaySkills(skills);
    },[props.providerData.character.configuration, props.entity.skills, props.pageState.editMode, sortOrder, knownOnly, favouritesOnly]);

    useEffect(() => {
        var sorted = props.entity.skills.sort(compareValues('name'));
        sorted = sorted.sort(compareValues('variant'));
        setAllSkills(sorted);
    },[props.entity.skills]);
    
    const addSkill = (newSkill) => {
        var updatedSkills = [...allSkills]
        var index = updatedSkills.findIndex(obj => obj.parentId === newSkill.parentId && (obj.variant && obj.variant.toLowerCase() === newSkill.variant.toLowerCase()))
        if (index < 0) {
            updatedSkills.push(newSkill);
            props.update([{fieldName: "skills", value: updatedSkills}], SAVE.NO)
        }
    }

    if (!allSkills) return null;

        return(
            <div style={{border: "2px solid #2F588A", borderRadius: "5px", position: "relative"}}>
                <PanelHeader title="Skills" addTitle="Add skill" addItem={addSkill}>
                    <AddSkillDialog />
                </PanelHeader>
                {/* <div style={{position: "absolute", opacity: 0.15, left: "160px", top: "60px"}}><img src="https://storage.googleapis.com/runequest/runes/movement-rune-600x600.png" style={{width: "400px", height: "400px"}} /></div> */}
                <Abilities diceRoller={props.diceRoller} style={{...props.style}} title={props.title} displayItem={props.displayItem} entity={props.entity} tile={props.tile} columnCount={props.columnCount} favouritesOnly={favouritesOnly} displayItems={displaySkills} type="Skill" items={allSkills} processResult={props.processResult} update={props.update} ></Abilities>
            </div>
        );
    }

    export default withContext(withPageState(SkillsPanel));