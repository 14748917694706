import { Button } from '@mui/material';
import React from 'react';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';

const Admin = (props) => {

  function fixCharacters() {
    props.firebase
    .characters()
    .get()
    .then(dataset => {
      dataset.forEach(doc => {
        var character = doc.data();
        if (character.complete){
          character.skills = addIdTo(character.skills)
          character.passions = addIdTo(character.passions)
          character.runes = addIdTo(character.runes)
          character.weapons = addIdTo(character.weapons)
          character.weaponSkills = addIdTo(character.weaponSkills)
          character.spiritSpells = addIdTo(character.spiritSpells)
          character.cults = addIdTo(character.cults)
          character.matrices = addIdTo(character.matrices)
          character.crystals = addIdTo(character.crystals)
          character.boundSpirits = addIdTo(character.boundSpirits)
          character.cults.forEach(cult => {
            cult.runeSpells = addIdTo(cult.runeSpells);
          });
          character.boundSpirits.forEach(spirit => {
            if (spirit.spiritSpells) {
              spirit.spiritSpells = addIdTo(spirit.spiritSpells);
            }
            if (spirit.runeSpells) {
              spirit.runeSpells = addIdTo(spirit.runeSpells);  
            }
          })
          character.matrices.forEach(matrix => {
            if (matrix.spell) {
              if (! matrix.spell.id) {
                matrix.spell.id = uuidv4();
              }
            }
          })
          props.firebase
          .character(character.id)
          .set(character)
          .then(() => {})
        }
      })
    })
  }

  function addIdTo (items) {
    if (items && items.length > 0) {
      items.forEach(item => {
        if (! item.id) {
          item.id = uuidv4();
        }
      });
      return items;
    }
    else {
      return [];
    }
  }

  return (
    <div style={{margin: "200px"}}>
      <h1>Admin</h1>
      <div >
        <Button title = "Fix characters" onClick={fixCharacters} variant='contained' color="primary" style={{backgroundColor: "#2f588a", color: "#fff", padding: "10px"}}>Fix Characters</Button>
      </div>
    </div>
  )
}
 
export default withFirebase(Admin);