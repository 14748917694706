import React, {useState, useEffect, useRef} from 'react';
import DiceButton from '../Buttons/diceButton';
import SmallDiceButton from '../Buttons/smallDiceButton';
import {withContext} from '../Context/context';
import { FormControlLabel, Switch } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import ModifierBox from '../Controls/modifierBox';
import * as SAVE from '../../constants/save';
import { withPageState } from '../Context/PageState';
import { getEnchantmentsModifier } from '../../services/enchantmentsService';
import { getAllPassions, getAllRunes, getAllSkills } from '../../services/dataService';

const AbilityTileContextMenu = (props) => {

    const [modifier, setModifier] = useState("");
    const [augment, setAugment] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [item, setItem] = useState(props.panelItem.item);
    const [description, setDescription] = useState("");
    const [panelHeight, setPanelHeight] = useState(0);
    const [categoryModifier, setCategoryModifier] = useState(0);
    const [encPenalty, setEncPenalty] = useState(0);
    const [enchantmentsModifier, setEnchantmentsModifier] = useState(0);
    const [currentAugment, setCurrentAugment] = useState(0);

    useEffect(() => {
        var type = props.panelItem.type.toLowerCase() + "s";
        var index = props.providerData.character[type].findIndex(obj => obj.id === props.panelItem.item.id);
        if (index > -1) {
            var updatedItem = props.providerData.character[type][index];
            setHidden(updatedItem.hidden || false)
            var encPenalty = 0;
            if (props.panelItem.type === "Skill") {
                getDescriptionFrom(getAllSkills(props.providerData.campaign));
                getCategoryModifier()
                if (["Agility", "Manipulation", "Stealth"].indexOf(updatedItem.skillCategory) > -1) {
                    var excessEnc = props.providerData.character.maxEnc - props.providerData.currentEncumbrance;
                    if (updatedItem.name === "Dodge") {
                        if (props.providerData.currentEncumbrance <= props.providerData.character.maxEnc) {
                            encPenalty -= props.providerData.currentEncumbrance
                        }
                        else {
                            encPenalty -= props.providerData.character.maxEnc
                            encPenalty += (excessEnc * 5);
                        }
                    }
                    else {
                        if (excessEnc < 0) {
                            encPenalty = (excessEnc * 5)
                        }
                    }
                }
            }
            else if (props.panelItem.type === "Rune") {
                getDescriptionFrom(getAllRunes(props.providerData.campaign));
                setCategoryModifier(0)
            }
            else if (props.panelItem.type === "Passion") {
                getDescriptionFrom(getAllPassions(props.providerData.campaign));
                setCategoryModifier(0)
            }
            var value = updatedItem.value
            if (updatedItem.skillCategory) {
                value += props.providerData.character.categoryModifiers[updatedItem.skillCategory];
            }
            var enchantmentsMod = getEnchantmentsModifier(props.panelItem.type, props.providerData.character, updatedItem, value);
            setEncPenalty(encPenalty);
            setEnchantmentsModifier(enchantmentsMod);
            setItem(updatedItem);
            setAugment(false);
        }
    },[props.panelItem, props.providerData.character]);

    useEffect(() => {
        setPanelHeight(props.panelHeight - 100);
    },[props.panelHeight])

    useEffect(() => {
        if (props.providerData.augmenting) {
            setCurrentAugment(props.providerData.augmenting.modifier)
        }
    },[props.providerData.augmenting])

    useEffect(() => {
        if (props.buttonClick === "ok") {
            update(item, SAVE.NO);
        }
    },[props.buttonClick])

    const getCategoryModifier = () => {
        var modifier = props.providerData.character.categoryModifiers[props.panelItem.item.skillCategory] || 0;
        setCategoryModifier(modifier);
    }

    const getDescriptionFrom = (data) => {
        var index = data.findIndex(obj => obj.id === props.panelItem.item.parentId);
        setDescription(data[index].description);
    }

    const assignAugment = () => {
        setAugment(!augment)
    }

    const processResult = (roll) => {
        setAugment(false);
        var messageText={critical: " achieved a critical success with {pronoun} " + " " + roll.item.name +" " + props.panelItem.type.toLowerCase(), special: " achieved a special success with {pronoun} " + " " + roll.item.name +" " + props.panelItem.type.toLowerCase(), success: " succeeded with {pronoun} " + " " + roll.item.name +" " + props.panelItem.type.toLowerCase(), failure: " failed with {pronoun} " + " " + roll.item.name +" " + props.panelItem.type.toLowerCase(), fumble: " fumbled {pronoun} "  + " " + roll.item.name +" " + props.panelItem.type.toLowerCase()};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment, type: props.panelItem.type, hidden: hidden}
        props.processResult(dieRoll, {...roll.item, type:props.panelItem.type.toLowerCase() + "s"}, roll.title)
    }
    
    function handleValueChange(field, value) {
        var updatedItem = {...item};
        updatedItem[field] = value;
        setItem(updatedItem);
        console.log('updatedItem', updatedItem)
    }

    function update(item, persist) {
        var type = props.panelItem.type.toLowerCase() + "s";
        var updatedAbilities = [...props.providerData.character[type]];
        const index = updatedAbilities.findIndex(ability => ability.id === item.id);
        if (index > -1) {
            updatedAbilities[index] = item;
            props.update([{fieldName: type, value: updatedAbilities}], persist);
            console.log('updatedAbilities', updatedAbilities)
        }
    }

    function setDice(value) {
        props.diceRoller.setDice(value);
        props.diceRoller.setHidden(hidden);
    }

    function displayAbilityDetails() {
        return (
            <>
                <hr style={{width:"100%"}} />
                <div style={{fontWeight: "bold", lineHeight: "20px"}}>
                    <div>Base Value: {item.value}%{((categoryModifier && item.value > 0) ? " + Category Modifier (" + categoryModifier + "%)" + " = " + (item.value + categoryModifier) + "%" : "")} </div>
                    {enchantmentsModifier ? 
                        <div>Enchantments: {enchantmentsModifier}%</div>
                    : null}
                    {currentAugment ? 
                        <div>Inspiration: {currentAugment}%</div>
                    : null}
                    {props.providerData.character.despondent ? 
                        <div>Despondent: -10%</div>
                    : null}
                    {encPenalty ? 
                        <div>Enc Penalty: {encPenalty}%</div>
                    : null}
                </div>
                <hr style={{width:"100%"}} />
                <div style={{}}>
                    <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{item.name}</div>
                    <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px"}}>
                        <div dangerouslySetInnerHTML={{__html: description}}></div>
                    </div>
                </div>
            </>
        )
    }

    if (props.pageState.editMode){
        return(
            <div style={{fontSize: "14px", lineHeight: "1"}}>
                <div style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
                    {item.variable ? 
                    <>
                    <div style={{marginRight: "10px"}}>Focus: </div>
                    <EditBox onChange={(value) => handleValueChange("variant", value)} value={item.variant} width="200px" >{item.variant}</EditBox>
                    </>
                    : 
                    null
                    }
                </div>
                <div style={{display:"flex", margin: "10px", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{marginRight: "10px"}}>Value{props.panelItem.type === "Skill" ? "*" : ""}:</div>
                    <EditBox onChange={(value) => handleValueChange("value", value)} value={item.value} width="100px" number>{item.value}</EditBox>
                </div>
                {props.panelItem.type === "Skill" ? 
                    <div>* Does not include category modifier</div>
                : 
                null}
                {displayAbilityDetails()}
            </div>
        )
    }
    else {
        return (
            <div style={{padding: "10px", fontSize: "14px", lineHeight: "1", height: panelHeight + "px", overflow: "auto"}}>
                <div style={{width:"100%", display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                    <div>Result visible to the GM only</div>
                    <FormControlLabel style={{marginTop: "-5px"}}
                        control={<Switch checked={hidden} color="primary" onChange={(event) => setHidden(event.target.checked)} />}
                    />
                </div>
                <hr style={{width:"100%"}} />
                <div style={{width:"100%", display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                    <div>Augmentation roll:</div>
                    <FormControlLabel style={{marginTop: "-5px"}}
                        control={<Switch disabled={props.augmenting?.state != ""} checked={augment} color="primary" onChange={(event) => assignAugment(event.target.checked)} />}
                    />
                </div>
                <hr style={{width:"100%"}} />
                <ModifierBox handleChange={(value) => setModifier(value)} />
                <div style={{width: "100%", display: "flex", flexDirection: "row-reverse", padding: "10px 0px"}}>
                    <div style={{width: "120px", marginRight: "4px"}}>
                        <DiceButton diceRoller={{diceResults:props.diceRoller.diceResults, setDice:setDice}} type={props.panelItem.type} augment={augment} disabled={item.value === 0} processResult={processResult} item={item} modifier={modifier} entity={props.entity}>
                            <SmallDiceButton />
                        </DiceButton>
                    </div>
                </div>
                {displayAbilityDetails()}
            </div>
        )
    }
}
export default withContext(withPageState(AbilityTileContextMenu))