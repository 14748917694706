import React, { useState, useEffect, useRef } from 'react';
import styles from './builder.module.css'
import globalStyles from '../Styles/styles.module.css';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import CloseIcon from '../../icons/close-icon.svg';
import Homeland from './step1Homeland/homeland';
import { withAuthorization, withEmailVerification, AuthUserContext } from '../Session';
import { Button } from '@mui/material';
import FamilyOccupation from './step2Family/familyOccupation';
import History from './step3History/history';
import Runes from './step4Runes/runes';
import Characteristics from './step5Characteristics/characteristics';
import HomelandBonuses from './step6CulturalBonuses/culturalBonuses';
import Occupations from './step7Occupation/occupation'
import Cults from './step8Cult/cult'
import {applyArmour, calculateHitPoints} from '../../services/hitPointsService';
import {calculateMagicPoints, calculateSpiritCombatDamage, calculateDamageBonus, calculateMaxEncumbrance, calculateStrikeRanks} from '../../services/attributeService';
import ViewIcon from '../../icons/view-icon.svg';
import PersonalBonuses from './step9PersonalBonuses/personalBonuses';
import PersonalInfo from './step10PersonalInfo/personalInfo';
import BuilderDialog from './dialogs/builderDialog';
import DialogBox from '../Dialogs/dialogBox';
import {getCategoryModifiers} from '../../services/skillsService';
import { v4 as uuidv4 } from 'uuid';
import * as homelandsData from '../../data/homelands.json'
import * as weaponsData from '../../data/weapons.json';
import Heirloom from './step11Heirloom/heirloom';
import AirRune from '../../images/air-rune-600x600.png'
import { withFirebase } from '../Firebase';
import { newWeapon, newWeaponSkill } from '../../services/weaponService';
import HelpButton from './helpButton';
import DetailsPanel from '../Controls/detailsPanel';
import { getAllRuneSpells, getAllSkills, getAllSpiritSpells } from '../../services/dataService';

const Builder = (props) => {

    const params = useParams()

	const [uid, setUid] = useState();
	const [character, setCharacter] = useState();
	const [campaign, setCampaign] = useState();
	const [step, setStep] = useState({no: 1, name: "Select Homeland"});
	const [nextDisabled, setNextDisabled] = useState(true);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [open, setOpen] = useState(false);
	const [displayCharacter, setDisplayCharacter] = useState({builder: {}});
	const [user, setUser] = useState();
    const [homelands, setHomelands] = useState([]);
	const [weaponData, setWeaponData] = useState([]);
	const [builder, setBuilder] = useState({});
	const [spiritSpells, setSpiritSpells] = useState([]);
	const [runeSpells, setRuneSpells] = useState([]);
	const [building, setBuilding] = useState(false);
	const [buildingMessage, setBuildingMessage] = useState("");
    const [panelVisibility, setPanelVisibility] = useState("hidePanel");
    const [panelHeight, setPanelHeight] = useState(800);
    const [panelMargin, setPanelMargin] = useState(-400)
    const [panelPosition, setPanelPosition] = useState(0);
    const [panelItem, setPanelItem] = useState(<div></div>)
    const [panelOpen, setPanelOpen] = useState(false)
    const [cultureRune, setCultureRune] = useState("");
	const [runeState, setRuneState] = useState("");

	useEffect(() => {
		var uid = params.uid;
		setUid(uid);
	},[params]);
         
    useEffect(() => {
        document.title = "RuneQuesting: Character Builder"
    },[]);

    useEffect(() => {
		if (builder.homeland) {
			if (builder.homeland.name === "Prax") {
				setCultureRune(builder.tribe?.rune)
			}
			else {
				setCultureRune(builder.homeland.rune)
			}
		}
      }, [builder.homeland]);

    useEffect(() => {
        var width = window.innerWidth;
        if (width > 1928) {
            var result = (((window.innerWidth -1200)/2))
            setPanelPosition(result);
        }
        else {
            setPanelPosition(364);
        }
        if (window.innerHeight < 800) {
            setPanelHeight(window.innerHeight)
            setPanelMargin((window.innerHeight * -0.5) + 50)
        }
    },[window.innerWidth, window.innerHeight])

	useEffect(() => {
		var characterId = uid;
		props.firebase
		.character(characterId)
		.get()
		.then((doc) => {
			var loadedCharacter = {...doc.data()};
			if (loadedCharacter.complete) {
				window.location.href = "/charactersheet/" + uid;
			}
			else {
				setCharacter(loadedCharacter);
				setBuilder(loadedCharacter.builder);
				if (loadedCharacter.campaignId) {
					props.firebase
					.campaign(loadedCharacter.campaignId)
					.get()
					.then(campaignDoc => {
						setCampaign({...campaignDoc.data(), id: campaignDoc.id});
					})
				}
			}
		});
	},[uid])
  
    useEffect(() => {
        var allHomelands = {...homelandsData}
		setHomelands(allHomelands.data);
      },[]);

	  useEffect(() => {
        var allWeapons = {...weaponsData}
		setWeaponData(allWeapons.data);
	  },[]);

	  useEffect(() => {
		setSpiritSpells([...getAllSpiritSpells(campaign)]);
	  },[]);

	  useEffect(() => {
		setRuneSpells(getAllRuneSpells(campaign));
	  },[]);

	useEffect(() => {
		if (step.no === 1) {
			setPrevDisabled(true);
		}
		else {
			setPrevDisabled(false);
		}
	},[step]);

	function updateBuilder(updatedBuilder, persist) {
		setBuilder(updatedBuilder);
		if (persist) {
			saveBuilder(updatedBuilder);
		}
	}

	function setOptions(option, value) {
		let updatedCharacter = {...character};
		updatedCharacter.configuration.builder[option] = value;
		setCharacter(updatedCharacter);
		saveCharacter(updatedCharacter)
	}

	function saveBuilder(updatedBuilder) {
		// checkForUndefined(updatedBuilder, updatedBuilder);
		var newBuilder = {builder: updatedBuilder}
		props.firebase
		.character(uid)
		.update(newBuilder)
		.then( function() {
		});
	}

	function saveCharacter(updatedCharacter) {

		props.firebase
		.character(uid)
		.set(updatedCharacter)
		.then( function() {
		});
	}
    
	function displayStep(){
		if (builder) {
            switch(step.no) {
                case 1: 
                return (
                    <Homeland homelands={homelands} disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder}></Homeland>
                )
                case 2: 
                return (
                    <FamilyOccupation homelands={homelands} disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder}></FamilyOccupation>
                )
                case 3: 
                return (
                    <History disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder}></History>
                )
                case 4: 
                return (
                	<Runes disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder} setRuneState={setRuneState} advance={() => incrementStep(true, user)}></Runes>
                )
                case 5: 
                return (
                	<Characteristics disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder} options={character.configuration.builder} setOptions={setOptions} diceRoller={props.diceRoller}></Characteristics>
                )
                case 6: 
                return (
                	<HomelandBonuses disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder}/>
                )
                case 7: 
                return (
                	<Occupations disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} displayCharacter={{...displayCharacter}} update={updateBuilder}></Occupations>
                )
                case 8: 
                return (
                	<Cults spiritSpells={spiritSpells} runeSpells={runeSpells} disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} displayCharacter={{...displayCharacter}} update={updateBuilder}></Cults>
                )
                case 9: 
                return (
                	<PersonalBonuses disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder} displayCharacter={{...displayCharacter}}></PersonalBonuses>
                )
                case 10: 
                return (
                	<PersonalInfo disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder}></PersonalInfo>
                )
                default: 
                return (
                	<Heirloom disableNext={(disable) => {setNextDisabled(disable)}} builder={{...builder}} update={updateBuilder} weapons={[...displayCharacter.builder.weapons]}></Heirloom>
                )
            }
		}
	}

	function incrementStep(next, authUser) {
		setRuneState("")
		setUser(authUser)
		saveBuilder({...builder});
		setNextDisabled(true);
		var updatedStep = {...step};
		var incrementor = next ? 1 : -1;

		updatedStep.no += incrementor;
		var tempCharacter = createDisplayCharacter(updatedStep.no)
		switch (updatedStep.no) {
			case 1: 
				updatedStep.name = "Select Homeland";
				break;
			case 2: 
				updatedStep.name = "Identify Family Members";
				break;
			case 3: 
				updatedStep.name = "Determine Family History";
				break;
			case 4: 
				updatedStep.name = "Select Rune Affinities";
				break;
			case 5: 
				updatedStep.name = "Roll Characteristics";
				break;
			case 6: 
				updatedStep.name = "Select Cultural Skill Bonuses";
				break;
			case 7: 
				updatedStep.name = "Choose Occupation";
				break;
			case 8: 
				updatedStep.name = "Select Cult";
				break;
			case 9: 
				updatedStep.name = "Choose Personal Skill Bonuses";
				break;
			case 10: 
				updatedStep.name = "Add Other Information";
				break;
			case 11: 
				updatedStep.name = "Family Heirloom";
				break;
			case 12: 
				createCharacter(authUser, tempCharacter);
				break;
		}
		setStep(updatedStep);
	}

	function createDisplayCharacter(stepNo) {
		var tempCharacter = {builder: {}};
		tempCharacter.builder.skills = [];
		tempCharacter.builder.hitPoints = {...builder.hitPoints}
		//deep clone necessary here
		if (builder && builder.skills) {
			builder.skills.forEach(skill => {
				var clonedSkill = {...skill}
				tempCharacter.builder.skills.push(clonedSkill);
			})
		}
		if (stepNo >= 2) {
			var passions = [];
			builder.homeland.passions.forEach(passion => {
				passions.push({...passion});
			});
			tempCharacter.builder.passions = passions;
			tempCharacter.builder.homeland = {...builder.homeland};
			tempCharacter.builder.tribe = builder.tribe ? {...builder.tribe} : {}; 
		}
		if (stepNo >= 3) {
			tempCharacter.builder.grandparent = {...builder.grandparent};
			tempCharacter.builder.parent = {...builder.parent};
		}
		if (stepNo >= 4) {
			tempCharacter.builder.history = {...builder.history};
			tempCharacter.builder.reputation = builder.reputation || 0;
			tempCharacter.builder.wealth = {gold: 0, silver: builder.wealth || 0, copper: 0};
            if (tempCharacter.builder.history.passions) {
                tempCharacter.builder.passions = accumulatePassions(tempCharacter.builder.passions, [...tempCharacter.builder.history.passions]);
            }
			if (tempCharacter.builder.history.skills) {
				tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, [...tempCharacter.builder.history.skills])
			}
		}
		if (stepNo >= 5) {
			tempCharacter.builder.runes = {...builder.runes}
		}
		if (stepNo >= 6) {
			tempCharacter.builder.stats = {...builder.stats}
			var characteristics = {};
			tempCharacter.builder.stats.details.forEach(stat => {
				characteristics[stat.name] = {...stat};
				characteristics[stat.name].statBonus = (characteristics[stat.name].currentValue - characteristics[stat.name].baseValue) + (characteristics[stat.name].bonus || 0)
			})
			tempCharacter.builder.characteristics = characteristics;
			tempCharacter.builder = calculateDexBasedSkills(tempCharacter.builder);
			tempCharacter.builder = calculateCategoryModifiers(tempCharacter.builder);
		}
		if (stepNo >= 7) {
            tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, [...tempCharacter.builder.homeland.skills])
            tempCharacter.builder.weapons = accumulateWeapons(tempCharacter.builder.weapons, [...tempCharacter.builder.homeland.weapons])
        }
		if (stepNo >= 8) {
			tempCharacter.builder.occupation = {...builder.occupation}
			tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, [...tempCharacter.builder.occupation.skills])
			if (tempCharacter.builder.occupation.weapons) {
				tempCharacter.builder.weapons = accumulateWeapons(tempCharacter.builder.weapons, [...tempCharacter.builder.occupation.weapons])
			}
			var wealth = 0; 
			var occWealth = tempCharacter.builder.occupation.wealth;
			if (occWealth.dieType > 0) {
				for (var i = 0; i < occWealth.quantity; i++) {
					var result = Math.ceil(Math.random()*(occWealth.dieType));
					wealth += result;
				}
			}
			if (builder.occupation.favouredPassion && builder.occupation.favouredPassion > -1) {
				var favouredPassion = {...builder.occupation.passions[builder.occupation.favouredPassion]};
				favouredPassion.value = 60;
				tempCharacter.builder.passions = accumulatePassions(tempCharacter.builder.passions, [favouredPassion]);
			}
			wealth += occWealth.modifier;
			tempCharacter.builder.wealth.silver += wealth;
		}
		if (stepNo >= 9) {
			var cultSkills = [...builder.cult.skills];
			var cultWeapons = [];
			if (builder.cult.weapons && builder.cult.weapons.length > 0) { cultWeapons = [...builder.cult.weapons];}
			var primary = {
				options: [
					{
						name: builder.cult.primarySkill.name,
						parentId: builder.cult.primarySkill.parentId
					}
				],
				bonus: 20
			};
			if (builder.cult.primarySkill.variant) {
				primary.options[0].variant = builder.cult.primarySkill.variant;
			}
			var secondary = {
				options: [
					{
						name: builder.cult.secondarySkill.name,
						parentId: builder.cult.secondarySkill.parentId
					}
				],
				bonus: 15
			}
			if (builder.cult.secondarySkill.variant) {
				secondary.options[0].variant = builder.cult.secondarySkill.variant;
			}
			var weaponIndex = weaponData.findIndex(obj => obj.id === builder.cult.primarySkill.parentId)
			if (weaponIndex > -1) {
				primary.options[0].baseValue = weaponData[weaponIndex].baseValue;
				cultWeapons.push(primary)
			}
			else {
				cultSkills.push(primary)
			}
			weaponIndex = weaponData.findIndex(obj => obj.id === builder.cult.secondarySkill.parentId)
			if (weaponIndex > -1) {
				secondary.options[0].baseValue = weaponData[weaponIndex].baseValue;
				cultWeapons.push(secondary)
			}
			else {
				cultSkills.push(secondary)
			}
			if (builder.cult.selectedGifts) {
				builder.cult.selectedGifts.forEach(gift => {
					if (gift.type === "skill") {
						cultSkills.push(gift);
					}
					else if (gift.type === "weapon") {
						cultWeapons.push(gift)
					}
					else if (gift.type === "characteristic" ) {
						tempCharacter.builder.characteristics[gift.selected.name].baseValue ++;
						tempCharacter.builder.characteristics[gift.selected.name].currentValue ++;
						tempCharacter.builder = calculateDexBasedSkills(tempCharacter.builder);
						tempCharacter.builder = calculateCategoryModifiers(tempCharacter.builder);
			
					}
				})
			}
			if (builder.occupation.name === "Priest") {
				var tertiary = {
					options: [
						{
							name: builder.cult.tertiarySkill.name,
							parentId: builder.cult.tertiarySkill.parentId
						}
					],
					bonus: 10
				}
				if (builder.cult.tertiarySkill.variant) {
					tertiary.options[0].variant = builder.cult.tertiarySkill.variant;
				}
				weaponIndex = weaponData.findIndex(obj => obj.id === builder.cult.tertiarySkill.parentId)
				if (weaponIndex > -1) {
					tertiary.options[0].baseValue = weaponData[weaponIndex].baseValue;
					cultWeapons.push(tertiary)
				}
				else {
					cultSkills.push(tertiary)
				}
			}
			builder.cult.freeSkills.forEach(freeSkill => {
				cultSkills.push(freeSkill);
			})

			tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, cultSkills);
			tempCharacter.builder.weapons = accumulateWeapons(tempCharacter.builder.weapons, cultWeapons);

			tempCharacter.builder.passions.forEach(passion => {
				if (passion.focus === "deity") {
					passion.focus = getCult(builder.cult.name);
				}
			})
			if (builder.cult.favouredPassion && builder.cult.favouredPassion > -1) {
				var favouredPassion = {...builder.cult.passions[builder.cult.favouredPassion]};
				favouredPassion.value = 60;
				tempCharacter.builder.passions = accumulatePassions(tempCharacter.builder.passions, [favouredPassion]);
			}
			tempCharacter.builder.cult = {...builder.cult}
			if (builder.personalBonuses){
				tempCharacter.builder.personalBonuses = {...builder.personalBonuses}
			}
		}
		if (stepNo >= 10) {
			tempCharacter.builder.personalBonuses = {...builder.personalBonuses}
			var skills = [];
			var weapons = [];
			tempCharacter.builder.personalBonuses.selected.forEach(item => {
				if (item.type === "weapon") {
					weapons.push(item);
				}
				else {
					skills.push(item);
				}
			})
			tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, skills, true)
			tempCharacter.builder.weapons = accumulateWeapons(tempCharacter.builder.weapons, weapons, true);
		}
		if (stepNo >= 12) {
			tempCharacter.builder.heirlooms = [...builder.heirlooms]
			var skills = [];
			var weapons = [];
			var passions = [];
			var spiritMatrices = [];
			var runeMatrices = [];
			var crystals = [];

			tempCharacter.builder.heirlooms.forEach(heirloom => {
				var specificValue = "";
				if (heirloom.specify) {
					specificValue = heirloom.specify.charAt(0).toUpperCase() + heirloom.specify.slice(1);
				}
				if (heirloom.description.indexOf("<specify>") > -1) {
					heirloom.description = heirloom.description.replace("<specify>", specificValue);
				}
				heirloom.benefits.forEach(benefit => {
					if (heirloom.specify && benefit.selected) {
						benefit.selected.name = benefit.selected.name.replace("<specify>", specificValue);
					}
					var item = null;
					if (benefit.options) {
						item = {...benefit.options[benefit.selection], type: benefit.type};
					}
					else {
						item = benefit;
					}
					if (item.type === "skill") {
						if (item.variant === "deity") {
							item.variant = getCult(builder.cult.name);
						}
						skills.push({
							bonus: item.gain,
							options: [item]
						})
					}
					else if (benefit.type === "passion") { 
						if (benefit.selected.focus === "deity") {
							benefit.selected.focus = getCult(builder.cult.name);
						}
						passions.push({...benefit.selected, value: 60})
					}
					else if (item.type === "reputation") {
						tempCharacter.builder.reputation += item.gain;
					}
					else if (benefit.type === "hitPoints") {
						var index = tempCharacter.builder.weapons.findIndex(item => item.name === benefit.selected.name)
						tempCharacter.builder.weapons[index].hitPoints = benefit.gain;
					}
					else if (item.type === "storage") { 
						crystals.push({
							id: uuidv4(),
							POW: benefit.POW,
							location: "",
							type: "POW storage crystal"
						});				
					}
					else if (benefit.type === "Spirit spell matrix") {
						var item = {
							id: uuidv4(),
							type: benefit.type, 
							location: benefit.location || "",
							notes: benefit.notes || "",
							spell: {...benefit.selected, id: uuidv4()}
						}
						spiritMatrices.push(item)
					}
					else if (item.type === "Rune spell matrix") { 
						var item = {
							id: uuidv4(),
							type: benefit.type, 
							location: benefit.location || "",
							active: true,
							notes: benefit.notes || "",
							spell: {...benefit.selected, id: uuidv4()}
						}
						runeMatrices.push(item)
					}
				})
			});
			tempCharacter.builder.passions = accumulatePassions(tempCharacter.builder.passions, passions)
			tempCharacter.builder.skills = accumulateSkills(tempCharacter.builder.skills, skills)
			tempCharacter.builder.weapons = accumulateWeapons(tempCharacter.builder.weapons, weapons);
			tempCharacter.builder.spiritMatrices = spiritMatrices;
			tempCharacter.builder.runeMatrices = runeMatrices;
			tempCharacter.builder.crystals = crystals;
		}
		setDisplayCharacter(tempCharacter);
		return tempCharacter;
    }

	function getCult(cult) {
		var value = cult;
		if (value === "Orlanth Adventurous" || value === "Orlanth Thunderous") {
			value = "Orlanth";
		}
		return value;
	}

	function calculateCategoryModifiers(builder) {
		var modifiers = getCategoryModifiers(builder.characteristics);
		builder.categoryModifiers = modifiers;
		return builder
	}

	function calculateDexBasedSkills(builder) {
		var dexValue = builder.characteristics.DEX.currentValue;
		var index = builder.skills.findIndex(skill => skill.name == "Dodge");
		if (index > -1) {
			builder.skills[index].baseValue = builder.skills[index].value = dexValue * 2; 
		}
		index = builder.skills.findIndex(skill => skill.name == "Jump");
		if (index > -1) {
			builder.skills[index].baseValue = builder.skills[index].value = dexValue * 3; 
		}
		return builder;
	}

	function accumulatePassions(currentPassions, newPassions) {
		var updatedPassionTotals = [...currentPassions];
		if (newPassions && newPassions.length > 0) {
			newPassions.forEach(newPassion => {
				var index = -1; 
				if (newPassion.focus) {
					index = updatedPassionTotals.findIndex(passion => (passion.name.toLowerCase() === newPassion.name.toLowerCase()) && (passion.focus.toLowerCase() === newPassion.focus.toLowerCase()));
				}
				else {
					index = updatedPassionTotals.findIndex(passion => passion.name === newPassion.name);
				}
				if (index > -1) {
					updatedPassionTotals[index].value += newPassion.value -50;
				}
				else {
					updatedPassionTotals.push({
						name: newPassion.name,
						focus: newPassion.focus || "",
						value: newPassion.value,
						parentId: newPassion.parentId
					})
				}
			});
		}
		return updatedPassionTotals;
	}

	function calculateTotal(existingSkill, skill, personalBonuses) {
		var categoryModifier = 0;
		if (displayCharacter.builder.categoryModifiers) {
			var category = existingSkill.skillCategory ? existingSkill.skillCategory : "Manipulation";
			categoryModifier = displayCharacter.builder.categoryModifiers[category]
		}
		var total = (existingSkill.value || 0) + (skill.bonus || skill.value || skill.gain || 0);
		if (personalBonuses && (total + categoryModifier > 100)) {
			if (existingSkill.value + categoryModifier > 100) {
				total = existingSkill.value;
			}
			else {
				total = 100 - categoryModifier;
			}
		}
		return total;
	}

	function accumulateSkills(currentSkills, newSkills, personalBonuses) {
		var updatedSkillTotals = [];
		currentSkills.forEach(skill => {
			var updatedSkill = {...skill};
			updatedSkillTotals.push(updatedSkill);
		})
		if (newSkills && newSkills.length > 0) {
			newSkills.forEach(skill => {
				var option; 
				if (skill.selected) {
					option = skill.selected;
				}
				else {
					option = skill.options[0];
				}
				var index = updatedSkillTotals.findIndex(updatedSkill => updatedSkill.name === option.name);
				if (option.variant) {
					var newIndex = updatedSkillTotals.findIndex(updatedSkill => (updatedSkill.name === option.name) && (updatedSkill.variant === option.variant));
					if (newIndex > -1) {index = newIndex;}
				}
				if (index > -1) {
					var updatedSkill = updatedSkillTotals[index];
					if (option.variant) {
						if (updatedSkill.variant == "") {
							updatedSkill.value += (skill.bonus || skill.value|| skill.gain || skill.baseValue);
							updatedSkill.variant = option.variant;
						}
						else if (updatedSkill.variant === option.variant) {
							var total = calculateTotal(updatedSkillTotals[index], skill, personalBonuses);
							updatedSkill.value = total;
						}
						else {
							var newSkill = {...updatedSkill}
							newSkill.variant = option.variant;
							newSkill.value = skill.bonus || skill.value || skill.gain || skill.baseValue;
							updatedSkillTotals.push(newSkill);						
						}
					}
					else {
						var total = calculateTotal(updatedSkillTotals[index], skill, personalBonuses);
						updatedSkillTotals[index].value = total;
					}
				}
				else {
					option.baseValue = 0;
					option.value = skill.value || skill.bonus || skill.gain;
					updatedSkillTotals.push(option);
				}
			});
		}
		return updatedSkillTotals;
	}

	function accumulateWeapons(currentWeapons, newWeapons, personalBonuses) {
		var updatedWeaponTotals = [];
		if (currentWeapons && currentWeapons.length > 0) {
			updatedWeaponTotals = [...currentWeapons];
		}
		if (newWeapons && newWeapons.length > 0) {
			newWeapons.forEach(newWeapon => {
				var selectedWeapon;
				if (newWeapon.selected && newWeapon.selected.name) {
					selectedWeapon = newWeapon.selected;
				}
				else {
					selectedWeapon = newWeapon.options[0];				
				}
				var index = updatedWeaponTotals.findIndex(weapon => weapon.name === selectedWeapon.name);
				if (index > -1) {
					if (selectedWeapon.fixed) {
						updatedWeaponTotals[index].value = newWeapon.gain - displayCharacter.builder.categoryModifiers.Manipulation;
					}
					else {
						var total = calculateTotal(updatedWeaponTotals[index], newWeapon, personalBonuses);
						updatedWeaponTotals[index].value = total;
					}
					if(! updatedWeaponTotals[index].baseValue) {updatedWeaponTotals[index].baseValue = selectedWeapon.baseValue}
				}
				else {
					var index = weaponData.findIndex(obj => obj.id === selectedWeapon.parentId);
					if (index > -1) {
						selectedWeapon.baseValue = weaponData[index].baseValue;
					}
					if (selectedWeapon.fixed) {
						selectedWeapon.value = newWeapon.gain - displayCharacter.builder.categoryModifiers.Manipulation;
					}
					else {
						selectedWeapon.value = selectedWeapon.baseValue || 0;
						selectedWeapon.value += (newWeapon.bonus || newWeapon.gain);
					}
					updatedWeaponTotals.push(selectedWeapon)
				}
			});
		}
		return updatedWeaponTotals;
	}

	function displayInfo (element) {
		setPanelVisibility("showPanel")
		setPanelItem(element)
		setPanelOpen(true);
	}

    const closePanel = () => {
        setPanelVisibility("hidePanel");
        setPanelItem(null)
		setPanelOpen(false);
    }

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
	  content: () => componentRef.current,
	});

	if (builder) {
		return(
			<div id="characterPage" style={{width: "100%", minHeight: "100vh"}}>
				<AuthUserContext.Consumer>
					{authUser => (
						<>
							<div style={{opacity: building ? 0.6 : 1, height: "100vh"}}>
								<div style={{color:"#001e23", width:"50px", height: "200px", borderRadius:"10px", position:"fixed", top:"200px", left:"0px", zIndex: 199}}>
									<div className={globalStyles.floatingButton} onClick={() => setOpen(true)} style={{width:"50px", cursor:"pointer", height:"50px", borderRadius:"30px", backgroundColor:"#ffffff", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
										<img style={{width:"40px"}} src={ViewIcon} title="View current build" />
									</div>
								</div>
								<div style={{display: "flex", backgroundColor: "#2F7589", justifyContent: "center", height:"100%", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
									<div style={{width: "100vw", maxWidth: "1200px", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", flexDirection: "column", marginTop: "50px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
									<div style={{display:"flex", justifyContent:"space-between", alignItems:"center", height: "50px", color:"#121312", position:"relative", marginTop: "10px"}}>
										<Button type="submit" disabled={prevDisabled} onClick={() => incrementStep(false, authUser)} variant="contained" color="primary" className={globalStyles.buttonTile} style={{marginLeft: "10px", color: "#fff", fontSize: "20px", fontWeight: "bold", height: "50px", width: "100px", borderRadius: "10px"}}>Prev</Button>
										<div style={{fontSize: "24px", height: "50px", display:"flex", alignItems:"center", maxWidth:"800px", textAlign: "center"}}>
											<div style={{marginRight: "20px"}}>Step {step.no} - {step.name}</div>
											<HelpButton display={displayInfo} step={step.no} open={panelOpen} cultureRune={cultureRune} runeState={runeState} homeland={builder.homeland} />
											{/* <div style={{backgroundColor: "#fff", borderRadius: "30px", border: "2px solid #851113", fontSize: "30px", color: "#851113", width: "40px", height: "40px", display:"flex", cursor: "pointer", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>?</div> */}
										</div>
										{/* <Button onClick={() => {}} variant="contained" className={globalStyles.buttonTile} style={{backgroundColor: "red", marginRight: "10px", color: "#fff", fontSize: "20px", fontWeight: "bold", height: "50px", width: "100px", borderRadius: "10px"}}>Help</Button> */}
										<Button type="submit" disabled={nextDisabled} onClick={() => incrementStep(true, authUser)} variant="contained" color="primary" className={globalStyles.buttonTile} style={{marginRight: "10px", color: "#fff", fontSize: "20px", fontWeight: "bold", height: "50px", width: "100px", borderRadius: "10px"}}>{step.no >= 11 ? "Finish" : "Next"}</Button>
									</div>
										<div className={globalStyles.horizontalLine}></div>
									<div style={{overflow: "auto"}}>
											<div style={{display: "flex", alignItems: "flex-start", justifyContent: "center", padding: "0px 20px 10px", minHeight: "calc(100vh - 142px"}}>
												{displayStep()}
											</div>
										</div>
									</div>
								</div>
							</div>
							{building ? 
								<div style={{position: "fixed", top: "50%", left: "50%", marginLeft: "-200px", marginTop: "-150px", borderRadius: "10px", width: "400px", height: "300px", paddingTop: "20px"}}>
									<div className={styles.rotate}>
										<img src={AirRune} style={{width: "120px"}} />
									</div>
									<div style={{fontSize: "36px", textAlign: "center", width: "100%", marginTop: "20px", color: "#2f588a", fontWeight: "bold"}}>{buildingMessage}</div>
								</div>
							: 
							null}
                            <div className={globalStyles.contextBar} style={{right: panelPosition, top: "50vh"}}>
                                <div id="contextPanel" className={globalStyles[panelVisibility]} style={{width: "360px", borderRadius: "5px", border: "2px solid #2f588a", fontSize: "15px", height: panelHeight + "px", left: "auto", marginTop: panelMargin + "px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-panel-background.png)"}}>
                                    {panelItem ?
                                            <DetailsPanel panelItem={{item: {name: "Step" + step.no + " - " + step.name}, type: "dicepool"}} close={closePanel} panelHeight={panelHeight}>
												{panelItem}
											</DetailsPanel>
                                    : 
                                    null
                                    }
                                </div>
                            </div>
						</>
					)}
				</AuthUserContext.Consumer>
				<DialogBox isOpen={open} handleClose={() => setOpen(false)} width="1200px" size="lg" title="Current Build" closeButton>
					<BuilderDialog ref={componentRef} handlePrint={handlePrint} character={displayCharacter} close={() => setOpen(false)} ></BuilderDialog>
				</DialogBox>
			</div>
		);
	}
	else return null;

	function createCharacter(authUser, tempCharacter) {
		var updatedCharacter = {...character};
		var characteristics = {};
		builder.stats.details.forEach(stat => {
			var char = displayCharacter.builder.characteristics[stat.name];
			var totalValue = char.currentValue + (stat.bonus || 0);
			characteristics[stat.name] = {name: stat.name, fullName: stat.fullName, description: stat.description, baseValue: totalValue, };
		})
		updatedCharacter.characteristics = characteristics;
		updatedCharacter.conditions = [];
		updatedCharacter.campaignId = uid;
		var newSpells = [];
		builder.cult.commonRuneSpells.forEach(spell => {
			var index = runeSpells.findIndex(obj => obj.id == spell.parentId);
			var spellData = runeSpells[index]
			var newSpell = {id: uuidv4()};
			newSpell.points = spellData.points;
			newSpell.parentId = spell.parentId;
			newSpell.name = spell.name;
			newSpell.common = true;
			newSpell.target = spellData.target || {};
			newSpell.stackable = spellData.stackable || false;
			newSpell.runes = [...builder.cult.runes]
			newSpells.push(newSpell)
		})
		builder.cult.selectedRuneSpells.forEach(spell => {
			var index = runeSpells.findIndex(obj => obj.id == spell.parentId);
			var spellData = runeSpells[index]
			var newSpell = {id: uuidv4()};
			newSpell.points = spell.points;
			newSpell.parentId = spell.parentId;
			newSpell.name = spell.name;
			newSpell.target = spellData.target || {};
			newSpell.stackable = spellData.stackable || false;
			newSpell.runes = [...spellData.runes]
			if (spell.variant) {
				newSpell.variant = spell.variant;
			}
			if (newSpell.runes[0] === "Magic") {
				newSpell.runes = [...builder.cult.runes]
			}
			newSpells.push(newSpell)
		})
		var runePointTotal = 3 + (builder.runePoints || 0)
		var cult = {
			id: uuidv4(),
			name: builder.cult.name,
			pantheon: builder.cult.pantheon,
			parentId: builder.cult.id,
			rank: "Initiate",
			runePoints: [{entityId: uid, type: "Character", current: runePointTotal, total: runePointTotal}],
			runeSpells: newSpells,
			runes: builder.cult.runes
		}
		if (builder.cult.selectedGifts) {
			cult.gifts = [];
			builder.cult.selectedGifts.forEach(gift => {
				cult.gifts.push(gift.description + (gift.selected ? " (" + gift.selected.name + ")": ""));
			})
		}
		if (builder.cult.selectedGeases) {
			cult.geases = [];
			builder.cult.selectedGeases.forEach(geas => {
				cult.geases.push(geas.description);
			})
		}
		updatedCharacter.cults = [cult];

		updatedCharacter.personalInfo = {
			age: builder.personalInfo.age, 
			family: builder.personalInfo.family,
			gender: builder.personalInfo.gender,
			handedness: builder.personalInfo.handedness,
			scars: 0,
			birthdate: {
				day: builder.personalInfo.day,
				week: builder.personalInfo.week,
				season: builder.personalInfo.season
			}
		}

		updatedCharacter.background = {
			homeland: builder.homeland.name,
			culture: builder.homeland.title,
			tribe: (builder.tribe && builder.tribe.name) || builder.personalInfo.tribeName || "",
			clan: builder.personalInfo.clanName || "",
			reputation: (builder.history.reputation || [])
		}
		updatedCharacter.history = {...builder.history}
			
		updatedCharacter.strikeRanks = calculateStrikeRanks(characteristics);
		updatedCharacter.damageBonus = calculateDamageBonus(characteristics);
		updatedCharacter.hitPoints = calculateHitPoints(characteristics, character.hitPoints);
		updatedCharacter.armour = [...builder.occupation.armour]
		updatedCharacter.armour.forEach(item => {
			item.id = uuidv4(),
			item.equipped = true;
		})
		updatedCharacter = applyArmour(updatedCharacter);

		updatedCharacter.id = uid;
		var magicPoints = calculateMagicPoints(characteristics);
		updatedCharacter.magicPoints = [{
			current: magicPoints, 
			total: magicPoints,
			entityId: uid,
			type: "Character"
		}]
		updatedCharacter.maxEnc = calculateMaxEncumbrance(characteristics);
		updatedCharacter.move = 8;
		updatedCharacter.name = {
			first: builder.personalInfo.firstName,
			last: builder.personalInfo.lastName
		}

		var occupationalSkills = []
		builder.occupation.skills.forEach(skill => {
			if (skill.selected) {
				occupationalSkills.push({id: uuidv4(), name: skill.selected.name, parentId: skill.selected.parentId})
			}
			else {
				var occupationalSkill = {id: uuidv4(), name: skill.options[0].name, parentId: skill.options[0].parentId};
				if (skill.options[0].variant) occupationalSkill.variant = skill.options[0].variant;
				occupationalSkills.push(occupationalSkill);
			}
		})
		var occupationalWeapons = []
		var selectedWeapon;
		builder.occupation.unitWeapons && builder.occupation.unitWeapons.forEach(unitWeapon => {
			if (unitWeapon.title === builder.occupation.selectedUnitWeapons) {

				unitWeapon.weapons.forEach(weapon => {
					if (weapon.selected && weapon.selected.name) {
						selectedWeapon = {id: uuidv4(), name: weapon.selected.name, parentId: weapon.selected.parentId};
					}
					else {
						selectedWeapon = {id: uuidv4(), name: weapon.options[0].name, parentId: weapon.options[0].parentId};
					}
					var index = occupationalWeapons.findIndex(existingWeapon => existingWeapon.name === selectedWeapon.name);
					if (index < 0) {
						occupationalWeapons.push(selectedWeapon);
					}
				})
			}
		})

		builder.occupation.weapons && builder.occupation.weapons.forEach(weapon => {
			if (weapon.selected && weapon.selected.name) {
				selectedWeapon = {id: uuidv4(), name: weapon.selected.name, parentId: weapon.selected.parentId}
			}
			else {
				selectedWeapon = {id: uuidv4(), name: weapon.options[0].name, parentId: weapon.options[0].parentId};
			}
			var index = occupationalWeapons.findIndex(existingWeapon => existingWeapon.name === selectedWeapon.name);
			if (index < 0) {
				occupationalWeapons.push(selectedWeapon);
			}
		})

		updatedCharacter.occupation = {
			name: builder.occupation.name,
			skills: occupationalSkills,
			reputation: builder.occupation.reputation || 0,
			weaponSkills: occupationalWeapons
		}
		if (updatedCharacter.occupation.name === "Priest" && builder.personalInfo.gender === "Female") {
			updatedCharacter.occupation.name = "Priestess";
		}

		var charPassions = [];
		tempCharacter.builder.passions.forEach(passion => {
			var updatedPassion = {...passion};
			updatedPassion.id = uuidv4();
			if (updatedPassion.focus) {
				if (updatedPassion.focus.toLowerCase() === "deity") {
					updatedPassion.focus = getCult(builder.cult.name);
				}
				if (updatedPassion.focus.toLowerCase() === "tribe" && updatedCharacter.background.tribe) {
					updatedPassion.focus = updatedCharacter.background.tribe;
				}
				if (updatedPassion.focus.toLowerCase() === "clan" && updatedCharacter.background.clan) {
					updatedPassion.focus = updatedCharacter.background.clan;
				}
				updatedPassion.variant = updatedPassion.focus;
				charPassions.push(
					updatedPassion
				);
			}
		});

		updatedCharacter.passions = charPassions;
		updatedCharacter.profileImage = builder.personalInfo.image;
		updatedCharacter.ransom = builder.occupation.ransom;
		updatedCharacter.reputation = tempCharacter.builder.reputation;
		updatedCharacter.reputation += builder.occupation.reputation;
		updatedCharacter.equipmentList = builder.occupation.equipmentList || "";
		builder.heirlooms.forEach(heirloom => {
			if (heirloom.benefits[0].type === "item") {
				updatedCharacter.equipmentList += ("<br/><br/>" + heirloom.description);
			}
		})
		
		var runes = [];
		builder.runes.elementalRunes.forEach(rune => {
			runes.push(
				{id: uuidv4(), 
				name: rune.name, 
				parentId: rune.id, 
				type: "Elemental", 
				value: rune.value, 
			});
		})
		builder.runes.powerRunes.forEach(rune => {
			runes.push({id: uuidv4(), name: rune.first.name, parentId: rune.first.id, type: rune.first.category, value: rune.first.value});
			runes.push({id: uuidv4(), name: rune.second.name, parentId: rune.second.id, type: rune.second.category, value: rune.second.value});
		})
		updatedCharacter.runes = runes;

		var modifiers = getCategoryModifiers(characteristics);
		updatedCharacter.categoryModifiers = modifiers;

        var allSkills = getAllSkills(campaign);
		var skills = [];
		tempCharacter.builder.skills.forEach(skill => {
            var index = allSkills.findIndex(item => item.id === skill.parentId);
            var skillDetails = {...allSkills[index]}
			var newSkill = {
				id: uuidv4(), 
				baseValue: skill.baseValue,
				canLearn: skillDetails.canLearn || false,
				skillCategory: skillDetails.skillCategory || "",
				hasSucceeded: false,
				name: skill.name, 
				parentId: skill.parentId, 
				value: skill.value || skill.baseValue
			};
			if (skill.variant) {
				newSkill.variant = skill.variant;
			}
			skills.push(newSkill);
		});
		updatedCharacter.skills = skills;

		updatedCharacter.spiritCombat = {title: "Spirit Combat", damage: calculateSpiritCombatDamage(characteristics), armour: 0}
		updatedCharacter.spiritCombat.damage.bonus = 0;
		updatedCharacter.wealth = {gold: 0, silver: builder.wealth || 0, copper: 0};
		if (builder.occupation.scars) {
			var result = Math.ceil(Math.random() * builder.occupation.scars.dieType);
			if (builder.scars) {
				builder.scars += result;
			}
			else {builder.scars = result}
		}
		updatedCharacter.scars = builder.scars || 0;


		var newSpells = []
		builder.cult.selectedSpiritSpells.forEach(spell => {
			var index = spiritSpells.findIndex(obj => obj.id == spell.parentId);
			if (index > -1) {
				var spellData = spiritSpells[index];
				var newSpell = {id: uuidv4()};
				newSpell.points = spell.points;
				newSpell.parentId = spell.parentId;
				newSpell.name = spell.name;
				newSpell.target = spellData.target || {};
				newSpell.variable = spellData.variable || false;
				if (spellData.variant) {
					newSpell.variant = spellData.variant;
				}
				newSpells.push(newSpell)
			}
		})
		if (builder.occupation.freeSpiritSpells) {
			builder.occupation.freeSpiritSpells.forEach(spell => {
				var index = spiritSpells.findIndex(obj => obj.id == spell.parentId);
				if (index > -1) {
					var spellData = spiritSpells[index];
					var newSpell = {id: uuidv4()};
					newSpell.points = spellData.points;
					newSpell.parentId = spell.parentId;
					newSpell.name = spellData.name;
					newSpell.target = spellData.target || {};
					if (spellData.variant) {
						newSpell.variant = spellData.variant;
					}
					newSpells.push(newSpell)
			}
			})
		}
		updatedCharacter.spiritSpells = newSpells;
		
		updatedCharacter.uid = user.uid;
		var weaponSkills = [];
		var equippedweapons = [];
		tempCharacter.builder.weapons.forEach(knownWeapon => {
			var index = weaponData.findIndex(obj => obj.id === knownWeapon.parentId);
			weaponSkills.push({
				id: uuidv4(), 
				name: weaponData[index].name,
				parentId: weaponData[index].id, 
				category: weaponData[index].category, 
				hasSucceeded: false,
				value: (knownWeapon.value + modifiers.Manipulation) >  100 ? (100 - modifiers.Manipulation) : knownWeapon.value, 
				skillCategory: "Manipulation"
			});
			var weaponDetails = weaponData[index]
			var equippedWeapon = {id: uuidv4()};
			equippedWeapon.equipped = true;
			equippedWeapon.parentId = knownWeapon.parentId
			equippedWeapon.ENC = weaponDetails.ENC
			equippedWeapon.baseValue = weaponDetails.baseValue
			equippedWeapon.category = weaponDetails.category
			equippedWeapon.class = weaponDetails.class
			equippedWeapon.damage = {...weaponDetails.damage}
			equippedWeapon.minDEX = weaponDetails.minDEX
			equippedWeapon.minSTR = weaponDetails.minSTR
			equippedWeapon.name = knownWeapon.name
			equippedWeapon.sr = weaponDetails.sr
			equippedWeapon.rate = weaponDetails.rate
			equippedWeapon.range = weaponDetails.range
			equippedWeapon.title = weaponDetails.name
			equippedWeapon.type = weaponDetails.type
			equippedWeapon.hitPoints = {...weaponDetails.hitPoints}
			if (knownWeapon.hitPoints) {
				equippedWeapon.hitPoints.total += knownWeapon.hitPoints;
				equippedWeapon.hitPoints.current += knownWeapon.hitPoints;
			}
			equippedweapons.push(equippedWeapon)
		});
		updatedCharacter.matrices = updatedCharacter.matrices.concat(tempCharacter.builder.spiritMatrices);
		updatedCharacter.matrices = updatedCharacter.matrices.concat(tempCharacter.builder.runeMatrices);
		updatedCharacter.crystals = tempCharacter.builder.crystals;

		updatedCharacter.crystals.forEach(crystal => {
			if (crystal.type === "POW storage crystal") {
				updatedCharacter.magicPoints.push({
					current: crystal.POW,
					total: crystal.POW,
					entityId: crystal.id,
					type: crystal.type
				})
			}
		});
		updatedCharacter.weaponSkills = weaponSkills;
		updatedCharacter.weapons = equippedweapons;

		cult.runeSpells.forEach(runeSpell => {
			var index = runeSpells.findIndex(obj => obj.id == runeSpell.parentId);
			var spellData = runeSpells[index]
			if (spellData.effects) {
				spellData.effects.forEach(effect => {
					if (effect.name === "addWeapon") {
						updatedCharacter.weaponSkills = newWeaponSkill(effect, updatedCharacter);
						updatedCharacter.weapons = newWeapon(effect, updatedCharacter);
					}
				})
			}
		})

		updatedCharacter.complete = true;
		delete updatedCharacter.builder;
		saveCharacter(updatedCharacter);
		setBuilding(true);
		setBuildingMessage("Temple visited...")
		setTimeout(function() {setBuildingMessage("Sacrifices made...")
			setTimeout(function() {setBuildingMessage("Gods appeased!")
				setTimeout(function() {window.location.href = "/character/" + uid}, 2000);
			}, 2000);
		}, 2000);
		return;
	}
}

export default withEmailVerification(withAuthorization(withFirebase(Builder)));