import React, {useState, useEffect} from "react";
import { withFirebase } from "../Firebase";
import { withEmailVerification } from "../Session";
import { AuthUserContext } from '../Session';
import globalStyles from '../Styles/styles.module.css'
import * as ROLES from '../../constants/roles';
import { Button } from "@mui/material";
import CharacterCard from "./characterCard";
import DialogBox from '../Dialogs/dialogBox';
import CreateCharacterDialog from './createCharacterDialog';
import ImportCharacterDialog from "./importCharacterDialog";

const Characters = (props) => {

    const [characters, setCharacters] = useState();
    const [open, setOpen] = useState(false);
    const [openImport, setOpenImport] = useState(false);
        
    useEffect(() => {
        document.title = "RuneQuesting: Characters"
    },[]);
    
    function getCharacters(authUser){
        var characters = [];
        props.firebase
        .characters()
        .where("uid", "==", authUser.uid)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(function(doc) {
            const id = doc.id;
            const data = doc.data();
            var character = {id, ...data}
            characters.push(character);
        });
        setCharacters(characters);
        });
    }

    function showDelete(item, authUser){
        if (item.uid === authUser.uid) return true
        else return false;
      }
      
    function deleteCharacter(characterId) {
        props.firebase
        .characters()
        .doc(characterId)
        .delete()
        .then(() => {
            var updatedCharacters = [];
            characters.forEach((character) => {
                if (character.id !== characterId) {
                    updatedCharacters.push(character);
                }
            });
            setCharacters(updatedCharacters);
        });
    }
    
    function createCharacter() {
        setOpen(true);
    }

    function importCharacter() {
      setOpen(false);
      setOpenImport(true);
    }

    function displayAuthUser(authUser) {
    }
    return (
        <div style={{display: "flex", backgroundColor: "#8a612f", justifyContent: "center", width: "100%", height:"100%", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", minHeight: "100vh"}}>
            <div className={globalStyles.characterBanner}></div>
            <AuthUserContext.Consumer>
                {authUser => (
                    condition(authUser) ? 
                        characters ? 
                        <div style={{width: "1200px", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", paddingBottom: "20px", flexDirection: "column", marginTop: "50px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								<div style={{margin: "20px 30px", height: "42px"}}>
									<Button disabled={characters.length >= 6 && !authUser.roles[ROLES.ADMIN]} onClick={() => createCharacter(authUser)} style={{minWidth:"10em"}} size="large" variant="contained" color="primary">Create a character</Button>
								</div>
								<div style={{marginLeft: "40px", fontWeight: "bold", fontSize: "18px"}}>
									<div>{"You have created " + characters.length + " character" + (characters.length === 1 ? "" : "s") + " out of a possible 6"}</div>
								</div>
							</div>
							{displayAuthUser(authUser)}
							<div className={globalStyles.horizontalLine}></div>
							<div >
								<div style={{display:"flex", flexWrap:"wrap", padding:"0px 20px"}}>
								{characters.map((item) => {
									if (item.id.indexOf("newCharacter") < 0) {
									return (
										<div key={item.id} style={{margin: "0px 10px"}}>
												{item.complete ? 
												<a href={'/character/' + item.id}>
													<CharacterCard character={item} showDelete={showDelete(item, authUser)} deleteCharacter={deleteCharacter}></CharacterCard>
												</a>
												:
												<a href={'/builder/' + item.id}>
													<CharacterCard character={item} showDelete={showDelete(item, authUser)} deleteCharacter={deleteCharacter}></CharacterCard>
												</a>
												}
										</div>
									)
		
									}
									else return null;
								})}
								</div>
							</div>                
							<DialogBox isOpen={open} width="600px" title="Create a character" >
								<CreateCharacterDialog close={() => setOpen(false)} importCharacter={importCharacter} authUser={authUser} />
							</DialogBox>
							<DialogBox isOpen={openImport} width="600px" title="Import a character" >
								<ImportCharacterDialog close={() => setOpenImport(false)} authUser={authUser}  />
							</DialogBox>
                        </div>
                            :
                        getCharacters(authUser)
                    : 
                    null

                )}
            </AuthUserContext.Consumer>
        </div>
    );
}

const condition = authUser => !!authUser;

export default withFirebase(withEmailVerification(Characters));