import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import globalStyles from '../Styles/styles.module.css'
import { AuthUserContext } from '../Session';
import styles from './campaign.module.css'
import Messages from '../Messages/Messages';
import { CampaignContext } from '../Context/Campaign';
import AugmentationPanel from '../Panels/augmentationPanel';
import Storytelling from './storytelling';
import EncountersPanel from '../Encounters/encountersPanel';
import * as SAVE from '../../constants/save';
import DicePoolButton from '../DicePool/dicePoolButton';
import NotesButton from '../Buttons/notesButton';
import EditButton from '../Buttons/editButton';
import TablesButton from '../Buttons/tablesButton';
import AddUserButton from './addUserButton';
import CampaignLog from './campaignLog';
import CampaignCharacters from './campaignCharacters';
import ChapterContextPanel from './chapterContextPanel';
import { withPageState } from '../Context/PageState';
import AdventureContextPanel from './adventureContextPanel';
import Encounters from './encounters';
import EncountersContextPanel from './encountersContextPanel';
import DicePoolContextPanel from '../DicePool/dicePoolContextPanel';
import SaveIcon from '../../icons/white-save-icon.svg';
import CancelIcon from '../../icons/white-cancel-icon.svg';
import d10Icon from '../../icons/d10-white-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import {getResultType, postMessage, postDamageMessage} from '../../services/dieRollService';
import DetailsPanel from '../Controls/detailsPanel';
import ResultWindow from '../Characters/controls/resultWindow';
import CustomContent from './customContent';
import CustomCultContextPanel from './customCultContextPanel';
import CustomSpiritSpellContextPanel from './customSpiritSpellContextPanel';
import CustomRuneSpellContextPanel from './customRuneSpellContextPanel';
import CancelButton from '../Buttons/cancelButton';
import CustomSkillContextPanel from './customSkillContextPanel';

const blankDieRoll = {
    name: "",
    result: 0,
    target: 0,
    show: false,
    resultType: "",
    title:""
};

const Campaign = (props) => {

	const [campaign, setCampaign] = useState(null);
	const [chapter, setChapter] = useState(null);
	const [adventure, setAdventure] = useState(null);
	const [uid, setUid] = useState(props.uid);
    const [show, setShow] = useState("customcontent");
    const [augmenting, setAugmenting] = useState({
        state: "",
        value: 0
    });
    const [tabClass, setTabClass] = useState("moveFromBottomFade");
    const [campaignCharacters, setCampaignCharacters] = useState([]);
    const [panelItem, setPanelItem] = useState({
        type: "ability", 
        item: {
            name: "Battle", value: 60, variant: "", cult: null
        }
    })
    const [panelVisibility, setPanelVisibility] = useState("hidePanel");
    const [panelPosition, setPanelPosition] = useState(0);
    const [panelHeight, setPanelHeight] = useState(800);
    const [panelMargin, setPanelMargin] = useState(-400)
    const [saveMode, setSaveMode] = useState(false);
    const [currentEncounterId, setCurrentEncounterId] = useState("");
    const [resultRight, setResultRight] = useState(0);
    const [result, setResult] = useState(null);
    const [rolls, setRolls] = useState(null);
    const [resultClass, setResultClass] = useState("moveFromBottomFade");


    useEffect(() => {
        if (window.innerHeight < 800) {
            setPanelHeight(window.innerHeight)
            setPanelMargin((window.innerHeight * -0.5) + 50)
        }
        var width = window.innerWidth;
        if (width > 1804) {
            var result = (((window.innerWidth -1200)/2) - 300)
            setResultRight(result);
        }
        else {
            setResultRight(0);
        }
    },[window.innerWidth])

    useEffect(() => {
        var width = window.innerWidth;
        var height = window.innerHeight;
        if (width > 1928) {
            var result = (((window.innerWidth -1200)/2))
            setPanelPosition(result);
        }
        else setPanelPosition(364);
    },[window.innerWidth])

	useEffect(() => {
        var uid = props.uid;
        setUid(uid);
		props.firebase
		.campaign(uid)
		.get()
		.then((doc) => {
			if (doc.exists) {
				var campaign = doc.data();
				campaign.id = doc.id;
                setCampaign(campaign)
                document.title = "RuneQuesting: " + campaign.name
                setCurrentEncounterId(campaign.currentEncounterId)
                if (campaign.uid === props.authUser.uid) {
                    {setShow("storytelling")}
                }
                setShow("customcontent") // For testing only
                campaign.chapters.forEach(item => {
                    if (item.id === campaign.currentChapterId) {
                        setChapter({...item})
                        item.adventures.forEach(adv => {
                            if (adv.id === campaign.currentAdventureId) {
                                setAdventure({...adv})
                            }
                        })
                    }
                })
			} 
		});
	},[props.uid]);

    useEffect(() => {
        var characters = [];
        const fetchData = async () => {
            props.firebase
            .characters()
            .where('campaignId', '==', uid)
            .orderBy('name')
            .get()
            .then(function(collection) {
                collection.forEach(function(doc) {
					var char = {...doc.data()};
					char.id = doc.id;
					characters.push(char);
                });
				var enemies = {
                    name: "Party",
                    npcs: characters
                }

                setCampaignCharacters(characters);
            });
        };

        if (uid) {
            fetchData();
        }
    }, [uid]);

    function showTab(name) {
        setShow(name.toLowerCase().replace(" ", ""));
        {setTabClass(tabClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")}
    }

    function displayTab(name, imageName) {
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width: "240px", cursor: "pointer", padding: "2px"}} onClick={() => showTab(name)}>
                <div style={{borderBottom: "2px solid transparent", display: "flex"}}>
                    <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-40x40.png"} style={{width:"30px"}} /></div>
                    <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                </div>
            </div>
        )
    }

    function displaySelectedTab(name, imageName) {
        return (
            <div style={{backgroundColor: "transparent", color: "#121312", display:"flex", justifyContent:"center", alignItems:"center", width: "240px", padding: "2px"}}>
                    <div style={{borderBottom: "2px solid #2F588A", display: "flex"}}>
                        <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-40x40.png"} style={{width:"30px"}} /></div>
                        <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                    </div>
            </div>
        )
    }
    
    // function processResult(dieRoll, action, updateFields){
	// 	console.log('augmenting: ', augmenting)
    //     var updateRequired = false;
	// 	var updatedCharacter = {...panelItem?.entity}

    //     var resultType = getResultType(dieRoll.target, dieRoll.result);
    //     if (dieRoll.hidden) {
    //         resultType.style = {border: "4px solid #676767", backgroundColor: "#cdcdcd", color: "#121312", borderRadius: "5px", height: "246px"};
    //     }

    //     if (updateRequired) {
    //         updateFields([{fieldName: "enchantments", value: updatedCharacter.enchantments}], SAVE.YES)
    //     }
    // }

    function processResult(dieRoll, action, title, npc){
		var userDetails = {
			uid: props.authUser.uid,
			firstName: "GM"
		} 
		// setAugmenting({state: "",value: 0})
		postMessage(userDetails, dieRoll, props.firebase, npc, action)
		// props.processResult(dieRoll, {...action, type: abilityType, title: title})
		var resultType = getResultType(dieRoll.target, dieRoll.result);
		setResult({dieRoll: dieRoll, resultType: resultType, title: npc.name.first + " " + title, action: action, type: action.type ? action.type === "weaponSkills" ? "" : action.type.substring(0, action.type.length - 1): ""});
        setRolls(null)
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")
	}

    function processDamage(result, npc){
		var userDetails = {
			uid: props.authUser.uid,
			firstName: "GM"
		} 
        var damage = {result: result.result, rolls: result.rolls, show: true, location: result.location};
        postDamageMessage(damage, userDetails, props.firebase, npc, result.weapon.title === "Spirit Combat")
        setResult(null);
        setRolls({result: result.result, rolls: result.rolls, title: npc.name.first + " " + result.title, modifier: result.modifier});
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")
	}

    function closeResult() {
        setResultClass("hiddenResult")
    }

    const displayPanelItem = (displayDetails) => {
        if (panelItem.item && panelItem.item.id && displayDetails.item && panelItem.item.id === displayDetails.item.id) {
            closePanel();
        }
        else {
            showPanel(displayDetails)
        }
    }

    const showPanel = (details) => {
        setPanelItem(details)
        setPanelVisibility("showPanel")
}

    const closePanel = () => {
        setPanelVisibility("hidePanel");
        setPanelItem({type: "", item: {name: ""}})
    }

    const update = (updatedCampaign, persist) => {
        setCampaign(updatedCampaign);
        if (persist) {
            saveCampaign(updatedCampaign)
        }
    }

    const updateEncounter = (updatedCampaign) => {
        if (updatedCampaign.currentEncounterId !== currentEncounterId) {
            setCurrentEncounterId(updatedCampaign.currentEncounterId);
            campaignCharacters.forEach(character => {
                props.firebase
                .character(character.id)
                .update({activeEncounterId: updatedCampaign.currentEncounterId})
                .then(() => {})
            })
        }
        setCampaign(updatedCampaign);
        saveCampaign(updatedCampaign)
    }

    function saveCampaign(updatedCampaign) {
        console.log('updatedCampaign', updatedCampaign)
        updatedCampaign.modified = new Date();
        props.firebase
        .campaign(uid)
        .set(updatedCampaign)
        .then( function() {
        });
    }
    function saveNPC(updatedNPC) {
        updatedNPC.modified = new Date();
        props.firebase
        .npc(updatedNPC.id)
        .set(updatedNPC)
        .then( function() {
            setSaveMode(false);
        });
    }
 
    function changeMode() {
        var mode = props.pageState.editMode;
        props.pageState.setEditMode(!mode);
    }
  
    const saveChanges = (npc) => { 
        saveCampaign(campaign, true);
        changeMode();
    }
    
    const cancelChanges = () => { 
        closePanel();
        changeMode();
        props.firebase
        .campaign(uid)
        .get()
        .then((doc) => {
            if (doc.exists) {
                setCampaign({...doc.data(), id: doc.id});    
            }
        });
    }

    function displayResult(result, diceRolled, modifier) {
        var resultType = getResultType(-1, result);
        var title = "";
        var name = "";
        if (diceRolled.d100) {
            title = "Rolled D100";
            name = "Rolled " + result;
        }
        else if (diceRolled.d20) {
            title = "Rolled D20";
            name = "Rolled " + result;
        }
        else {
            for (var prop in diceRolled) {
                if (diceRolled.hasOwnProperty(prop)) {
                    if (prop.substring(0,3) === "d10"){
                        title += "D10+";
                    }
                    else {
                        title += prop.substring(0,2) + "+";
                    }
                    name += diceRolled[prop] + "+"
                }
            }
        }
        var dieRoll = {target: -1, result: result}
        title = title.substring(0, title.length -1);
        name = name.substring(0, name.length -1);
        if (modifier) {name += (modifier > 0 ? "+" : "") + modifier}
        resultType.name = name;
        setResult({dieRoll: dieRoll, resultType: resultType, title: title, action: {}, type: ""});
        setRolls(null)
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")

    }

    if (campaign) {
		return(
			<div id="campaignPage" style={{width: "100%", height: "100%"}}>
                    <AuthUserContext.Consumer>
                        {authUser =>
						<CampaignContext.Provider value={{campaign, setCampaign}} >
							<div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"60px", left:"0px", zIndex: "999"}}>
                                {props.pageState.editMode ? 
                                    <div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"200px", left:"0px", zIndex: "999"}}>
                                        <div className={globalStyles.floatingButton} onClick={saveChanges} style={{width:"50px", cursor:"pointer", height:"50px", borderRadius:"25px", backgroundColor:"#2F7589", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                                            <img style={{width:"30px"}} src={SaveIcon} title="Save changes" />
                                        </div>
                                        <CancelButton cancelChanges={cancelChanges} type="campaign" />
                                    </div>
                                :                    
                                <>
                                    <DicePoolButton diceRoller={props.diceRoller} authUser={authUser} name={"GM"} displayPanel={() => displayPanelItem({type: "DicePool"})} />
                                    <TablesButton />
                                    <NotesButton user={authUser} campaignId={uid} /> 
                                    <AddUserButton campaignId={uid} />
                                    {/* <DramatisPersonaeButton character={character} user={authUser} update={updateCharacter} /> */}
                                    {show === "encounters" || show === "customcontent" ?
                                        <EditButton user={authUser} onClick={() => props.pageState.setEditMode(true)} />
                                    : null
                                    }
                                </>
                                }
							</div>
                            <div style={{display: "flex", backgroundColor: "#2f588a", justifyContent: "center", alignItems: "center", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", position: "relative"}}>
                                <div style={{width: "1200px", position: "relative", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", flexDirection: "column", marginTop: "50px", minHeight: "calc(100vh - 50px", height: "100%", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
									<div style={{display:"flex", height: "262px", justifyContent: "space-between"}}>
										<div style={{width: "980px"}}>
											<div style={{display:"flex", margin: "5px"}}>
												<div style={{height: "260px"}} >
													<img src={campaign.imageUrl} style={{height: "252px", borderRadius: "5px"}} />
												</div>
												<div style={{height: "240px"}}>
													<div style={{padding:"10px", position: "relative", width: "510px", height: "240px", fontSize: "15px"}}>
														<div style={{marginBottom: "10px", fontSize: "30px"}}>{campaign.name}</div>
														<div style={{marginBottom: "10px"}}>{campaign.description}</div>
														{chapter ? 
															<div style={{marginBottom: "10px"}}>{"Current chapter: " + chapter.name}</div>
														: 
														null
														}
														{adventure ? 
															<div style={{marginBottom: "10px"}}>{"Current adventure: " + adventure.name}</div>
														: 
														null
														}
													</div>
												</div>
												{
													augmenting.state === "next" ? 
													<AugmentationPanel augmentation={augmenting.modifier} />
													: 
													null
												}
											</div>
										</div>
										<div style={{margin: "5px 0px"}}>
											<div style={{width: "328px", backgroundColor: "#cdcdcd", marginLeft: "5px", borderRadius: "5px"}}>
												<Messages campaignId={campaign.id} isGM={true}></Messages>
											</div>
										</div>
									</div>
									<div style={{display:"flex"}}>
										{
											show === "storytelling" ? 
												<div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px", borderRadius: "5px"}}>
													{displaySelectedTab("Storytelling", "illusion")}
                                                    {/* {displayTab("Custom Content", "disorder")} */}
													{displayTab("Campaign Log", "truth")}
													{displayTab("Characters", "harmony")}
													{displayTab("Encounters", "death")}
                                                    </div>
											: 
											show === "customcontent" ? 
												<div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px", borderRadius: "5px"}}>
                                                    {campaign.uid === authUser.uid ? 
                                                    <>
                                                        {displayTab("Storytelling", "illusion")}
                                                        {/* {displaySelectedTab("Custom Content", "disorder")} */}
                                                        {displayTab("Campaign Log", "truth")}
                                                        {displayTab("Characters", "harmony")}
                                                        {displayTab("Encounters", "death")}
                                                    </>
                                                    : 
                                                    <>
                                                        {displaySelectedTab("Campaign Log", "truth")}
                                                        {displayTab("Characters", "harmony")}
                                                    </>
                                                    }   
												</div>
											: 
											show === "campaignlog" ? 
												<div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px", borderRadius: "5px"}}>
                                                    {campaign.uid === authUser.uid ? 
                                                    <>
                                                        {displayTab("Storytelling", "illusion")}
                                                        {/* {displayTab("Custom Content", "disorder")} */}
                                                        {displaySelectedTab("Campaign Log", "truth")}
                                                        {displayTab("Characters", "harmony")}
                                                        {displayTab("Encounters", "death")}
                                                    </>
                                                    : 
                                                    <>
                                                        {displaySelectedTab("Campaign Log", "truth")}
                                                        {displayTab("Characters", "harmony")}
                                                    </>
                                                    }   
												</div>
											: 
											show === "characters" ? 
												<div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px"}}>
                                                    {campaign.uid === authUser.uid ? 
                                                    <>
                                                        {displayTab("Storytelling", "illusion")}
                                                        {/* {displayTab("Custom Content", "disorder")} */}
                                                        {displayTab("Campaign Log", "truth")}
                                                        {displaySelectedTab("Characters", "harmony")}
                                                        {displayTab("Encounters", "death")}
                                                    </>
                                                    : 
                                                    <>
                                                        {displayTab("Campaign Log", "truth")}
                                                        {displaySelectedTab("Characters", "harmony")}
                                                    </>
                                                    }   
												</div>
											: 
											show === "encounters" ? 
												<div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px"}}>
													{displayTab("Storytelling", "illusion")}
                                                    {/* {displayTab("Custom Content", "disorder")} */}
													{displayTab("Campaign Log", "truth")}
													{displayTab("Characters", "harmony")}
													{displaySelectedTab("Encounters", "death")}
												</div>
											: 
											null
										}
									</div>
									{
										show === "campaignlog" ? 
										<div className={globalStyles[tabClass]}>
                                            <CampaignLog campaign={campaign} update={(updatedCampaign) => update(updatedCampaign, true)} authUser={authUser} />
										</div>
										:
										show === "characters" ? 
										<div className={globalStyles[tabClass]}>
                                            <CampaignCharacters campaign={campaign} characters={campaignCharacters} authUser={authUser} />
										</div>
										:
										show === "storytelling" ? 
										<div className={globalStyles[tabClass]}>
										<Storytelling campaign={campaign} update={(updatedCampaign) => update(updatedCampaign, true)} displayItem={displayPanelItem}  />
										</div>
										:
										show === "encounters" ?
										<div >
                                            <Encounters saveMode={saveMode} saveNPC={saveNPC} campaign={campaign} update={updateEncounter} displayItem={displayPanelItem} diceRoller={props.diceRoller} authUser={authUser} processResult={processResult} processDamage={processDamage} activeEnemies={campaignCharacters} />
										</div>
										:
										show === "customcontent" ?
										<div >
                                            <CustomContent campaign={campaign} update={(updatedCampaign) => update(updatedCampaign, false)} authUser={authUser} displayItem={displayPanelItem} />
                                            </div>
										:
										null
									}

								</div>
							</div>
                            {result || rolls  ? 
                                <ResultWindow result = {result} rolls={rolls} />
                            : 
                            null}

                            <div className={globalStyles.contextBar} style={{right: panelPosition, top: "50vh"}}>
                                <div id="contextPanel" className={globalStyles[panelVisibility]} style={{width: "360px", borderRadius: "5px", border: "2px solid #fff", fontSize: "15px", height: panelHeight + "px", left: "auto", marginTop: panelMargin + "px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-panel-background.png)"}}>
                                    {panelItem && panelItem.type === "Chapter" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Edit Chapters"}, type: "chapters"}} panelHeight={panelHeight} close={closePanel}>
                                                <ChapterContextPanel campaign={campaign} update={update} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Adventure" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Edit Adventures"}, type: "adventures"}} panelHeight={panelHeight} close={closePanel}>
                                                <AdventureContextPanel campaign={campaign} update={update} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "Encounters" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "View Encounters"}, type: "encouters"}} panelHeight={panelHeight} close={closePanel}>
                                                <EncountersContextPanel campaign={campaign} update={update} authUser={authUser} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "DicePool" ?
                                        <>
                                            <DetailsPanel panelItem={{item: {name: "Dice Pool"}, type: "dicepool"}} panelHeight={panelHeight} close={closePanel}>
                                                <DicePoolContextPanel displayResult={displayResult} diceRoller={props.diceRoller} firebase={props.firebase} authData={authUser} campaignId={uid} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "CustomCult" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem}  panelHeight={panelHeight} close={closePanel}>
                                                <CustomCultContextPanel displayResult={displayResult} firebase={props.firebase} authData={authUser} update={setCampaign} campaign={campaign} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "CustomRuneSpell" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem}  panelHeight={panelHeight} close={closePanel}>
                                                <CustomRuneSpellContextPanel displayResult={displayResult} firebase={props.firebase} authData={authUser} update={setCampaign} campaign={campaign} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "CustomSpiritSpell" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem}  panelHeight={panelHeight} close={closePanel}>
                                                <CustomSpiritSpellContextPanel displayResult={displayResult} firebase={props.firebase} authData={authUser} update={setCampaign} campaign={campaign} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    panelItem && panelItem.type === "CustomSkill" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem}  panelHeight={panelHeight} close={closePanel}>
                                                <CustomSkillContextPanel displayResult={displayResult} firebase={props.firebase} authData={authUser} update={setCampaign} campaign={campaign} />
                                            </DetailsPanel>
                                        </>
                                    : 
                                    null
                                    }
                                </div>
                            </div>
						</CampaignContext.Provider>
                    }
                    </AuthUserContext.Consumer>
			</div>
		)
	}
	else return null;
}

export default withFirebase(withPageState(Campaign));
