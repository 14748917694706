import React, { useState, useEffect } from 'react';
import globalStyles from '../../Styles/styles.module.css';
import compareValues from '../../../utils/compareValues';
import DialogBox from '../../Dialogs/dialogBox';
import {
    DndContext,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
  } from "@dnd-kit/core";
import {Draggable} from '../../DragAndDrop/draggable';
import {Droppable} from '../../DragAndDrop/droppable';
import { FormControlLabel, Checkbox, Switch, Typography } from '@mui/material';
import SelectVariantDialog from '../../Dialogs/selectVariantDialog';
import * as WeaponsData from '../../../data/weapons.json';
import PropTypes from 'prop-types';
import Accordion from '../../Controls/accordion';
import AccordionDetails from '../../Controls/accordionDetails';
import AccordionSummary from '../../Controls/accordionSummary';
import { getAllSkills } from '../../../services/dataService';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Typography>{children}</Typography>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

const PersonalBonuses = (props) => {

    const [skills, setSkills] = useState([]);
    const [weapons, setWeapons] = useState([]);
    const [knownSkills, setKnownSkills] = useState(true);
    const [knownWeapons, setKnownWeapons] = useState(true);

    const sensors = useSensors(
        useSensor(MouseSensor), 
        useSensor(TouchSensor),
      );
    const [value, setValue] = React.useState(-1);
    const [selectedSkills, setSelectedSkills] = useState({
        bonus25: [],
        bonus10: [],
    });
    const [bonus, setBonus] = useState(0);
    const [open, setOpen] = useState(false);
    const [variantSkill, setVariantSkill] = useState();
    const [skillFilters, setSkillFilters] = useState({
        Agility: false, Communication: false, Knowledge: false, Magic: false, Manipulation: false, Perception: false, Stealth: false
    });
    const [weaponFilters, setWeaponFilters] = useState({
        Melee: false, Missile: false, Shield: false
    });
    const maxLength = {
        bonus25: 4,
        bonus10: 5
    }
    const [expanded, setExpanded] = React.useState('KnownSkills');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
      
	useEffect(() => {
		var skills = [];
        props.displayCharacter.builder.skills.forEach(skill => {
            var updatedSkill = {...skill};
            if (updatedSkill.restricted && updatedSkill.value <= updatedSkill.baseValue){}
            else {
                updatedSkill.value += props.displayCharacter.builder.categoryModifiers[updatedSkill.skillCategory];
                skills.push(updatedSkill)
            }
        })
        var allSkills = getAllSkills(props.builder.campaign)
        allSkills.forEach(skill => {
            if (skills.findIndex(currentSkill => (currentSkill.name === skill.name) && !currentSkill.variant) > -1) {}
            else {
                if (!skill.restricted) {
                    var updatedSkill = {...skill}
                    updatedSkill.value += props.displayCharacter.builder.categoryModifiers[updatedSkill.skillCategory];
                    updatedSkill.parentId = updatedSkill.id;
                    delete updatedSkill.id;
                    skills.push(updatedSkill)
                    } 
            }
        })
        skills.forEach(skill => {
            skill.hide = false;
        });
        if (props.builder.personalBonuses) {
            var updatedSelected = {...selectedSkills};
            props.builder.personalBonuses.selected.forEach(existingItem => {
                var index = -1;
                if (existingItem.options[0].variable) {
                    index = skills.findIndex(skill => skill.name === existingItem.options[0].name && skill.variant === existingItem.options[0].variant);
                    if (index < 0) {
                        index = skills.findIndex(skill => skill.name === existingItem.options[0].name);
                        if (index > -1) {
                            var newSkill = {...skills[index]};
                            newSkill.variant = existingItem.options[0].variant;
                            skills.push(newSkill);
                        }
                    }
                }
                if (existingItem.options[0].variable) {
                    index = skills.findIndex(skill => skill.name === existingItem.options[0].name && skill.variant === existingItem.options[0].variant);
                }
                else {
                    index = skills.findIndex(skill => skill.name === existingItem.options[0].name);
                }
                if (index > -1) {
                    skills[index].hide = true;
                }
                if (existingItem.bonus === 25) {
                    updatedSelected.bonus25.push({...existingItem.options[0], value: existingItem.currentValue, type: existingItem.type});
                }
                else {
                    updatedSelected.bonus10.push({...existingItem.options[0], value: existingItem.currentValue, type: existingItem.type});
                }
            });
        }
        skills.sort(compareValues('name'));
        setSkills(skills);
        if (updatedSelected) {
            setSelectedSkills(updatedSelected);
        }
    },[]);

    useEffect(() => {
		var weapons = [];
        props.displayCharacter.builder.weapons.forEach(weapon => {
            var updatedWeapon = {...weapon};
            updatedWeapon.value += props.displayCharacter.builder.categoryModifiers.Manipulation;
            weapons.push(updatedWeapon)
        })
        var allWeapons = {...WeaponsData};
        var data = allWeapons.data;
        data.forEach(weapon => {
            weapon.hide = false;
            var index = weapons.findIndex(currentWeapon => currentWeapon.parentId === weapon.parentId);
            if (index  > -1) {
                weapons[index].class = weapon.class;
            }
            else {
                weapons.push({...weapon, value: (weapon.baseValue + props.displayCharacter.builder.categoryModifiers.Manipulation)});
            }
        });
        if (props.builder.personalBonuses) {
            props.builder.personalBonuses.selected.forEach(existingItem => {
                var index = weapons.findIndex(weapon => weapon.parentId === existingItem.options[0].parentId);
                if (index > -1) {weapons[index].hide = true;}
            });
        }
        weapons.sort(compareValues('name'));
        setWeapons(weapons);
    },[]);

    useEffect(() => {
        if (props.builder.personalBonuses) {
            props.builder.personalBonuses.selected.forEach(item => {
                if (item.type === "weapon") {
                    var updatedWeapons = [...weapons];
                    var index = updatedWeapons.findIndex(weapon => weapon.parentId === item.options[0].parentId);
                    if (index > -1) {
                        updatedWeapons[index].show = false;
                        setWeapons(updatedWeapons);
                    }
                }
                else {
                    var updatedItems = [...skills];
                    var index = updatedItems.findIndex(skill => skill.parentId === item.options[0].parentId);
                    if (index > -1) {
                        updatedItems[index].show = false;
                        setSkills(updatedItems);
                    }
                }
            });
        }
    },[props.builder]);

    useEffect(() => {
        if (selectedSkills && selectedSkills.bonus25.length == 4 && selectedSkills.bonus10.length == 5) {
            props.disableNext(false);
            var updatedBuilder = {...props.builder};
            var personalBonuses = [];
            selectedSkills.bonus25.forEach(skill => {
                personalBonuses.push(createBonusSkill(skill, 25));
            })
            selectedSkills.bonus10.forEach(skill => {
                personalBonuses.push(createBonusSkill(skill, 10));
            })
            updatedBuilder.personalBonuses = {selected: personalBonuses};
            props.update(updatedBuilder, false);
        }
        else {
            props.disableNext(true);
        }
    },[selectedSkills])

    function createBonusSkill(skill, bonus) {
        var newSkill = {
            bonus: bonus,
            type: skill.type? skill.type : skill.class ? "weapon" : "skill",
            currentValue: skill.value ,
            options: [
                {
                    skillCategory: skill.skillCategory || skill.category || "Manipulation",
                    name: skill.name,
                    parentId: skill.parentId,
                }
            ]
        };
        if (skill.variant) {
            newSkill.options[0].variable = true;
            newSkill.options[0].variant = skill.variant;
        }
        return newSkill;
    }

	function updateSkillFilters(event, category) {
		var updatedFilters = {...skillFilters};
		updatedFilters[category] = event.target.checked;
		setSkillFilters(updatedFilters);
	}

	function updateWeaponFilters(event, category) {
		var updatedFilters = {...weaponFilters};
		updatedFilters[category] = event.target.checked;
		setWeaponFilters(updatedFilters);
	}

    function handleDragEnd(data) {
        if (data.over) {
            var draggedSkill = data.active.id;
            var bonus = data.over.id;
            if (selectedSkills[bonus].length >= maxLength[bonus]) return;
            if (draggedSkill.variable && ! draggedSkill.variant) {
                setOpen(true);
                setBonus(data.over.id === "bonus25" ? 25 : 10);
                setVariantSkill({...draggedSkill})
            }
            else {
                var updatedSelected = {...selectedSkills}
                updatedSelected[bonus].push(draggedSkill);
                setSelectedSkills(updatedSelected);
                hideSkill(draggedSkill, true);
            }
        }
    }

    function hideSkill(updatedSkill, hide) {
        var updatedSkills = [...skills];
        var index = updatedSkills.findIndex(skill => skill.name === updatedSkill.name && (!skill.variant || skill.variant === updatedSkill.variant));
        if (index > -1) {
            updatedSkills[index].hide = hide;
            setSkills(updatedSkills);
        }
        else {
            var updatedWeapons = [...weapons];
            var index = updatedWeapons.findIndex(weapon => weapon.name === updatedSkill.name);
            if (index > -1) {
                updatedWeapons[index].hide = hide;
                setWeapons(updatedWeapons);
            }
        }
    }

    function addVariableSkill(variant) {
        var newSkill = {...variantSkill};
        newSkill.variant = variant;
        newSkill.bonus = bonus;
        var bonusValue = "bonus" + bonus;
        var updatedSelected = {...selectedSkills};
        updatedSelected[bonusValue].push(newSkill);
        setSelectedSkills(updatedSelected);
    }

    function handleVariant(value) {
        var index = skills.findIndex(skill => skill.name == variantSkill.name && skill.variant.toLowerCase() === value.toLowerCase());
        if (index < 0) {
            index = selectedSkills.bonus25.findIndex(skill => skill.name == variantSkill.name && skill.variant.toLowerCase() === value.toLowerCase());
        }
        if (index < 0) {
            index = selectedSkills.bonus10.findIndex(skill => skill.name == variantSkill.name && skill.variant.toLowerCase() === value.toLowerCase());
        }
        if (index < 0) {
            addVariableSkill(value);
            setOpen(false);
        }
    }

    function removeBonus(index, bonus) {
        var updatedSelected = {...selectedSkills};
        var removedSkill = updatedSelected[bonus][index];
        updatedSelected[bonus].splice(index, 1);
        setSelectedSkills(updatedSelected);
        hideSkill(removedSkill, false);
    }

    function DraggableItem(props) {
        var name = props.item.name + (props.item.variant ? " (" + props.item.variant + ")" : "");
        return (
            <Draggable id={props.item}>
                <button style={{height: "60px", margin: "3px"}} disabled={props.disabled} className={props.disabled ? globalStyles.disabledButton : globalStyles.tile} >
                    <div style={{height: "48px", color: "#fff", borderRadius: "5px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "80px", padding: "4px 0px", fontSize: name.length >= 25 ? "11px" : "13px"}}>
                        <div style={{width: "100%"}}>
                            <div style={{}}>{name}</div>
                        </div>

                        <div style={{color: "#fff", width: "40px", display:"flex", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>{props.item.value}%</div>
                    </div>
                </button>
            </Draggable>
        )
    }

    function displaySkills(category) {
            return (
                <div>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {skills.map((skill, index) => {
                            if (category) {
                                if (skill.skillCategory === category && !skill.hide) {
                                    return (
                                        <div style={{display:"flex", flexWrap: "wrap"}} key={index}>
                                            <DraggableItem item={skill} />
                                        </div>
                                    )
                                }
                            }
                            else {
                                if (! skill.hide && skill.value > (skill.baseValue + props.displayCharacter.builder.categoryModifiers[skill.skillCategory])) {
                                    return (
                                        <div style={{display:"flex", flexWrap: "wrap"}} key={index}>
                                            <DraggableItem item={skill} />
                                        </div>
                                    )
                                }
                            }
                                // if (!skill.hide && (!knownSkills || (skill.value > (skill.baseValue + props.displayCharacter.builder.categoryModifiers[skill.skillCategory])))) {
                                // }
                        })}

                    </div>
                </div>
            )
    }

      function displayTab(name, imageName, newValue) {
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", color: "#fff", backgroundColor: "#2f588a", borderRadius: "10px", margin: "10px", padding: "10px", width: "300px", cursor: "pointer"}} onClick={() => changeTab(newValue)}>
                <div style={{borderBottom: "2px solid transparent", display: "flex"}}>
                    <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-white-40x40.png"} style={{width:"30px"}} /></div>
                    <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                </div>
            </div>
        )
    }

    function displaySelectedTab(name, imageName) {
        return (
            <div style={{backgroundColor: "transparent", color: "#121312", display:"flex", justifyContent:"center", alignItems:"center", width: "300px", margin: "10px", padding: "10px"}}>
                    <div style={{borderBottom: "2px solid #2F588A", display: "flex"}}>
                        <div style={{marginRight: "10px"}}><img src={"https://storage.googleapis.com/runequest/runes/" + imageName + "-rune-40x40.png"} style={{width:"30px"}} /></div>
                        <div style={{display:"flex", alignItems: "center"}}>{name}</div>
                    </div>
            </div>
        )
    }

    function changeTab(newValue) {
        setValue(newValue);
        if (newValue === 0) {
            setExpanded("KnownSkills")
        }
        else {
            setExpanded("KnownWeapons")
        }
    }

    function displayWeapons(weaponClass) {
        return (
            <div>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    {weapons.map((weapon, index) => {
                        if (weaponClass) {
                            if (weapon.class === weaponClass && !weapon.hide) {
                                return (
                                    <div style={{display:"flex", flexWrap: "wrap"}} key={index}>
                                        <DraggableItem index={index} item={weapon} />
                                    </div>
                                )
                            }
                        }
                        else {
                            if (!weapon.hide && (weapon.value > (weapon.baseValue + props.displayCharacter.builder.categoryModifiers.Manipulation))) {
                                return (
                                    <div style={{display:"flex", flexWrap: "wrap"}} key={index}>
                                        <DraggableItem index={index} item={weapon} />
                                    </div>
                                )
                            }

                        }
                    })}

                </div>
            </div>
        )
    }

    if (selectedSkills) {
        return (
            <div style={{fontSize: "15px"}}>
                <DndContext onDragEnd={handleDragEnd} autoScroll={false} sensors={sensors}>
                    <div style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                        <div style={{margin: "5px"}}>
                            <div style={{margin: "10px 0px"}}>25% Bonuses ({4 - selectedSkills.bonus25.length} remaining)</div>
                            <Droppable id="bonus25">
                                <div style={{border: "3px solid #2F588A", backgroundColor: "#fff", opacity: selectedSkills.bonus25.length >= 4 ? 0.7 : 1,  borderRadius:"15px", width: "460px", minHeight: "80px", display:"flex", alignItems: "center"}}>
                                    <div style={{display: selectedSkills.bonus25.length == 0 ? "flex": "none", fontSize: "20px", flexWrap: "wrap", color: "#898989", padding: "10px"}}>Drag 4 skills and/or weapons into this box to gain a 25% bonus to those skills and weapons.</div>
                                    <div style={{display:"flex", flexWrap: "wrap"}}>
                                    {selectedSkills.bonus25.map((skill, index) => {
                                        var name = skill.name + (skill.variant ? " (" + skill.variant + ")" : "");
                                        return (
                                                <div onClick={() => {removeBonus(index, "bonus25")}} key={index} style={{textAlign: "center", backgroundColor: "#b3722a", margin: "0px 5px", color: "#fff", cursor: "pointer", border: "2px solid #454645", fontSize: "12px", borderRadius: "10px", height: "55px", width: "90px", paddingTop: "5px"}}>
                                                    <div style={{fontSize: name.length >= 25 ? "11px" : "13px", fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>{name}</div>
                                                    <div style={{fontSize: "13px", fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>{skill.value + 25 > 100 ? skill.value > 100 ? skill.value : 100 : skill.value + 25}%</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Droppable>
                        </div>
                        <div style={{margin: "5px"}}>
                        <div style={{margin: "10px 0px"}}>10% Bonuses ({5 - selectedSkills.bonus10.length} remaining)</div>
                            <Droppable id="bonus10">
                            <div style={{border: "3px solid #2F588A", backgroundColor: "#fff", opacity: selectedSkills.bonus10.length >= 5 ? 0.7 : 1,  borderRadius:"15px", width: "540px", minHeight: "80px", display:"flex", alignItems: "center"}}>
                                    <div style={{display: selectedSkills.bonus10.length == 0 ? "flex": "none", fontSize: "20px", flexWrap: "wrap", color: "#898989", padding: "10px"}}>Drag 5 skills and/or weapons into this box to gain a 10% bonus to those skills and weapons.</div>
                                    <div style={{display:"flex", flexWrap: "wrap"}}>
                                    {selectedSkills.bonus10.map((skill, index) => {
                                         var name = skill.name + (skill.variant ? " (" + skill.variant + ")" : "");
                                         return (
                                                <div onClick={() => {removeBonus(index, "bonus10")}} key={index} style={{textAlign: "center", backgroundColor: "#b3722a", margin: "0px 5px", color: "#fff", cursor: "pointer", border: "2px solid #454645", fontSize: "12px", borderRadius: "10px", height: "55px", width: "90px", paddingTop: "5px"}}>
                                                    <div style={{fontSize: name.length >= 25 ? "11px" : "13px", fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>{name}</div>
                                                    <div style={{fontSize: "13px", fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>{skill.value + 10 > 100 ? skill.value > 100 ? skill.value : 100 : skill.value + 10}%</div>
                                                </div>
                                            )
                                        })}
                                        {/* <div style={{display: selectedSkills.bonus10.length == 0 ? "none": "flex", width: ((5-selectedSkills.bonus10.length) * 94)+"px"}} /> */}
                                    </div>
                                </div>
                            </Droppable>
                        </div>
                    </div>
                        <div style={{marginLeft: "10px"}}>
                        {
                                    value === 0 ? 
                                        <div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px", borderRadius: "5px"}}>
                                            {displaySelectedTab("Selecting from skills", "air")}
                                            {displayTab("Select from weapons", "death", 1)}
                                        </div>
                                    : 
                                    value === 1 ? 
                                        <div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px"}}>
                                            {displayTab("Select from skills", "air", 0)}
                                            {displaySelectedTab("Selecting from weapons", "death")}
                                        </div>
                                    :
                                        <div style={{minHeight: "50px", display:"flex", fontSize: "17px", marginLeft: "5px", marginBottom: "10px"}}>
                                            {displayTab("Select from skills", "air", 0)}
                                            {displayTab("Select from weapons", "death", 1)}
                                        </div>

                                }
                            {value === 0 ? 
                                <div>
                                    <Accordion expanded={expanded === 'KnownSkills'} onChange={handleChange('KnownSkills')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>Known Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {displaySkills()}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Agility'} onChange={handleChange('Agility')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>Agility Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Agility" ? 
                                                displaySkills("Agility")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Communication'} onChange={handleChange('Communication')}>
                                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Typography>Communication Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {expanded === "Communication" ? 
                                                displaySkills("Communication")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Knowledge'} onChange={handleChange('Knowledge')}>
                                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Typography>Knowledge Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Knowledge" ? 
                                                displaySkills("Knowledge")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Magic'} onChange={handleChange('Magic')}>
                                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                        <Typography>Magic Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Magic" ? 
                                                displaySkills("Magic")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Manipulation'} onChange={handleChange('Manipulation')}>
                                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                        <Typography>Manipulation Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Manipulation" ? 
                                                displaySkills("Manipulation")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Perception'} onChange={handleChange('Perception')}>
                                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                        <Typography>Perception Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Perception" ? 
                                                displaySkills("Perception")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Stealth'} onChange={handleChange('Stealth')}>
                                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                        <Typography>Stealth Skills</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Stealth" ? 
                                                displaySkills("Stealth")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            : 
                            value === 1 ?
                                <div>
                                    <Accordion expanded={expanded === 'KnownWeapons'} onChange={handleChange('KnownWeapons')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>Known Weapons</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {displayWeapons()}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Melee'} onChange={handleChange('Melee')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>All Melee Weapons</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Melee" ? 
                                                displayWeapons("Melee")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Missile'} onChange={handleChange('Missile')}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>All Missile Weapons</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Missile" ? 
                                                displayWeapons("Missile")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'Shield'} onChange={handleChange('Shield')}>
                                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Typography>All Shields</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {expanded === "Shield" ? 
                                                displayWeapons("Shield")
                                            : null}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            : 
                            null
                            }
                        </div>
                </DndContext>
                <DialogBox isOpen={open} width="400px" title={variantSkill && "Select " + variantSkill.name + " Specialization"}>
                    <SelectVariantDialog setVariant={(value) => handleVariant(value)} close={() => setOpen(false)}  />
                </DialogBox>
            </div>
        );
    }
    else {
        return null;
    }
}

export default PersonalBonuses;