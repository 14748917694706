import React from 'react';

const CultDetails = (props) => {

    if (props.item ) {
        return (
            <div style={{padding: "20px", backgroundColor: "#cdcdcd", border:"5px solid #2F588A", borderRadius: "15px", zIndex: "99!important"}}>
                <div id="props.item-rune" style={{fontSize:"24px"}}>
                    {props.item.name}
                </div>
                <div style={{margin: "5px 0px", marginBottom: "20px"}} dangerouslySetInnerHTML={{__html: props.item.description}}></div>
                <div style={{margin: "10px 0px",}}>
                    <div style={{fontWeight: "bold"}}>Cult Starting Skills:&nbsp;</div>
                        <div>{props.item.startingSkills}</div>
                    </div>
                    <div style={{margin: "10px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Special Rune Magic:&nbsp;</div>
                        <div>{props.item.startingRuneSpells}</div>
                    </div>
                    <div style={{margin: "10px 0px"}}>
                    <div style={{fontWeight: "bold"}}>Cult Spirit Magic:&nbsp;</div>
                        <div>{props.item.startingSpiritSpells}</div>
                    </div>
                    <div style={{margin: "10px 0px"}}>
                        <div><b>Favored Passions:&nbsp;</b>{props.item.startingPassions}</div>
                    </div>
                    <div style={{margin: "10px 0px"}}>
                        <div><b>Notes:&nbsp;</b>{props.item.startingNotes}</div>
                    </div>
                    <div style={{margin: "10px 0px"}}>
                        <div><b>Associated Cults:&nbsp;</b>{props.item.startingAssociated}</div>
                    </div>
                </div>
        );
    }
    else {
        return null;
    }
}

export default CultDetails;