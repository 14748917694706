import React, {useState, useEffect, useRef} from 'react';
import { withFirebase } from '../Firebase';
import { Button, Switch, FormGroup, FormControlLabel } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { withPageState } from '../Context/PageState';
import { getSkill, getWeapon } from '../../services/dataService';
import DescriptionDialog from './descriptionDialog';
import AddSpellEffectsDialog from './addSpellEffectsDialog';
import AddWeaponFilterDialog from './addWeaponFilterDialog';

const targets = [{name: "Character"}, {name: "Weapon"}];
const spellTypes = [{name: "Active"}, {name: "Passive"}, {name: "Ritual"}];
const durations = [{name: "Instant"}, {name: "Temporal"}, {name: "Other"}];
const ranges = [{name: "Touch"}, {name: "Ranged"}, {name: "Other"}];

const CustomSpiritSpellContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [openEffects, setOpenEffects] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);
	const [spell, setSpell] = useState({name: "", points: 0, range: "", duration: "", variable: false, unplayable: true});
	const [selectors, setSelectors] = useState({type: "", range: "", duration: ""});
    const [target, setTarget] = useState({name: "Select"});
	const [info, setInfo] = useState("");
	const [campaign, setCampaign] = useState(props.campaign);

	const infoBox = useRef(null)

	useEffect(() => {
		if (props.panelItem) {
			setSpell(props.panelItem.item);
		}
		if (props.panelItem.item.target) {
			const index = targets.findIndex(obj => obj.name === props.panelItem.item.target.type)
			if (index > -1) {
				setTarget(targets[index]);
			}
		}
		var selector = {type: "", range: "", duration: ""}
		if (props.panelItem.item.type) {
			selector.type = props.panelItem.item.type
		}
		if (["Instant", "Temporal"].indexOf(props.panelItem.item.duration) > -1) {
			selector.duration = props.panelItem.item.duration
		}
		else {
			selector.duration = "Other";
		}
		if (["Touch", "Ranged"].indexOf(props.panelItem.item.range) > -1) {
			selector.range = props.panelItem.item.range
		}
		else {
			selector.range = "Other";
		}
		setSelectors(selector)
	},[props.panelItem]);

    useEffect(() => {
        if (props.buttonClick === "ok") {
			props.update(campaign, true);
        }
    },[props.buttonClick])

	function updateTarget(newTarget) {
		setTarget(newTarget);
		var updatedSpell = {...spell}
		updatedSpell.target.type = newTarget.name;
		setSpell(updatedSpell);
		updateCampaign(updatedSpell);
	}

	function setValue(field, value) {
		var updatedSpell = {...spell}
		updatedSpell[field] = value;
		setSpell(updatedSpell);
		updateCampaign(updatedSpell);
	}

	function updateCampaign(updatedSpell) {
		var updatedCampaign = {...campaign}
		let index = updatedCampaign.spiritSpells.findIndex(obj => obj.id === updatedSpell.id)
		if (index > -1) {
			updatedCampaign.spiritSpells[index] = updatedSpell;
		}
		else {
			updatedCampaign.spiritSpells.push(updatedSpell);
		}
		setCampaign(updatedCampaign)
	}

	function getEffectName(effect) {
		let name = effect.name;
		let item = null;
		let title = "";

		switch (name) {
			case "attackModifier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return effect.value < 0 ? (title + " attacks decreased by") : (title + " attacks increased by")
			case "parryModifier": 
				return "Parry Modifier";
			case "attackMultiplier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return title + " attacks multiplied by"
			case "parryMultiplier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return title + " parries multiplied by"
			case "damage": 
				return "Total Damage";
			case "damageModifier": 
				return "Damage modified by " + effect.value.quantity + "D" + effect.value.dieType + (effect.value.modifier < 0 ? effect.value.modifier : "+" + effect.value.modifier);
			case "damageMultiplier": 
				return "Weapon damage multiplied by";
			case "defenseModifier": 
				return "Incoming attacks reduced by";
			case "moveMultiplier": 
				return "Move multiplied by";
			case "moveModifier": 
				return effect.value < 0 ? "Move decreased by" : "Move increased by"
			case "strikeRankModifier": 
				title = effect.item.name + " strike rank"
				return effect.value < 0 ? title + " decreased by" : title + " increased by"
			case "statModifier": 
				return effect.item.name + (effect.value < 0 ? " decreased by" : " increased by")
			case "statMultiplier": 
				return effect.item.name + " multiplied by"
			case "armourModifier": 
				return effect.value < 0 ? "Armor decreased by" : "Armor increased by"
			case "spiritArmourModifier": 
				return effect.value < 0 ? "Spirit Combat armor decreased by" : "Spirit Combat armor increased by"
			case "skillMultiplier": 
				item = getSkill(effect.item.id, props.campaign.skills)
				title = (item && item.name) + (effect.item.variant ? " (" + effect.item.variant + ")" : "") + " skill";
				return title + " multiplied by ";
			case "skillModifier": 
				item = getSkill(effect.item.id, props.campaign.skills)
				title = (item && item.name) + (effect.item.variant ? " (" + effect.item.variant + ")" : "") + " skill";
				return title + (effect.value < 0 ? " decreased by" : " increased by")
			case "addWeapon": 
				item = getWeapon(effect.item.id, props.campaign.weapons)
				return ("Add " + item && item.name || "Weapon");
		}
	}

    function applyDescription(editorContent, title, index) {
        var updatedSpell = {...spell}
        updatedSpell.description = editorContent;
        setSpell(updatedSpell);
		updateCampaign(updatedSpell)
    }

	function applyEffects(spellEffects) {
		let updatedSpell = {...spell};
		updatedSpell.effects = spellEffects.effects;
		setSpell(updatedSpell)
		updateCampaign(updatedSpell)
	}

	function applyFilters(filters) {
		let updatedSpell = {...spell};
		let spellFilter = {};
		if (filters.classes.length > 0) {spellFilter.classes = filters.classes;}
		if (filters.damageTypes.length > 0) {spellFilter.damageTypes = filters.damageTypes;}
		if (filters.categories.length > 0) {spellFilter.categories = filters.categories;}
		if (filters.weapons.length > 0) {spellFilter.weapons = filters.weapons;}
		if (!updatedSpell.target) {updatedSpell.target = {};}
		updatedSpell.target.filter = spellFilter;
		setSpell(updatedSpell)
		updateCampaign(updatedSpell)
	}

	function setChoice(name, item) {
		setValue(name, item.name);
		let updatedSelectors = {...selectors};
		updatedSelectors[name] = item.name;
		setSelectors(updatedSelectors)
	}

	if (spell) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: "800px", overflow: "auto", padding: "5px"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Spell name: </div>
									<EditBox size="sm" width="200px" value={spell.name} onChange={(value) => setValue("name", value)} />
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Spell name: </div>
									<div>{spell.name}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "50%"}}>
								<div style={{marginRight: "22px", fontWeight: "bold"}}>Points: </div>
								{props.pageState.editMode ? 
									<EditBox size="sm" number width="50px" value={spell.points} onChange={(value) => setValue("points", value)} />
									: 
									<div>{spell.points}</div>
								}
							</div>
							<div style={{display: "flex", alignItems: "center", width: "145px", marginLeft: "20px"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel control={<Switch color="primary" checked={spell.variable} onChange={(event) => setValue("variable", event.target.checked)}></Switch>} label="Variable Spell"></FormControlLabel>
									</FormGroup>
								:
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Variable: </div>
										<div>{spell.variable ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "166px"}}>
								{props.pageState.editMode ? 
									<Selector labelMargin="33px" label="Type:" style={{height: "28px"}} value={selectors.type} values={spellTypes} onChange={(item) => {setValue("type", item.name)}} />
								: 
									<>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Type: </div>
										<div>{spell.type}</div>
									</>
								}
							</div>
							<div style={{display: "flex", alignItems: "center", width: "145px", marginLeft: "20px"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel control={<Switch color="primary" checked={spell.unplayable} onChange={(event) => setValue("unplayable", event.target.checked)}></Switch>} label="Unavailable to players"></FormControlLabel>
									</FormGroup>
								:
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Not available to players: </div>
										<div>{spell.unplayable ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "space-between"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<Selector labelMargin="25px" label="Range:" style={{height: "28px"}} value={selectors.range} values={ranges} onChange={(item) => {setChoice("range", item)}} />
								: 
									<>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Range: </div>
										<div>{spell.range}</div>
									</>
								}
							</div>
							{props.pageState.editMode && selectors.range === "Other" ? 
								<div style={{display: "flex", alignItems: "center"}}>
									<EditBox placeholder="Enter range" size="sm" width="120px" value={spell.range} onChange={(value) => setValue("range", value)} />
								</div>
							: null
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "20px", justifyContent: "space-between"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<Selector label="Duration:" style={{height: "28px"}} value={selectors.duration} values={durations} onChange={(item) => {setChoice("duration", item)}} />
								: 
									<>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Duration: </div>
										<div>{spell.duration}</div>
									</>
								}
							</div>
							{props.pageState.editMode && selectors.duration === "Other" ? 
								<div style={{display: "flex", alignItems: "center"}}>
									<EditBox placeholder="Enter duration" size="sm" width="120px" value={spell.duration} onChange={(value) => setValue("duration", value)} />
								</div>
							: null
							}
						</div>
						<div style={{marginTop: "20px"}}>
							{props.pageState.editMode ? 
							<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
								<div style={{fontWeight: "bold"}}>Description</div>
								<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
							</div>
						: 
							<>
								<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
								<div dangerouslySetInnerHTML={{__html: spell.description}}></div>
							</>
						}
						</div>
						<hr />
						{props.pageState.editMode ? 
							<>
								<div style={{display: "flex", alignItems: "center", marginTop: "10px", position: "relative"}}>
									<div style={{display:"flex", alignItems: "center"}} title="Help">
										<div>Spell Target: </div>
										<div onClick={() => setInfo("target")} style={{backgroundColor: "#fff", borderRadius: "30px", marginRight: "10px", border: "2px solid #2f588a", marginLeft: "10px", fontSize: "14px", color: "#2f588a", minWidth: "16px", height: "16px", marginTop: "-5px", display:"flex", cursor: "pointer", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>?</div>
										<form style={{position: "absolute", top: 0, zIndex: 399, width: "300px"}} ref={infoBox} onMouseOver={() => infoBox.current.focus()} onBlur={() => {setInfo("")}} tabIndex={0}>
										{info === "target" ? 
										<div onClick={() => setInfo("")} style={{padding: "5px", fontSize: "14px", border: "2px solid #2f588a", borderRadius: "5px", backgroundColor: "#757675", color: "#fff"}}><p><b>Spell Target</b></p><p style={{marginTop: "10px"}}>Select Weapon if the spellcaster must target a specific weapon when casting the spell e.g. Bladesharp, Truesword. Select Character for all other spells.</p></div>
										: null}
										</form>
										<Selector style={{width: "120px", height: "32px", marginLeft: "90px"}} value={target ? target.name : "Select"} values={targets} onChange={updateTarget} />
									</div>
								</div>
							</>
						:
							<div style={{display:"flex", alignItems: "center"}}>
								<div style={{fontWeight: "bold"}}>Spell Target:&nbsp; </div>
								<div>{target.name}</div>
							</div>
						}
						<hr style={{margin: "10px 0px"}} />
						<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
							<div style={{display: "flex", alignItems: "center", position: "relative"}}>
								<div style={{fontWeight: "bold", fontSize: "15px"}}>Weapon Filters: </div>
								{props.pageState.editMode ? 
								<>
									<div onClick={() => setInfo("filters")} style={{backgroundColor: "#fff", borderRadius: "30px", marginRight: "10px", border: "2px solid #2f588a", marginLeft: "10px", fontSize: "14px", color: "#2f588a", minWidth: "16px", height: "16px", marginTop: "-5px", display:"flex", cursor: "pointer", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>?</div>
									<form style={{position: "absolute", top: 0, zIndex: 399, width: "300px"}} ref={infoBox} onMouseOver={() => infoBox.current.focus()} onBlur={() => {setInfo("")}} tabIndex={0}>
									{info === "filters" ? 
									<div onClick={() => setInfo("")} style={{padding: "5px", fontSize: "14px", border: "2px solid #2f588a", borderRadius: "5px", backgroundColor: "#757675", color: "#fff"}}><p><b>Spell Filters</b></p><p style={{marginTop: "10px"}}>Filters are used by weapon-based effects to determine which weapons are valid targets for the spell.</p></div>
									: null}
									</form>
								</>
								:null }
							</div>
							{props.pageState.editMode ? 
								<Button variant='contained' color='primary' onClick={() => setOpenFilters(true)}>Edit Filters</Button>
							: null }

						</div>

						{spell.target && spell.target.filter && spell.target.filter.classes ? 
							<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
								<div style={{fontWeight: "bold", width: "100px"}}>Classes: </div>
									{spell.target.filter.classes.map((weaponClass, index) => (weaponClass + (index < spell.target.filter.classes.length -1 ? ", " : "")))}
							</div>
						: null}
						{spell.target && spell.target.filter && spell.target.filter.damageTypes ? 
							<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
								<div style={{fontWeight: "bold", width: "100px"}}>Damage Types: </div>
								{spell.target.filter.damageTypes.map((damageType, dtIndex) => (damageType + (dtIndex < spell.target.filter.damageTypes.length -1 ? ", " : "")))}
								</div>
						: null}
						{spell.target && spell.target.filter && spell.target.filter.categories ? 
							<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
								<div style={{fontWeight: "bold", width: "100px"}}>Categories: </div>
								{spell.target.filter.categories.map((category, catIndex) => (category + (catIndex < spell.target.filter.categories.length -1 ? ", " : "")))}
							</div>
						: null}
						{spell.target && spell.target.filter && spell.target.filter.weapons ? 
							<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
								<div style={{fontWeight: "bold", width: "100px"}}>Specific Weapons: </div>
								{spell.target.filter.weapons.map((weapon, wIndex) => (weapon + (wIndex < spell.target.filter.weapons.length -1 ? ", " : "")))}
							</div>
						: null}
						<hr style={{margin: "10px 0px"}} />
						<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
							<div style={{fontWeight: "bold", fontSize: "15px"}}>Effects: </div>
							{props.pageState.editMode ? 
								<Button variant='contained' color='primary' onClick={() => setOpenEffects(true)}>Edit Effects</Button>
							: null }
						</div>
						{spell.effects && spell.effects.map((effect, index) => {
							return (
								<div key={index} style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
									<div>{getEffectName(effect)}&nbsp;</div>
									{effect.name !== "damageModifier" ? 
									<>
										<div>{effect.value}</div>
										<div>{effect.suffix === "pts" ? effect.value === 1 ? "pt" : "pts" : effect.suffix}</div>
									</>
									: null}
									<div style={{marginLeft: "5px"}}>{effect.ordinality.id === "total" ? "" : effect.ordinality.name}</div>
								</div>
							)
						})}
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={spell.description} />
				</DialogBox>
				<DialogBox isOpen={openFilters} width="800px" title="Edit Weapon Filters">
					<AddWeaponFilterDialog campaign={props.campaign} close={() => setOpenFilters(false)} filter={spell.target && spell.target.filter} target={target && target.name} update={applyFilters} />
				</DialogBox>
				<DialogBox isOpen={openEffects} width="900px" title="Edit Spell Effects">
					<AddSpellEffectsDialog campaign={props.campaign} close={() => setOpenEffects(false)} effects={spell.effects} target={target && target.name} update={applyEffects} />
				</DialogBox>
			</>
		)
	}
	else {
		return null;
	}
}

export default withFirebase(withPageState(CustomSpiritSpellContextPanel));