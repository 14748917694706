import React, { useState, useEffect } from 'react'
import CustomCults from './customCults';
import CustomSpiritSpells from './customSpiritSpells';
import CustomRuneSpells from './customRuneSpells';
import CustomSkills from './customSkills';

const CustomContent = (props) => {

    const [campaign, setCampaign] = useState(props.campaign)

    useEffect(() => {
        setCampaign({...props.campaign})
    },[props.campaign])

    const deleteItem = (item, type) => {
        let updatedCampaign = {...campaign};
        let index = updatedCampaign[type].findIndex(obj => obj.id === item.id);
        if (index > -1) {
            updatedCampaign[type].splice(index, 1);
        }
        setCampaign(updatedCampaign);
        props.update(updatedCampaign, true);
    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{margin: "10px 0px"}}>
                <CustomCults campaign={campaign} displayItem={props.displayItem} delete={(item) => deleteItem(item, "cults")} />
            </div>
            <div style={{margin: "10px 0px"}}>
                <CustomRuneSpells campaign={campaign} displayItem={props.displayItem} delete={(item) => deleteItem(item, "runeSpells")} />
            </div>
            <div style={{margin: "10px 0px"}}>
                <CustomSpiritSpells campaign={campaign} displayItem={props.displayItem} delete={(item) => deleteItem(item, "spiritSpells")} />
            </div>
            <div style={{margin: "10px 0px"}}>
                <CustomSkills campaign={campaign} displayItem={props.displayItem} delete={(item) => deleteItem(item, "skills")} />
            </div>
        </div>
    )
}

export default CustomContent;