import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css'
import SkillListItem from './skillListItem';
import { withPageState } from '../Context/PageState';
import { getAllSkills } from '../../services/dataService';

const SkillList = (props) => {

    // const [selected, setSelected] = useState([]);
    
    const selectItem = (item, checked) => {
        let updatedSelected = [...props.selected];
        if (checked) {
            updatedSelected.push({name: item.name, parentId: item.id});
        }
        else {
            let index = -1;
            if (item.variant) {
                index = updatedSelected.findIndex(skill => skill.parentId === item.id && skill.variant === item.variant )
            }
            else {
                index = updatedSelected.findIndex(skill => skill.parentId === item.id )
            }
            if (index > -1) {
                updatedSelected.splice(index, 1);
            }
        }
        // setSelected(updatedSelected);
        props.update(updatedSelected);
    };

        return (
            <>
                <div>
                    <div style={{display:"flex", fontSize: "15px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                    <div style={{width:"80px", marginLeft:"5px", textAlign:"left"}}></div>
                    <div style={{width:"150px", marginLeft:"5px", textAlign:"left"}}>Skill</div>
                        <div style={{width:"150px", textAlign:"center"}}>Base Value</div>
                        <div style={{width:"100px", textAlign:"center"}}>Skill Category</div>
                    </div>
                    <div className={globalStyles.panel} style={{height: "400px", overflow: "auto"}}>
                        { 
                            getAllSkills(props.campaign).filter(function(obj) {return (!obj.restricted)}).map((item, index) => {
                                return (
                                    <SkillListItem key={index} item={item} select={selectItem} selected={props.selected} />
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
}

export default withPageState(SkillList);   